import React, { FC, useEffect, useContext, useState } from 'react';
import parse from 'html-react-parser';

import { ICardData } from '@throttleup/esi-components';
import CardDataContext from '../context/CardDataContext';
import { getExistingHtml } from '../utils';
import { getUpdateSessionDataInGoLinksOptions } from '../utils/helpers/goLinks';

interface IESIWrapper {
  cardId: number;
  containerId: string;
  className: string;
  esiSrc: string;
  fallbackRenderFunction: (data: ICardData, upDomain: string) => any;
  dummyCardData: ICardData;
  extraParams: any[];
  cardDataFromProps?: ICardData | null; //Used by the preview page to tell the ESI Wrapper to use the fallback render function instead of an ESI Tag
}

const ESIWrapper: FC<IESIWrapper> = ({
  cardId,
  containerId,
  className,
  esiSrc,
  fallbackRenderFunction,
  dummyCardData,
  extraParams,
  cardDataFromProps,
}) => {
  const isESISuppressed = process.env['SUPPRESS_ESI'] === 'true';
  const esiHtml = cardDataFromProps ? null : (
    <div
      id={containerId}
      className={className}
      dangerouslySetInnerHTML={{
        __html: `<esi:include src="${esiSrc}" onerror="continue" />`,
      }}
    ></div>
  );
  const [content, setContent] = useState(esiHtml);
  const { cardDataJson } = useContext(CardDataContext);
  const allCardData = cardDataJson?.data;
  const cardData = cardDataFromProps
    ? cardDataFromProps
    : allCardData && cardId && allCardData.find((c) => c.cardId === cardId);
  const [loading, setLoading] = useState(true);

  //If this is the first time trying to mount the component client-side, check if we need to prevent flickering due to ESI
  if (typeof window !== 'undefined' && !isESISuppressed && loading && !cardDataFromProps) {
    const html = getExistingHtml(`#${containerId}`, 'innerHTML');
    //If the HTML of the ESI tag has been properly changed to the content, set it in the DOM now to prevent flickering
    if (html && !html.replace(/&amp;/g, '&').includes(esiSrc)) {
      setLoading(false);
      setContent(
        <div id={containerId} className={className} dangerouslySetInnerHTML={{ __html: html }} />
      );
    }
  }

  // This function is called client side to set the state to the rendered SSG component given the passed in data
  const setClientSideContent = (data: ICardData) => {
    return setContent(
      <div className={className}>
        {parse(
          fallbackRenderFunction(data, ...extraParams) || '',
          getUpdateSessionDataInGoLinksOptions()
        )}
      </div>
    );
  };

  useEffect(() => {
    if (loading) {
      if (cardData) {
        setLoading(false);
        setClientSideContent(cardData);
      } else {
        setClientSideContent(dummyCardData);
      }
    }
  }, [cardData, loading]);

  return content;
};

export default ESIWrapper;
