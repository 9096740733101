import React from 'react';
import { SVGProps } from 'react';

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24.192} height={17.595} {...props}>
    <path
      data-name="Path 939"
      d="m23.87 8.02-7.7-7.7a1.1 1.1 0 1 0-1.555 1.555l5.821 5.82H1.1a1.1 1.1 0 1 0 0 2.2h19.338l-5.821 5.821a1.1 1.1 0 1 0 1.555 1.555l7.7-7.7a1.1 1.1 0 0 0 0-1.555Z"
      fill={`${props.fill ? props.fill : '#f7941e'}`}
    />
  </svg>
);

export default ArrowRightIcon;
