import { ILink } from '../../models/ILink';

export const getCategoriesBreadcrumbs = (
  categories?: Queries.Maybe<Queries.WpPostToCategoryConnection>
): ILink[] => {
  const forwardSlashOccurrenceRegex = /\//g;
  let breadCrumbs: ILink[] = [
    {
      text: 'Home',
      link: '/',
    },
  ];
  if (categories && categories.nodes) {
    breadCrumbs = breadCrumbs.concat(
      categories.nodes.map((category) => ({
        link: category?.uri || '',
        text: category?.name || '',
      }))
    );
  }
  return breadCrumbs.sort(
    (categoryA, categoryB) =>
      (categoryA?.link?.match(forwardSlashOccurrenceRegex) || [])?.length -
      (categoryB?.link?.match(forwardSlashOccurrenceRegex) || [])?.length
  );
};
