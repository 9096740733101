import { graphql, useStaticQuery } from 'gatsby';

const getAllSidebarAds = () => {
  const { wpUpOption } = useStaticQuery(graphql`
    query {
      wpUpOption(slug: { eq: "sidebar-ads" }) {
        sidebarAds {
          upgpAllSidebarAds {
            adLink
            imageAd {
              altText
              sourceUrl
            }
            displayOn {
              displayCategory
              displayPagesUrl
              displayPosts {
                ... on WpPost {
                  databaseId
                }
              }
              displayCategories {
                databaseId
                slug
              }
              displayTags {
                databaseId
                slug
              }
            }
          }
        }
      }
    }
  `);

  return wpUpOption as Queries.WpUpOption;
};

export default getAllSidebarAds;
