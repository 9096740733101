import React, { FC } from 'react';
import { IImageOverlay } from './FullFeatured.def';
import { createSourceElements, createSrc, createSrcSet } from '../../utils';
import { Link } from 'gatsby';

import {
  LARGE_TAB_MIN_WIDTH_MQ,
  MEDIUM_TAB_MIN_WIDTH_MQ,
  MED_DESKTOP_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_DESKTOP_MIN_WIDTH_MQ,
} from '../../constants';

const ImageOverlay: FC<IImageOverlay> = ({ thumbnail, children, link = '' }) => {
  const sourceElements = createSourceElements([
    {
      media: MED_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 1248,
            height: 634,
          },
        },
      ]),
    },
    {
      media: SMALL_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 936,
            height: 634,
          },
        },
      ]),
    },
    {
      media: LARGE_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 666,
            height: 544,
          },
        },
      ]),
    },
    {
      media: MEDIUM_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 740,
            height: 296,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);

  thumbnail = {
    ...thumbnail,
    src: createSrc(thumbnail.src || ''),
  };
  const overlay = <div className="image-overlay" />;

  return (
    <div className="bg-cover main">
      {link ? <Link to={link}>{overlay}</Link> : overlay}
      <figure>
        <picture>
          {sourceElements}
          <img {...thumbnail} loading="lazy" />
        </picture>
      </figure>
      {children}
    </div>
  );
};

export default ImageOverlay;
