import { graphql, useStaticQuery } from 'gatsby';

const getFlexiblePointsForCalculator = () => {
  const { wpUpOption } = useStaticQuery(graphql`
    query {
      wpUpOption(slug: { eq: "flexible-points-calculator" }) {
        tptFlexiblePointsCalculator {
          tptFlexiblePoints {
            tptFlexiblePointsName
            tptFlexiblePointsPartners {
              tptFlexiblePointsPartnersBonusText
              tptFlexiblePointsPartnersExtraContent
              tptFlexiblePointsPartnersPartnerName
              tptFlexiblePointsPartnersTransferRatio
              tptFlexiblePointsPartnersTransferRatioBase
              tptFlexiblePointsPartnersTransferTime
            }
          }
        }
      }
    }
  `);
  return wpUpOption as Queries.WpUpOption;
};

export default getFlexiblePointsForCalculator;
