import { graphql, useStaticQuery } from 'gatsby';
import { getPlaceholderWpGatsbyImg } from '../';
import { IWpPost } from '../../models/IWpPost';

const getLatestNewsArticles = () => {
  const articleData = useStaticQuery(graphql`
    {
      allWpPost(
        limit: 7
        filter: { categories: { nodes: { elemMatch: { name: { eq: "News" } } } } }
        sort: { date: DESC }
      ) {
        nodes {
          databaseId
          uri
          dateGmt
          excerpt
          title
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
          author {
            node {
              uri
              name
            }
          }
          postEditorSettings {
            secondaryPostAuthor {
              name
              uri
            }
          }
          featuredImageOptions {
            imageHorizontalPosition
            imageVerticalPosition
          }
        }
      }
    }
  `);
  const articleNodes = articleData?.allWpPost?.nodes;
  const articles =
    articleNodes &&
    articleNodes.length &&
    articleNodes.map((node: IWpPost) => {
      
      const {
        featuredImage: {
          node: featuredImageNode = getPlaceholderWpGatsbyImg(),
        } = {},
        featuredImageOptions: {
          imageHorizontalPosition: horizontalPosition,
          imageVerticalPosition: verticalPosition,
        } = {},
      } = node;
      
      const { sourceUrl: src, altText: alt } = featuredImageNode;
      const thumbnail = {
        src,
        alt,
        ...(horizontalPosition && verticalPosition && { style: { objectPosition: `${horizontalPosition} ${verticalPosition}` } }),
      };

      return {
        databaseId: node.databaseId,
        date: node.dateGmt,
        excerpt: node.excerpt,
        link: node.uri,
        thumbnail: thumbnail,
        title: node.title,
        author: {
          link: node.author?.node?.uri || '#',
          text: node.author?.node?.name || '',
        },
        secondaryAuthor: {
          link: node.postEditorSettings?.secondaryPostAuthor?.uri || '#',
          text: node.postEditorSettings?.secondaryPostAuthor?.name || '',
        },
      };
    });

  return articles || [];
};

export default getLatestNewsArticles;
