import React, { FC } from 'react';
import { IContactCard } from './ContactCard.def';
import { getMenuItems } from '../../../utils/static-queries';
import SocialIcons from '../../SocialIcons';


const ContactCard: FC<IContactCard> = ({
  title,
  thumbnail,
  children,
  alt,
  variant,
  showSocialIcons,
}) => {
  const { socialLinks } = getMenuItems();

  const socials =
    socialLinks?.map((socialLink) => ({
      link: socialLink?.link || '',
      name: socialLink?.text || '',
    })) || [];

  return (
    <div className={`contactBlock ${variant ? 'bg-lighterBlue' : ''}`}>
      {thumbnail && alt && (
        <figure>
          <img src={thumbnail} alt={alt} loading="lazy" />
        </figure>
      )}
      <h3 className={`${showSocialIcons ? 'other-ways' : ''}`}>{title}</h3>
      {children}
      {showSocialIcons && (
        <div className="social-wrapper">
          <SocialIcons socials={socials} color='blue'/>
        </div>
      )}
    </div>
  );
};

export default ContactCard;
