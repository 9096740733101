import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import HomeIntro from '../components/HomeIntro/HomeIntro';
import { IWPBulletPoint } from '../components/HomeIntro/HomeIntro.def';

const HomeIntroContainer = (props: any) => {
  const {
    upgp_inter_homepage_title: title,
    upgp_inter_homepage_bullet_points: bulletPoints = [],
    upgp_inter_homepage_image_grid: imageGrid,
  } = props;

  const perks = bulletPoints?.map(
    ({
      upgp_inter_homepage_bullet_description,
      upgp_inter_homepage_bullet_point,
    }: IWPBulletPoint) => {
      return {
        description: upgp_inter_homepage_bullet_description,
        title: upgp_inter_homepage_bullet_point,
      };
    }
  );

  const featuredImages: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>[] =
    [];
  let wpImage = imageGrid?.upgp_inter_homepage_image_1;
  if (wpImage?.image) {
    featuredImages.push({
      alt: wpImage.alt,
      src: wpImage.image,
    });
  }
  wpImage = imageGrid?.upgp_inter_homepage_image_2;
  if (wpImage?.image) {
    featuredImages.push({
      alt: wpImage.alt,
      src: wpImage.image,
    });
  }
  wpImage = imageGrid?.upgp_inter_homepage_image_3;
  if (wpImage?.image) {
    featuredImages.push({
      alt: wpImage.alt,
      src: wpImage.image,
    });
  }

  return <HomeIntro featuredImages={featuredImages} perks={perks} title={title} />;
};

export default HomeIntroContainer;
