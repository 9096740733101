import React, { SVGProps } from 'react';

const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30.202} height={37.9} {...props}>
    <path
      d="M25.76 13.916h-2.667V7.829a8 8 0 0 0-15.989 0v6.087H4.441A4.446 4.446 0 0 0 0 18.358v15.1A4.446 4.446 0 0 0 4.441 37.9H25.76a4.446 4.446 0 0 0 4.44-4.441v-15.1a4.446 4.446 0 0 0-4.44-4.443Zm-15.7-6.087a5.036 5.036 0 0 1 10.067 0v6.087H10.064Zm17.181 25.63a1.482 1.482 0 0 1-1.48 1.48H4.441a1.482 1.482 0 0 1-1.48-1.48v-15.1a1.482 1.482 0 0 1 1.48-1.48H25.76a1.482 1.482 0 0 1 1.48 1.48Z"
      fill={`${props.fill ? props.fill : '#fff'}`}
    />
    <path
      d="M15.101 21.17a2.739 2.739 0 0 0-1.483 5.041v3.25a1.48 1.48 0 0 0 2.961 0v-3.246a2.739 2.739 0 0 0-1.478-5.045Z"
      fill={`${props.fill ? props.fill : '#fff'}`}
    />
  </svg>
);

export default LockIcon;
