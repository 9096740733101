import { IFastlyOptions } from '../../models/IFastlyOptions';

/**
 * Replace the domain in the passed-in URL with the one from the environment. Alternatively,
 * add the domain from the environment to the URL if it does not have one. Also ensure that the
 * protocol is https.
 * @param url - a URL with or without a domain
 * @returns - a full URL with https as the protocol and the domain from the environment.
 */
export const replaceDomainWithSiteDomain = (url: string) => {
  if (!url) return null; //Exit early if url is not defined
  const siteUrl = process.env['GATSBY_SITE_URL'];
  if (!siteUrl) return url; //Exit early if site url is not defined
  const urlParts = url.split('/');
  if (urlParts[0]?.includes('http')) {
    //First, make sure we use https not http
    if (urlParts[0] === 'http:') {
      urlParts[0] = 'https:';
    }
    const siteDomain = siteUrl.split('/')[2];
    if (siteDomain) urlParts[2] = siteDomain;
    return urlParts.join('/');
  }
  //url has no domain, just prepend it
  return siteUrl + url;
};

export const getSiteDomainWithoutProtocol = () =>
  process.env['GATSBY_SITE_URL']?.replace(/^https?:\/\//, '');

/**
 * Prepend the passed-in URL with the protocol and domain from the environment.
 * If the URL already has a domain, replace the domain with the site's domain.
 * @param url - A URL with or without a domain
 * @param protocol - If given, use this protocol instead of https.
 * @returns - A full URL with the protocol and the domain, or null if construction fails.
 */
export const prependUpgradedPointsDomain = (url: string, protocol?: string) => {
  if (!url) return null; // Exit early if URL is not defined

  const siteUrl = process.env['GATSBY_SITE_URL'];
  if (!siteUrl) return null; // Ensure siteUrl is available

  let newUrl = url.includes('://')
    ? replaceDomainWithSiteDomain(url)
    : `${siteUrl}${url.startsWith('/') ? url : `/${url}`}`;

  if (!newUrl) return null; // Ensure replaceDomainWithSiteDomain didn't return null

  return protocol ? newUrl.replace(/^https/, protocol) : newUrl;
};

export const replaceWpDomainWithFastlyDomain = (
  url: string,
  options?: IFastlyOptions,
  isFallbackImage: boolean = false,
) => {
  const wpDomain = process.env['GATSBY_WORDPRESS_URL'];
  const fastlyDomain = process.env['GATSBY_SITE_URL'];
  let newUrl = url;
  if (wpDomain && fastlyDomain && (newUrl.startsWith('/') || newUrl.startsWith(wpDomain))) {
    newUrl = url.replace(wpDomain, fastlyDomain);

    if (newUrl.startsWith('/')) {
      newUrl = fastlyDomain + newUrl;
    }

    // Add Default Query Params
    if (!/\?[a-z0-9]*=[a-z0-9-%]*/.test(newUrl)) {
      newUrl = `${newUrl}?auto=webp&disable=upscale`;

      if (isFallbackImage) {
        newUrl = `${newUrl}&width=1200`;
      }
    }

    if (options) {
      newUrl = `${newUrl}&width=${options?.width}`;
      if (options?.height) {
        newUrl = `${newUrl}&height=${options?.height}`;
      }
      if (options?.fit) {
        newUrl = `${newUrl}&fit=${options.fit}`;
      } else if (options?.width && options?.height) {
        newUrl = `${newUrl}&fit=crop`;
      }
      if (options?.dpr) {
        newUrl = `${newUrl}&dpr=${options.dpr}`;
      }
    }
  }

  if (newUrl && !newUrl.startsWith('http')) {
    newUrl = `https://${newUrl}`;
  }
  return newUrl;
};

export const removeDomain = (url: string) => {
  if (!url) return null; //Exit early if url is not defined
  const urlParts = url.split('/');
  if (urlParts[0]?.includes('http')) {
    return '/' + urlParts.slice(3).join('/');
  }
  //url has no domain, just return it
  return url;
};

/**
 * Function to get the domain only wihtout the www.
 *
 * @param url String URL
 * @returns String Domain without the www.
 */
export const getDomainWithoutWWW = (url: string) => {
  try {
    let hostname = new URL(url).hostname;

    // Remove "www." if present at the beginning
    return hostname.replace(/^www\./, '');
  } catch (e) {
    console.error('Invalid URL: ', e);
    return '';
  }
};
