import React, { FC } from 'react';
import { Link } from 'gatsby';
import { IDisclosureModal } from './DisclosureModal.def';

const DisclosureModal: FC<IDisclosureModal> = () => {
  const handleCloseDisclosure = () => {
    document.querySelector('.disclosure-modal-container')?.classList.remove('open');
  };
  return (
    <div className="disclosure-modal-container" onClick={handleCloseDisclosure}>
      <p className="disclosure-modal-container__title">Advertiser Disclosure</p>
      <p className="disclosure-modal-container__text">
        Many of the credit card offers that appear on this site are from credit card companies from
        which we receive financial compensation. This compensation may impact how and where products
        appear on this site (including, for example, the order in which they appear). However, the
        credit card information that we publish has been written and evaluated by experts who know
        these products inside out. We only recommend products we either use ourselves or endorse.
        This site does not include all credit card companies or all available credit card offers
        that are on the market.&nbsp;
        <strong>
          <Link to="/advertising-policy/">See our advertising policy here</Link> where we list
          advertisers that we work with, and how we make money. You can also review our&nbsp;
          <Link to="/credit-card-rating-methodology/">credit card rating methodology</Link>.
        </strong>
      </p>
      <button className="disclosure-modal-container__button upBtn" onClick={handleCloseDisclosure}>
        Close
      </button>
    </div>
  );
};

export default DisclosureModal;
