import React, { FC } from 'react';
import ArticleExcerpt from '../ContentTypes/Article/ArticleExcerpt';
import { INewsFeaturedArticle } from './NewsFeaturedArticle.def';

const NewsFeaturedArticle: FC<INewsFeaturedArticle> = ({ article }) => {
  return (
    <section className="container guideBlocks featuredNewsArticle cols2">
      <div className="newsContainer">
        <ArticleExcerpt {...article} />
      </div>
    </section>
  );
};

export default NewsFeaturedArticle;
