import React, { FC, useEffect, useState, ReactNode } from 'react';
import { Tooltip } from '@throttleup/esi-components';

export interface ITooltipContainer {
  tooltipText: string | ReactNode;
}

const TooltipContainer: FC<ITooltipContainer> = ({ tooltipText }) => {
  const [innerWidth, setInnerWidth] = useState(0);

  useEffect(() => {
    if (innerWidth === 0 && window) {
      setInnerWidth(window.innerWidth);
    }
    window?.addEventListener('resize', () => {
      setInnerWidth(window.innerWidth);
    });
    return () =>
      window?.removeEventListener('resize', () => {
        setInnerWidth(window.innerWidth);
      });
  }, []);

  return <Tooltip text={tooltipText} />
};

export default TooltipContainer;
