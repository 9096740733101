import React, { FC, useEffect, useRef } from 'react';
import { IHeaderWrapper } from './HeaderWrapper.def';
import Header from '../../components/Header';
import { STICKY_NAV_SCROLL_UP_OFFSET } from '../../constants';

const HeaderWrapper: FC<IHeaderWrapper> = ({ isTabletOrMobile, ...rest }) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const headerPlaceholderRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Initialize a variable to track the last known scroll position
    let lastScrollTop = 0;

    // Check if the device is a tablet or mobile
    if (isTabletOrMobile) {
      document.addEventListener('scroll', () => {
        // Get the current vertical scroll position
        const scrollTop = document.documentElement.scrollTop;
        // Locate the search wrapper element inside the header, if it exists
        const searchWrapperElement = headerRef.current?.querySelector('.search-wrapper');

        // If the search wrapper element is not found, allow toggling the sticky navigation bar
        // This check is necessary because when the search is open, scrolling would otherwise cause
        // the entire header to disappear. We prevent the sticky behavior when the search is active.
        if (!searchWrapperElement) {
          // If the user scrolls down or is at the top of the page (scrollY === 0)
          if (scrollTop > lastScrollTop || window.scrollY === 0) {
            // Remove the 'sticky-nav' class from the header to stop it from sticking
            headerRef.current?.classList.remove('sticky-nav');
            // Hide the header placeholder (if it exists) when the nav is not sticky
            if (headerPlaceholderRef.current) headerPlaceholderRef.current.style.display = 'none';
          } else if (lastScrollTop - scrollTop > STICKY_NAV_SCROLL_UP_OFFSET) {
            // If the user scrolls up more than the offset, add the sticky-nav class
            headerRef.current?.classList.add('sticky-nav');
            // Show the header placeholder (if it exists) when the nav is sticky
            if (headerPlaceholderRef.current) headerPlaceholderRef.current.style.display = 'block';
          }
        }
        // Update lastScrollTop with the current scroll position
        // Ensure it's not negative by setting it to 0 if necessary
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      });
    }
  }, [isTabletOrMobile]);
  return (
    <>
      {isTabletOrMobile && (
        <div
          style={{
            height: `${headerRef?.current?.offsetHeight}px`,
            display: 'none',
          }}
          aria-hidden="true"
          ref={headerPlaceholderRef}
        />
      )}
      <Header {...rest} ref={headerRef} />
    </>
  );
};

export default HeaderWrapper;
