import React from 'react';
import { SVGProps } from 'react';

const OrangeBadgeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.6 49.6" {...props}>
    <path
      fill="#fff"
      d="M24.8 0C11.1 0 0 11.1 0 24.8s11.1 24.8 24.8 24.8 24.8-11.1 24.8-24.8S38.5 0 24.8 0Zm13.7 23-7.2 5.2 2.8 8.5c0 .1.1.3.1.4 0 .7-.6 1.3-1.3 1.3-.3 0-.6-.1-.8-.3l-7.3-5.2-7.2 5.3c-.2.2-.5.3-.8.3-.7 0-1.3-.6-1.3-1.3 0-.1 0-.3.1-.4l2.8-8.5-7.2-5.2c-.3-.2-.6-.6-.6-1.1 0-.7.6-1.3 1.3-1.3h8.9l2.8-8.4c.2-.5.7-.9 1.3-.9s1.1.4 1.3.9l2.8 8.4h8.9c.7 0 1.3.6 1.3 1.3-.1.4-.4.8-.7 1Z"
    />
  </svg>
);

export default OrangeBadgeIcon;
