import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SquareBrowser from '../components/SquareBrowser';
import { getPlaceholderWpGatsbyImg } from '../utils';
import { IWpCategory } from '../models/IWpCategory';
import { IWpPost } from '../models/IWpPost';
import { getLatestCreditCardPosts } from '../utils/static-queries';

const SquareBrowserContainer = (props: any) => {
  const {
    up_gb_square_browser_title: title,
    up_gb_square_browser_subtitle: subtitle,
    up_gb_square_browser_more_link: moreLinkData,
    up_gb_square_browser_limit: limit,
    up_gb_square_browser_category: categoryId,
  } = props;
  const moreLink = {
    text: moreLinkData?.up_gb_square_browser_more_text,
    link: moreLinkData?.up_gb_square_browser_more_link,
  };
  let articles = [];

  const allCategoryData = useStaticQuery(graphql`
    query {
      allWpCategory {
        nodes {
          databaseId
          uri
          name
        }
      }
    }
  `);
  const categoryData = allCategoryData.allWpCategory.nodes.find(
    (node: IWpCategory) => node.databaseId === categoryId
  );
  const categoryObj = { text: categoryData.name, link: categoryData.uri };
  switch (categoryData.name) {
    case 'Credit Cards':
      articles = getLatestCreditCardPosts();
      break;
  }
  articles = articles.slice(0, limit).map((wpArticle: IWpPost) => {
    const featuredImageNodeThumb = wpArticle?.featuredImage?.node || getPlaceholderWpGatsbyImg();
    return {
      thumbnail: {
        alt: featuredImageNodeThumb.altText,
        src: featuredImageNodeThumb.sourceUrl,
      },
      imageOverlay: true,
      title: wpArticle.title,
      link: wpArticle.uri,
      category: categoryObj,
    };
  });
  return (
    <SquareBrowser title={title} moreLink={moreLink} articles={articles} subTitle={subtitle} />
  );
};

export default SquareBrowserContainer;
