import React, { FC } from 'react';
import { renderEarningsRateTooltip } from '@throttleup/esi-components';
import HTMLContentESIWrapper from '../../wrappers/HTMLContentESIWrapper';

interface IEarningsRateTooltipESI {
  src: string;
}

const EarningsRateTooltipESI: FC<IEarningsRateTooltipESI> = ({ src }) => {
  const { searchParams } = new URL(`https://example.com${src}`);
  const cardId = parseInt(searchParams.get('card_id') || '0');
  const parentId = parseInt(searchParams.get('parent_id') || '0');
  const containerId = `earnings_rate_tooltip_${cardId}`;
  const extraParams = [parentId];

  return (
    <HTMLContentESIWrapper
      cardId={cardId}
      containerId={containerId}
      src={src}
      fallbackRenderFunction={renderEarningsRateTooltip}
      extraParams={extraParams}
      tagName="span"
    />
  );
};

export default EarningsRateTooltipESI;
