import React, { SVGProps } from 'react';

const IconTrophy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      className="st0"
      d="M15 2V0H5v2H0v4c0 2.8 2.2 5 5 5h.1c.4 2 1.9 3.5 3.9 3.9V17l-5 2v1h12v-1l-5-2v-2.1c2-.4 3.5-1.9 3.9-3.9h.1c2.8 0 5-2.2 5-5V2h-5zM5 9C3.3 9 2 7.7 2 6V4h3v5zm10 0V4h3v2c0 1.7-1.3 3-3 3z"
    />
  </svg>
);

export default IconTrophy;
