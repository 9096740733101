import { Link } from 'gatsby';
import React, { FC } from 'react';
import { renderItems } from '../../utils';
import BlockHeader from '../BlockHeader/BlockHeader';
import Scroller from '../Scroller';
import { IOverviewTestimonials } from './OverviewTestimonials.def';

const OverviewTestimonials: FC<IOverviewTestimonials> = ({ blockHeader, testimonies }) => {
  if (testimonies.length === 0) return null;
  return (
    <>
    <section>
      <div className="container">
        <BlockHeader {...blockHeader} />
      </div>
      <Scroller className="overviewTestimonials">
        {renderItems(testimonies, 'Testimonial')}
      </Scroller>
      {blockHeader.moreLink && (
        <div className="btn-wrapper">
          <Link to={blockHeader.moreLink.link} className="btn-standard btn-standard-mobile">
            {blockHeader.moreLink.text}
          </Link>
        </div>
      )}
    </section>
    </>
  );
};
export default OverviewTestimonials;
