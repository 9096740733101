import React, { SVGProps } from 'react';

const UpgradedPointsFooterLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={114.847} height={66.317} {...props}>
    <g fill="#fff">
      <path d="m55.865 19.693-.06.129h.056Z" />
      <path d="m60.071 0-6.2 13.388-.06.129-5.646 12.172h5.512v16.669c0 7.487-2.221 13.381-7.185 17.6S35.034 66.32 27.055 66.32q-12.093 0-19.59-6.362T0 42.4V0h15.217v42.4c0 4.028 1.043 7.1 3.151 9.158s4.981 3.1 8.719 3.1c3.672 0 6.554-1.016 8.61-3.071s3.071-5.1 3.071-9.184V0Z" />
      <path d="M108.024 5.933Q101.198 0 89.57 0h-29.5l4.549 9.825 1.71 3.692 5.645 12.172h-5.545v39.685h13.353V42.949h9.788c7.76 0 13.9-1.946 18.454-5.9s6.823-9.1 6.823-15.546a19.646 19.646 0 0 0-6.823-15.57ZM98.81 28.608c-1.73 1.81-4.25 2.716-7.54 2.716h-9.785V11.691h9.788q4.981 0 7.567 2.8a10 10 0 0 1 2.58 7.129 9.769 9.769 0 0 1-2.61 6.988Z" />
    </g>
  </svg>
);

export default UpgradedPointsFooterLogo;
