import React from 'react';
import parse from 'html-react-parser';

import { getPlaceholderGatsbyImg, renderItems } from '../utils';
import { IWpTestimony } from '../models/IWpTestimony';

const TestimonialsContainer = (props: any) => {
  const { upgp_gb_testimonials: wpTestimonials } = props;
  const testimonies = wpTestimonials.map((testimony: IWpTestimony) => {
    const {
      upgp_gb_testimonials_name: name,
      upgp_gb_testimonials_rating: rating,
      upgp_gb_testimonials_rich_comment: richComment,
      upgp_gb_testimonials_profile_picture: thumbnailUrl,
    } = testimony;
    const designation = name + ' Level UP Travel member';
    let thumbnail = '';
    if (thumbnailUrl) {
      //Use the 60x60 version of the thumbnail
      const urlSuffixIndex = thumbnailUrl.lastIndexOf('.');
      thumbnail = thumbnailUrl.slice(0, urlSuffixIndex) + thumbnailUrl.slice(urlSuffixIndex);
    } else {
      thumbnail = getPlaceholderGatsbyImg();
    }
    const reviewText = parse(richComment || '');
    return {
      name,
      designation,
      rating,
      reviewText,
      thumbnail,
    };
  });
  return <div className="testimonials">{renderItems(testimonies, 'Testimonial')}</div>;
};

export default TestimonialsContainer;
