import React from 'react';
import CategoryNav from '../components/CategoryNav';

const CategoryNavContainer = (props: any) => {
  const {
    upgp_inter_homepage_category_browser_title: title,
    upgp_inter_homepage_category_browser_subtitle: subtitle,
    upgp_inter_homepage_category_browser_categories: categories,
  } = props;
  return (
    <CategoryNav variant={'homepage'} title={title} subtitle={subtitle} categories={categories} />
  );
};

export default CategoryNavContainer;
