import React from 'react';
import { SVGProps } from 'react';

const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={8.618} height={15.524} {...props}>
    <g>
      <path
        d="M8.437 7.338 1.637.282A1.087 1.087 0 0 0 .367.141a.609.609 0 0 0-.181.988l6.4 6.633-6.4 6.633a.609.609 0 0 0 .181.988 1.083 1.083 0 0 0 .544.141 1.014 1.014 0 0 0 .726-.282l6.8-7.057a.585.585 0 0 0 0-.847Z"
        fill={`${props.fill ? props.fill : '#ffffff'}`}
      />
    </g>
  </svg>
);

export default ChevronRightIcon;
