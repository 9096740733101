import React, { FC } from 'react';
import { IAsSeenIn } from './AsSeenIn.def';
import { Link } from 'gatsby';
import { createSourceElements, createSrc, createSrcSet } from '../../utils';
import { MOBILE_MAX_WIDTH_MQ, SMALL_TAB_MIN_WIDTH_MQ } from '../../constants';

const AsSeenIn: FC<IAsSeenIn> = ({ logos, link, moreLink }) => {
  return (
    <section className="asSeenIn">
      <div className="container text-center">
        <h2>As seen in</h2>
        <Link to={link || '#'}>
          <ul>
            {logos.map((logo, index) => {
              const sourceElements = createSourceElements([
                {
                  media: MOBILE_MAX_WIDTH_MQ,
                  srcSet: createSrcSet(logo?.src || '', [
                    {
                      options: {
                        width: 500,
                        fit: 'bounds',
                      },
                    },
                  ]),
                },
                {
                  media: SMALL_TAB_MIN_WIDTH_MQ,
                  srcSet: createSrcSet(logo?.src || '', [
                    {
                      options: {
                        width: 250,
                        height: 202,
                        fit: 'bounds',
                      },
                    },
                  ]),
                },
              ]);

              logo = {
                ...logo,
                src: createSrc(logo.src || ''),
              };

              return (
                <li key={index}>
                  <picture>
                    <img {...logo} loading="lazy" />
                    {sourceElements}
                  </picture>
                </li>
              );
            })}
          </ul>
        </Link>
        {moreLink && (
          <>
            <div className="spacer"></div>
            <a className="btn-standard btn-standard-mobile" href={moreLink.link}>
              {moreLink.text}
            </a>
          </>
        )}
      </div>
    </section>
  );
};

export default AsSeenIn;
