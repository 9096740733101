import React, { FC } from 'react';
import { ICategoryNavItem } from './NewCategoryItem.def';
import { getNavIcon } from '../../../../utils/getNavIcon';
import { Link } from 'gatsby';

const NewCategoryNavItem: FC<ICategoryNavItem> = ({
  assetClassName,
  label,
  asset,
  link,
  iconTop,
}) => {
  return (
    <Link to={link} className={`category-nav-item ${asset ? 'category-nav-item-image' : ''}`}>
      {assetClassName && (
        <div className="category-nav-item__icon">
          <img src={getNavIcon(assetClassName)} alt={assetClassName} />
        </div>
      )}
      {iconTop && (
        <figure className="category-nav-item__icon">
          <img src={iconTop.src} alt={iconTop.alt} loading="lazy" />
        </figure>
      )}
      <h4 className="category-nav-item__label">{label}</h4>
      {asset && (
        <figure className="category-nav-item__image">
          <img src={asset.src} alt={asset.alt} loading="lazy" />
        </figure>
      )}
    </Link>
  );
};

export default NewCategoryNavItem;
