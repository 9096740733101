import { IHeading } from '../../components/Article/components/TableOfContents/components/HeadingsContainer/HeadingsContainer.def';
import getKeyTakeawaysOptions from '../static-queries/getKeyTakeawaysOptions';

export const getTableOfContentsHeadings = (articleContent: JSX.Element[]) => {
  const headings: IHeading[] = [];

  articleContent.forEach((articleContent) => {
    if (
      typeof articleContent !== 'string' &&
      typeof articleContent?.type === 'string' &&
      articleContent?.type === 'h2' &&
      articleContent.props.id
    ) {
      const id = articleContent?.props.id;
      let title;
      if (articleContent?.props?.children?.type) {
        // The code below removes html elements inside the heading element and get the string value
        title = articleContent.props.children.props?.children;
      }
      if (!title) {
        title = articleContent?.props.children;
      }
      headings.push({
        link: `#${id}`,
        title,
        subHeadings: [],
      });
    }
    // The code checks if key takeaways is present and adds title and id to the TOC headings
    if (
      typeof articleContent !== 'string' &&
      typeof articleContent?.type === 'string' &&
      articleContent?.type === 'section' &&
      articleContent.props.className === 'key-takeaways'
    ) {
      const ktTitle = getKeyTakeawaysOptions() || '';

      headings.push({
        link: `#${slugifyKeyTakeawaysTitle(ktTitle)}`,
        title: ktTitle,
        subHeadings: [],
      });
    }
  });

  return headings;
};

/**
 * Slugifies the key takeaways title, coming from WP
 * in order to be used in the TOC ids (anchors)
 * @param {string} ktTitleFromWp title coming from WP.
 * @returns {string} Slugified version, prepended 'toc'.
 */
export const slugifyKeyTakeawaysTitle = (ktTitleFromWp: string): string => {
  if (!ktTitleFromWp) {
    return '';
  }
  return `toc-${ktTitleFromWp.replaceAll(' ', '-').toLowerCase()}`;
};
