import { IWpTopCCContentForReviews } from '../../components/TopRelatedContent/TopRelatedContent.def';

export const getTopContentItemsReviews = (
  topContentFromWP: IWpTopCCContentForReviews | undefined
) => {
  if (!topContentFromWP) {
    return null;
  }

  const title = topContentFromWP.upgpCcReviewsTopContentTitle || '';
  const categories =
    topContentFromWP.upgpCcReviewsTopContentColumns?.map((item) => {
      const innerLinks = item.upgpCcReviewsTopContentColumnLinks.map((link) => {
        return {
          text: link.upgpCcReviewsTopContentColumnLinksText || '',
          link: link.upgpCcReviewsTopContentColumnLinksUrl || '#',
        };
      });

      return {
        title: item.upgpCcReviewsTopContentColumnTitle || '',
        viewAllLink: {
          link: item.upgpCcReviewsTopContentColumnBottomLink || '#',
          text: item.upgpCcReviewsTopContentColumnBottomText || '',
        },
        links: innerLinks || [],
      };
    }) || [];

  return { title, categories };
};
