import * as React from 'react';
import { SVGProps } from 'react';

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16.536} height={12.026} {...props}>
    <path
      data-name="Path 24"
      d="m.22 6.544 5.261 5.262a.752.752 0 1 0 1.063-1.063L2.566 6.764h13.218a.752.752 0 1 0 0-1.5H2.566l3.979-3.978A.752.752 0 1 0 5.482.223L.22 5.485a.752.752 0 0 0 0 1.063Z"
      fill={`${props.fill ? props.fill : '#d8d8d8'}`}
    />
  </svg>
);

export default ArrowLeftIcon;
