import React, { useState, useEffect, FC } from 'react';

import ScrollerPure from './ScrollerPure';
import { IScroller, IScrollerSettings } from './Scroller.def';

// Defaults to control the number of tiles to show in the scroller based on the screen width.
// These values can be overridden by specifying a new array in the scrollerSettings prop.
// The number of tiles to show applies if the screen width is less than or equal to the specified
// breakpoint (in px), but larger than the next one in the list.
// TODO: Also control the number of tiles to scroll at different breakpoints.
const DEFAULT_SETTINGS = {
  defaultTilesToShow: 6,
  responsive: [
    {
      breakpoint: 1500,
      tilesToShow: 5,
    },
    {
      breakpoint: 1023,
      tilesToShow: 3,
    },
    { breakpoint: 500, tilesToShow: 2 },
  ],
};

const Scroller: FC<IScroller> = ({ children, className, scrollerSettings = null }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [tilesDisplayed, setTilesDisplayed] = useState(DEFAULT_SETTINGS.defaultTilesToShow);

  const calculateTilesToDisplay = () => {
    if (typeof window === undefined) return;
    const screenWidth = window.innerWidth;
    const settings: IScrollerSettings = scrollerSettings || DEFAULT_SETTINGS;
    let tilesToShow = settings.defaultTilesToShow || DEFAULT_SETTINGS.defaultTilesToShow;
    if (settings.responsive && settings.responsive.length && settings.responsive.length > 0) {
      for (let i = 0; i < settings.responsive.length; i++) {
        const responsiveItem = settings.responsive[i];
        if (responsiveItem && responsiveItem.breakpoint) {
          if (screenWidth > responsiveItem.breakpoint) {
            break;
          } else {
            tilesToShow = responsiveItem.tilesToShow;
          }
        }
      }
    }

    setTilesDisplayed(tilesToShow);
  };

  useEffect(() => {
    calculateTilesToDisplay();

    // Add Event Listener to handle resizing of the tiles when the screen size changes
    const handleResize = () => {
      calculateTilesToDisplay();
    };

    // Consider using throttling here (see https://chat.openai.com/share/78610a99-9e24-4665-8670-a518e050b0cc)
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const clickLeft = () => {
    setScrollPosition((prevOffset) =>
      prevOffset === 0 ? children.length - tilesDisplayed : prevOffset - 1
    );
  };
  const clickRight = () => {
    setScrollPosition((prevOffset) =>
      prevOffset === children.length - tilesDisplayed ? 0 : prevOffset + 1
    );
  };
  return (
    <ScrollerPure
      tiles={children}
      className={className}
      onLeftArrowClick={clickLeft}
      onRightArrowClick={clickRight}
      scrollPosition={scrollPosition}
      tilesDisplayed={tilesDisplayed}
    />
  );
};

export default Scroller;
