const useIsMobile = (type: 'tablet' | 'phone' = 'tablet') => {
  if (typeof window !== 'undefined') {
    if (type === 'tablet') {
      return window.innerWidth < 900;
    } else if (type === 'phone') {
      return window.innerWidth < 700;
    }
  }
  return false;
};

export default useIsMobile;
