import React from 'react';
import AboutContent from '../components/About/components/AboutContent';
import parse from 'html-react-parser';

const AboutContentContainer = (props: any) => {
  const { upgp_gb_about_main_content: wpMainContent } = props;
  const mainContent = parse(wpMainContent);
  return (
    <section>
      <AboutContent mainContent={mainContent} />
    </section>
  );
};

export default AboutContentContainer;
