import React, { FC } from 'react';
import { renderItems } from '../../../../utils';
import Scroller from '../../../Scroller';
import { ITeamMembers } from './TeamMembers.def';
import { IScrollerSettings } from '../../../Scroller/Scroller.def';

const TeamMembers: FC<ITeamMembers> = ({ members, moreLink, title, isLargeVariant, className }) => {
  const renderTeamMembers = (isInScroller: boolean) => {
    const extraProps = { isInScroller };
    return renderItems(members, 'TeamMember', null, extraProps);
  };

  const scrollerSettings: IScrollerSettings = {
    defaultTilesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        tilesToShow: 2,
      },
    ],
  };
  return (
    <section
      className={`aboutBlockBrowser ${isLargeVariant ? 'aboutBlockBrowserLarge-variant' : ''} ${
        className ? className : ''
      }`}
    >
      <div className="container">
        <div className="blockHeader">
          {title && <h2>{title}</h2>}
          {moreLink && (
            <a href={moreLink.link} className="btn-standard">
              {moreLink.label}
            </a>
          )}
        </div>
        {isLargeVariant ? (
          <div className="container meet-the-team">
            {renderItems(
              members.map((member) => ({
                ...member,
                thumbnail: {
                  ...member.thumbnail,
                  src: member.thumbnail?.src?.replace('s=96', 's=' + '150'),
                },
                isAboutMeetTeam: true,
              })),
              'TeamMember'
            )}
          </div>
        ) : (
          <Scroller className="teamMembersScroller" scrollerSettings={scrollerSettings}>
            {renderTeamMembers(true)}
          </Scroller>
        )}
      </div>
    </section>
  );
};

export default TeamMembers;
