import React from 'react';

import AsSeenIn from '../components/AsSeenIn';
import { ILink } from '../models/ILink';

const AsSeenInContainer = (props: any) => {
  const { upgp_gb_press_mentions_logos: pmLogos, upgp_gb_press_mentions_more: wpMoreLink } = props;
  const moreLink: ILink | null =
    wpMoreLink && wpMoreLink.upgp_gb_press_mentions_more_link.length
      ? {
          link: wpMoreLink.upgp_gb_press_mentions_more_link,
          text: wpMoreLink.upgp_gb_press_mentions_more_text,
        }
      : null;
  return (
    <AsSeenIn
      link={moreLink?.link || '/press-mentions/'}
      logos={pmLogos.map((logo: any) => ({
        alt: logo.alt,
        src: logo.image,
      }))}
      moreLink={moreLink}
    />
  );
};

export default AsSeenInContainer;
