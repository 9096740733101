import React, { FC, useContext } from 'react';
import CardDataContext from '../../context/CardDataContext';
import { IApplyBtnShortcode } from './ApplyBtnShortcode.def';
import GoLink from '../GoLink/GoLink';
import { renderError } from '../../utils/helpers/card';

const ApplyBtnShortcode: FC<IApplyBtnShortcode> = ({ cardId, placing = '/TT/' }) => {
  if (!cardId) {
    return renderError('No Card ID set.');
  }
  const { cardDataJson } = useContext(CardDataContext);
  if (!cardDataJson?.data?.length) return renderError(`JSON API Error.`);
  const cardData = cardDataJson.data.find((card) => card.cardId === cardId);

  // Get the affiliate link.
  const goLink = cardData?.affiliateLinkReviewPage;
  const needToAddSource = cardData?.upgpCardReviewAddTrackingSourceType || false;

  if (goLink) {
    return (
      <GoLink
        link={goLink}
        placingFromProps={placing}
        needToAddSource={needToAddSource}
        children="Learn More"
        classNameFromProps="btn btn--apply-now"
      />
    );
  }

  return renderError(`Affiliate Link for Card ${cardId} not found.`);
};

export default ApplyBtnShortcode;
