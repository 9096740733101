import React, { FC } from 'react';
import AsideMeta from './AsideMeta';
import { IFeaturedMeta } from './FullFeatured.def';
import ImageOverlay from './ImageOverlay';

const FeaturedMeta: FC<IFeaturedMeta> = ({ image, alt, asideMeta, linkClick = () => {} }) => (
  <>
    {image && (
      <ImageOverlay
        thumbnail={{
          src: image,
          alt,
        }}
      />
    )}
    <AsideMeta {...asideMeta} linkClick={linkClick} />
  </>
);

export default FeaturedMeta;
