import React, { FC } from 'react';
import { renderItems } from '../../utils';
import { ICategoryNav } from './CategoryNav.def';

const CategoryNav: FC<ICategoryNav> = ({
  variant,
  title,
  subtitle,
  categories,
  allLink,
  allText,
}) => {
  const sectionClass = variant === 'homepage' ? 'categoryNavSection' : undefined;
  const navClass = variant === 'category' ? 'categoryNavinside_variant' : undefined;
  return (
    <section className={sectionClass}>
      <div className="container">
        {sectionClass && (
          <>
            {title && <h1>{title}</h1>}
            {subtitle && <p>{subtitle}</p>}
            <nav className="categoryNav">
              <ul>{renderItems(categories, 'CategoryLink')}</ul>
            </nav>
          </>
        )}
        {navClass && (
          <nav className={`categoryNav ${navClass}`}>
            {subtitle && <h2>{subtitle}</h2>}
            <ul>
              {renderItems(categories, 'CategoryLink')}
              {allText && (
                <li className="catLink cat-viewAll">
                  <a href={allLink}>{allText}</a>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
};

export default CategoryNav;
