import React from 'react';
import HowWeWork from '../components/HowWeWork';
import parse from 'html-react-parser';

interface IGBHowWeWorkPoint {
  upgp_how_work_point_title: string;
  upgp_how_work_point_content: string;
}

const HowWeWorkContainer = (props: any) => {
  const { upgp_how_work_title: title, upgp_how_work_points: pointsData } = props;
  const points = pointsData.map((point: IGBHowWeWorkPoint) => ({
    title: point.upgp_how_work_point_title,
    content: parse(point.upgp_how_work_point_content),
  }));
  return (
      <HowWeWork title={title} points={points} />
  );
};

export default HowWeWorkContainer;
