import React, { FC } from 'react';
import { Link } from 'gatsby';
import { IArticleExcerpt } from './Article.def';
import { processCopyright } from '../../../utils/helpers';
import parser from 'html-react-parser';
import { createSourceElements, createSrc, createSrcSet } from '../../../utils';
import {
  LARGE_TAB_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
} from '../../../constants';

const ArticleOverlay: FC<IArticleExcerpt> = ({
  imageOverlay = false,
  thumbnail,
  category,
  title,
  link,
  moreLink,
}) => {
  const sourceElements = createSourceElements([
    {
      media: LARGE_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 500,
            height: 500,
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 450,
            height: 450,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);

  if (thumbnail) {
    thumbnail = {
      ...thumbnail,
      src: createSrc(thumbnail.src || ''),
    };
  }

  return (
    <div className="newsBlock">
      {imageOverlay && (
        <Link to={link} className="overlay-link">
          <div className="image-overlay"></div>
        </Link>
      )}
      <figure>
        {thumbnail && (
          <Link to={link}>
            <picture>
              {sourceElements}
              <img {...thumbnail} loading="lazy" />
            </picture>
          </Link>
        )}
      </figure>
      <div className="newsBlockDetails">
        {category?.link && (
          <Link to={category.link} className="postCategory">
            {category.text}
          </Link>
        )}
        {!category?.link && category?.text && <span className="postCategory">{category.text}</span>}
        <h3>
          <Link to={link}>{parser(processCopyright(title))}</Link>
        </h3>
        {moreLink?.link && (
          <Link to={moreLink.link} className="btn-standard btn-small text-base">
            {moreLink.text}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ArticleOverlay;
