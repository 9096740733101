import { graphql, useStaticQuery } from 'gatsby';

const getAllEmailOptins = () => {
  const { wpUpOption } = useStaticQuery(graphql`
    query {
      wpUpOption(slug: { eq: "email-optin" }) {
        allEmailOptins {
          upgpDisplayEmailOptin {
            title
            description
            hideOn
            displayEmailOptin
            optinStyle
            submitButtonText
            listid
            redirectAfterSubmit
            image{
              altText
              sourceUrl
            }
            displayOn {
              displayCategory
              displayPagesUrl
              displayPosts {
                ... on WpPost {
                  databaseId
                }
              }
              displayPages {
                ... on WpPage {
                  databaseId
                }
              }
              displayCategories {
                databaseId
                slug
              }
              displayTags {
                databaseId
                slug
              }
            }
          }
        }
      }
    }
  `);

  return wpUpOption as Queries.WpUpOption;
};

export default getAllEmailOptins;
