import React, { FC } from 'react';
import { IOrangeBadge } from './OrangeBadge.def';
import OrangeBadgeIcon from '../../../svgs/OrangeBadgeIcon';

const OrangeBadge: FC<IOrangeBadge> = ({ orangeBadgeText }) => {
  return (
    <div className="orangeBadge">
      <OrangeBadgeIcon fill="#fff" />
      <span>{orangeBadgeText}</span>
    </div>
  );
};

export default OrangeBadge;
