import React from 'react';
import SkimlinksMerchantDomainsContext from '../context/SkimlinksMerchantDomainsContext';
import { graphql, useStaticQuery } from 'gatsby';

interface ChildProps {
  children: JSX.Element;
}

const SkimlinksMerchantDomainsProvider = ({ children }: ChildProps) => {
  const domainsQuery = useStaticQuery(graphql`
    query SkimlinksMerchantDomainsQuery {
      wpUpOption(slug: {eq: "skimlinks-merchant-domains"}) {
        skimlinksMerchantDomains {
          upgpSkimlinksMerchantDomains
        }
      }
    }
  `);

  return (
    <SkimlinksMerchantDomainsContext.Provider
      value={{
        domains: JSON.parse(
          domainsQuery?.wpUpOption?.skimlinksMerchantDomains?.upgpSkimlinksMerchantDomains || '[]'
        )
      }}
    >
      {children}
    </SkimlinksMerchantDomainsContext.Provider>
  );
};

export default SkimlinksMerchantDomainsProvider;
