import React, { FC, memo, useEffect, useRef } from 'react';
import { IScrollerPure } from './Scroller.def';
import ArrowLeftIcon from '../../svgs/ArrowLeftIcon';

const ScrollerPure: FC<IScrollerPure> = ({
  tiles,
  className,
  onLeftArrowClick,
  onRightArrowClick,
  scrollPosition,
  tilesDisplayed,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const scrollContainerElement = scrollContainerRef?.current;
    if (scrollContainerElement) {
      const scrollContainerStyle = window.getComputedStyle(scrollContainerElement);
      const totalWidth = parseFloat(scrollContainerStyle.width);
      const scrollContainerPaddingLeft = parseFloat(scrollContainerStyle.paddingLeft);
      const tileWidth = totalWidth / tilesDisplayed;
      const scrollLeft = (tileWidth - scrollContainerPaddingLeft / tilesDisplayed) * scrollPosition;
      scrollContainerElement.scrollTo({
        top: 100,
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  }, [tilesDisplayed, scrollPosition]);

  const tileDynamicStyles = {
    width: `${100 / tilesDisplayed}%`,
  };
  const needToShowControls = tiles && tiles.length && tiles.length > tilesDisplayed;
  return (
    <>
      <div ref={scrollContainerRef} className={`horizontalScroll ${className ? className : ''}`}>
        {tiles &&
          tiles.map((tile, index) => (
            <div key={index} className="horizontalScrollTile" style={tileDynamicStyles}>
              {tile}
            </div>
          ))}
      </div>
      {needToShowControls && (
        <div className="horizontalScrollControls">
          <div className="leftArrow" onClick={onLeftArrowClick}>
            <ArrowLeftIcon fill="#f7941e" />
          </div>
          <div className="rightArrow" onClick={onRightArrowClick}>
            <ArrowLeftIcon fill="#f7941e" />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(ScrollerPure);
