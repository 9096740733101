import React, { FC } from 'react';
import { IBlockHeader } from './BlockHeader.def';

const BlockHeader: FC<IBlockHeader> = ({ moreLink, subTitle, title, headline }) => {
  return (
    <div className="blockHeader">
      <header className="titles">
        {!!title && <h2>{title}</h2>}
        {subTitle && <p>{subTitle}</p>}
        {headline && <h4 className="blockHeader__headline">{headline}</h4>}
      </header>
      {moreLink && (
        <a href={moreLink.link} className={`btn-standard ${subTitle && 'hasSubtitle'}`}>
          {moreLink.text}
        </a>
      )}
    </div>
  );
};

export default BlockHeader;
