import React from 'react';

import { getStyleObjectFromString } from '..';

export const renderIframe = (attribs: { [name: string]: string }) => {
  const { 'data-src': dataSrc, src, children, style, ...rest } = attribs;
  const iframeStyle = style ? getStyleObjectFromString(style) : {};

  return <iframe src={dataSrc} style={iframeStyle} {...rest} loading="lazy" />;
};
