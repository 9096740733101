import React from 'react';
import { SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21.035} height={21.787} {...props}>
    <path
      d="m20.716 19.845-5.185-5.393a8.793 8.793 0 1 0-6.733 3.142A8.7 8.7 0 0 0 13.836 16l5.225 5.434a1.147 1.147 0 1 0 1.655-1.589ZM8.797 2.295a6.5 6.5 0 1 1-6.5 6.5 6.509 6.509 0 0 1 6.5-6.5Z"
      fill={`${props.fill ? props.fill : '#003458'}`}
    />
  </svg>
);

export default SearchIcon;
