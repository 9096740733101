import React, { useState, useEffect, FC } from 'react';

import { getCookie } from '../utils/helpers/cookie';
import {
  PULSE_SLIDE_UP_SESSION_ITEM,
  CCM_PREFERENCES_COOKIE_NAME,
  CCM_SESSION_ID,
  UP_STAFF_MEMBER_PARAM_NAME,
  UP_STAFF_MEMBER_PARAM_VALUE,
} from '../constants';
import getAllEmailOptins from '../utils/static-queries/getEmailOptins';
import { IWPEmailOptinObject } from '../components/EmailOptin/EmailOptin.def';
import { IWpSingleEmailOptin } from '../components/EmailOptin/EmailOptin.def';
import { isUrlInList } from '../utils';
import NewsletterSignupContainer from './NewsletterSignupContainer';
import { IGatsbyPageContext } from '../models/IGatsbyPageContext';

interface IPulsePopupContainer {
  context: IGatsbyPageContext;
}

const PulsePopupContainer: FC<IPulsePopupContainer> = ({ context }) => {
  const [pulsePopupActive, setPulsePopupActive] = useState(false);
  const { allEmailOptins } = getAllEmailOptins();

  const findMatchingEmailOptin = (
    emailOptinObject: IWPEmailOptinObject,
    postContext: IGatsbyPageContext,
  ): IWpSingleEmailOptin | null => {
    if (
      !emailOptinObject ||
      !postContext ||
      !Array.isArray(emailOptinObject.upgpDisplayEmailOptin)
    ) {
      return null;
    }

    for (const emailOptin of emailOptinObject.upgpDisplayEmailOptin) {
      // Continue if any hideURL matches the post URL or displayEmailOptin is set false.
      if (
        (emailOptin?.hideOn &&
          postContext.link &&
          isUrlInList(emailOptin.hideOn, postContext.link)) ||
        !emailOptin.displayEmailOptin
      ) {
        continue;
      }

      for (const condition of emailOptin?.displayOn) {
        // Check for URL match
        if (
          condition.displayPagesUrl &&
          postContext.link &&
          isUrlInList(condition.displayPagesUrl, postContext.link)
        ) {
          return emailOptin;
        }

        // Check for category match.
        if (condition.displayCategories) {
          const categorySlugs = postContext.categories?.nodes?.map((node) => node?.slug);
          for (const category of condition.displayCategories) {
            if (categorySlugs?.includes(category?.slug)) {
              return emailOptin;
            }
          }
        }

        // Check for tag match.
        if (condition.displayTags) {
          const postTags = postContext?.tags?.nodes?.map((node) => node?.slug);
          for (const tag of condition.displayTags) {
            if (postTags?.includes(tag?.slug)) {
              return emailOptin;
            }
          }
        }

        // Check for specific posts match.
        if (condition.displayPosts) {
          const postIds = condition.displayPosts.map((post) => post?.databaseId);
          if (postIds?.includes(postContext?.databaseId)) {
            return emailOptin;
          }
        }
      }
    }

    return null;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const isUpStaffMember =
        localStorage.getItem(UP_STAFF_MEMBER_PARAM_NAME) === UP_STAFF_MEMBER_PARAM_VALUE;
      if (
        // Show the popup only if there is no Cookie Conset Manager, or there is one and the user has selected his preferences.
        // Also do not show the popup for UP staff members.
        (!getCookie(CCM_SESSION_ID) || !!getCookie(CCM_PREFERENCES_COOKIE_NAME)) &&
        !getCookie(PULSE_SLIDE_UP_SESSION_ITEM) &&
        !sessionStorage.getItem(PULSE_SLIDE_UP_SESSION_ITEM) &&
        !isUpStaffMember
      ) {
        sessionStorage.setItem(PULSE_SLIDE_UP_SESSION_ITEM, '1');
        setPulsePopupActive(true);
        clearInterval(timer);
      }
    }, 30000);

    return () => clearInterval(timer);
  }, []);

  const customOptin = findMatchingEmailOptin(allEmailOptins as IWPEmailOptinObject, context);

  return pulsePopupActive ? <NewsletterSignupContainer isPopup customOptin={customOptin} /> : null;
};

export default PulsePopupContainer;
