import { HTEventsContextType } from '../../context/HTEventsContext/HTEventsContext.def';
import { WindowLocation } from '@reach/router';
import { getSessionNumberFromLS, getUserIdFromLS } from './goLinks';

/**
 * Send a Hightouch Page event
 * @param htevents - the global handle
 * @param category - the category list for the page, in the format of a comma separated list of strings
 * @param title - the title of the page
 * @param location - location data for the page, from the react page context
 * @param referrer - last page visited
 */
export const sendHtPageView = (
  htevents: HTEventsContextType,
  category: string,
  title: string,
  location: WindowLocation,
  referrer: string,
) => {
  if (htevents) {
    const biSessionNumber = getSessionNumberFromLS();
    htevents.page(category, title, {
      path: location?.pathname || '',
      url: location?.href || '',
      referrer,
      biSessionNumber,
    });
  }
};

/**
 * Send a Hightouch Identify Event containig the bi_user_id from local storage as the userId
 * @param htevents - the global handle
 * @param userId - the userId to send
 */
export const sendHtIdentifyEvent = (htevents: HTEventsContextType, userId: string) => {
  if (htevents && userId) {
    htevents.identify(userId);
  }
};

/**
 * Send a Hightouch Track event. Include the bi_session_id as a property.
 * @param htevents - The global handle.
 * @param eventType - The event type, to pass as the event parameter.
 * @param eventProperties - An object containing additional fields to pass into the properties parameter.
 */
export const sendHtTrackEvent = (
  htevents: HTEventsContextType,
  eventType: string,
  eventProperties: object,
) => {
  const biSessionNumber = getSessionNumberFromLS();
  const properties = {
    ...eventProperties,
    biSessionNumber,
  };
  if (htevents) {
    htevents.track(eventType, properties);
  }
};
