import React, { FC } from 'react';
import { Link } from 'gatsby';
import UpgradedPointsFooterLogo from '../../svgs/UpgradedPointsFooterLogo';
import { IFooter } from './Footer.def';
import FooterPlaneGraphic from '../../svgs/FooterPlaneGraphic';
import { getSocialIcon } from '../../utils/getSocialIcon';
import { isCreditCardsReviewPage } from '../../utils';
import { HOMEPAGE_PAGE_ID } from '../../constants';

const Footer: FC<IFooter> = ({
  siteLine,
  socialFooterLinks,
  footerLinks,
  legalLinks,
  pageId,
}) => {
  let isCreditCardReviews = false;
  const isHomepage = HOMEPAGE_PAGE_ID === pageId && typeof pageId !== 'undefined';

  if (typeof location !== 'undefined') {
    isCreditCardReviews = isCreditCardsReviewPage(location.pathname);
  }

  const footerSocials = (
    <ul className="footerSocial">
      {socialFooterLinks.map(({ link, text, ariaLabel }, index) => {
        const props: any = {};
        if (ariaLabel) {
          props['aria-label'] = ariaLabel;
        }
        return (
          <li key={index}>
            <a href={link} {...props} target="_blank" rel="noopener">
              {text && (
                <figure>
                  <img src={getSocialIcon(text)} alt={text} />
                </figure>
              )}
            </a>
          </li>
        );
      })}
    </ul>
  );

  const YearTag = () => <span>{new Date().getFullYear()}</span>;

  return (
    <footer className={`${isCreditCardReviews ? 'credit-card-reviews-page' : ''}`}>
      <FooterPlaneGraphic ismobile="true" className="mobileGraphic" />
      <FooterPlaneGraphic className="desktopGraphic" />
      <div className="container">
        <div className="footerHeadContainer">
          <div className="footerHead">
            <UpgradedPointsFooterLogo />
          </div>
          <div className="footerContent">
            <div className="aside">
              <p className="siteLine">{siteLine}</p>
              {footerSocials}
            </div>
            <div className="main">
              <div className="footerNav ">
                {footerLinks.map((links, index) => {
                  return (
                    <ul key={index}>
                      {links.map(({ link, text }, index) => (
                        <li key={index}>{link && <Link to={link}>{text}</Link>}</li>
                      ))}
                    </ul>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p id="ccm-footer-container">
            {legalLinks.map(({ link, text }, index) => (
              <React.Fragment key={index}>
                <Link to={link}>{text}</Link>
                {index !== legalLinks.length - 1 && <span className="pipe"> | </span>}
              </React.Fragment>
            ))}
          </p>
          <p className="trademark">
            &copy; <YearTag /> Upgraded Points&trade;, LLC. All Rights Reserved.
          </p>
        </div>
        <div className="footerBadges">
          <a
            className="footer-badges__link dmca-badge"
            href="http://www.dmca.com/Protection/Status.aspx?ID=a5626ca5-33a4-47d4-ac70-bb793266eb4d&amp;refurl=https://upgradedpoints.com/"
            rel="follow"
            title="DMCA.com Protection Status"
            data-wpel-link="internal"
          >
            <img
              className="footer-badges__img"
              width="120"
              height="24"
              src="/shared/dmca_protected_sml_120n.png"
              data-src="/shared/dmca_protected_sml_120n.png"
              alt="DMCA.com Protection Status"
            />
          </a>
          <div className="footer-badges__link">
            <img
              className="footer-badges__img comodo-logo js-lazy-image--handled anim-fade-in"
              width="100"
              height="85"
              src="/shared/comodo-secure-seal.png"
              data-src="/shared/comodo-secure-seal.png"
              alt="Comodo Secure Seal"
            />
          </div>
        </div>
        {!isHomepage && (
          <div className="footer-disclaimer">
            <p>
              <span style={{ textDecoration: 'underline' }}>Disclaimer:</span> Upgraded Points, LLC
              and its website, UpgradedPoints.com, provides information on credit cards, reward
              programs, travel information and ancillary information concerning travel and credit
              cards. The information provided is for informational purposes only and should not be
              considered financial, tax or legal advice.
            </p>
            <p>
              Upgraded Points, LLC uses reasonable efforts to maintain accurate information on the
              site — and prior to applying for any credit card offers found on UpgradedPoints.com,
              all visitors should review other features of such credit cards including but not
              limited to interest rates, annual fees and transaction fees, and should determine the
              suitability of such credit cards considering their own individual financial position.
            </p>
            <p>
              <span style={{ textDecoration: 'underline' }}>Advertiser Disclosure:</span> Many of
              the credit card offers that appear on this site are from credit card companies from
              which we receive compensation. This compensation may impact how and where products
              appear on this site (including, for example, the order in which they appear). This
              site does not include all credit card companies or all available credit card offers.
              For more information on our advertisers and how we make money,&nbsp;
              <Link to="/advertising-policy/">see our advertising policy here.</Link> In addition,
              as an Amazon Associate we earn from qualifying purchases.
            </p>
            <p>
              <span style={{ textDecoration: 'underline' }}>Editorial Disclosure:</span> The content
              featured on UpgradedPoints.com has not been influenced, provided, or reviewed by the
              credit card companies mentioned. Any and all options, reviews, comments and analyses
              are the responsibility of the author(s) and not any Advertiser or credit card issuer.
            </p>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
