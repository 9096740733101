import React, { useState, useEffect } from 'react';
import CardDataContext from '../context/CardDataContext';
import { ICardData } from '@throttleup/esi-components';

interface ChildProps {
  children: JSX.Element;
}

interface ICardDataState {
  data: ICardData[];
}

const CardDataProvider = ({ children }: ChildProps) => {
  const [cardDataJson, setCardDataJson] = useState<ICardDataState | {}>({});
  const [chaseGoLinkNames, setChaseGoLinkNames] = useState([]);

  useEffect(() => {
    fetch(`${process.env['GATSBY_SITE_URL']}/wp-content/uploads/json/allcards.json`)
      .then((response) => response.json())
      .then((data) => {
        setCardDataJson({ data: data });
        const chaseGoLinkNameList = (data || [])
          .filter(
            (cardData: ICardData) =>
              cardData.cardProviderCardTables === 'Chase' && cardData.affiliateLinkReviewPage,
          )
          .map((cardData: ICardData) => cardData.affiliateLinkReviewPage?.split('/')[2])
          .filter(Boolean);
        setChaseGoLinkNames(chaseGoLinkNameList);
      });
  }, []);

  return (
    <CardDataContext.Provider value={{ cardDataJson, chaseGoLinkNames }}>
      {children}
    </CardDataContext.Provider>
  );
};

export default CardDataProvider;
