import {
  FULL_WIDTH_NO_HERO_MAX_WIDTH,
  FULL_WIDTH_PAID_LANDING_PAGE_MAX_WIDTH,
} from '../../constants';
import { ITableCondition } from '../../models/ITableCondition/ITableCondition';
import { TTemplate } from '../../models/TTemplate';

/**
 * Checks if the given table width is within the allowed maximum width
 * for specific templates and returns a boolean indicating the result.
 *
 * @param {TTemplate} templateName - The name of the template to check.
 * @param {number} tableWidth - The width of the table to check.
 * @returns {boolean} - Returns true if the table width is within the allowed
 *                      maximum width for the given template, otherwise false.
 */

export const isTableFit = (templateName: TTemplate, tableWidth: number) => {
  const conditions: ITableCondition[] = [
    {
      names: ['Full Width (No Hero Image)'],
      maxWidth: FULL_WIDTH_NO_HERO_MAX_WIDTH,
    },
    {
      names: ['Paid Landing Page - Full Width'],
      maxWidth: FULL_WIDTH_PAID_LANDING_PAGE_MAX_WIDTH,
    },
  ];

  return conditions.some(
    (condition) => condition.names.includes(templateName) && tableWidth <= condition.maxWidth,
  );
};
