import React, { FC } from 'react';
import { IExploreTopContent } from './ExploreTopContent.def';
import { Link } from 'gatsby';

const ExploreOurTopContent: FC<IExploreTopContent> = ({ title, categories }) => {
  return (
    <section className="explore-our-top-content container">
      <h2 className="explore-our-top-content__title">{ title ? title: ''}</h2>
      <div className="explore-our-top-content__categories">
        {categories.map(({ link, title, links }) => {
          if (!link || !title || !links || !Array.isArray(links)) {
            return null
          }
          return (
            <div key={title} className="explore-our-top-content__category">
              <h3 className="explore-our-top-content__category-title">
                <Link to={link}>{title}</Link>
                <ul className="explore-our-top-content__links">
                  {links.map(
                      ({
                        upgp_explore_top_content_category_links_link: link,
                        upgp_explore_top_content_category_links_title: title,
                      }) => {
                      if (!link || !title) {
                          return null
                      }
                      return (<li key={title}><Link to={link}>{title}</Link></li>)
                    }
                  )}
                </ul>
              </h3>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ExploreOurTopContent;
