import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import PageHistoryContext from '../context/PageHistoryContext';
import { PageHistoryContextType } from '../context/PageHistoryContext/PageHistoryContext.def';

interface ChildProps {
  children: JSX.Element;
}

export const PageHistoryProvider = ({ children }: ChildProps) => {
  const [pathData, setPathData] = useState<PageHistoryContextType>([]);
  const location = useLocation();

  useEffect(() => {
    const newPathData = [...pathData, location.pathname];
    setPathData(newPathData);
  }, [location.pathname]);

  return <PageHistoryContext.Provider value={pathData}>{children}</PageHistoryContext.Provider>;
};
