import { Element as ReactParserElement } from 'html-react-parser';
import { isElement } from '..';

/**
 * Computes various metrics for a table.
 *
 * This function calculates the total width of the table and tracks the effects of rowspan attributes
 * on the first column's cells. It also keeps track of the IDs of the first column's cells affected by rowspan attributes.
 *
 * @param {ReactParserElement} tbody - The table body element containing the rows and cells.
 * @returns {Object} An object containing the following properties:
 *   - {number} tableWidth - The total width of the table in pixels.
 *   - {string[]} firstColumnTableCellsAffectedByRowSpan - An array of IDs of the first column's cells affected by rowspan attributes.
 */

export const computeTableMetrics = (tbody: ReactParserElement) => {
  let tableWidth = 0;
  let totalNumberOfCellsPerRow = 0;
  const firstColumnTableCellsAffectedByRowSpan: string[] = [];
  let remainingColumnsAffectedByRowSpan = 0;
  let numberOfCellsWithNoWidths = 0;
  tbody.children.forEach((tr, rowIndex) => {
    if (isElement(tr)) {
      tr.children.forEach((td, cellIndex) => {
        if (isElement(td)) {
          if (rowIndex === 0) {
            const styleString = td.attribs['style'];
            const widthMatch = styleString?.match(/width\s*:\s*(\d+(?:\.\d+)?)px/);
            if (widthMatch && widthMatch[1]) {
              tableWidth += parseFloat(widthMatch[1]);
            } else {
              numberOfCellsWithNoWidths++;
            }
            if (td.attribs['colspan']) {
              totalNumberOfCellsPerRow += parseInt(td.attribs['colspan']);
            } else {
              totalNumberOfCellsPerRow++;
            }
          }
          if (cellIndex === 0) {
            if (td.attribs['rowspan']) {
              remainingColumnsAffectedByRowSpan = parseInt(td.attribs['rowspan']) - 1;
            } else if (remainingColumnsAffectedByRowSpan > 0) {
              firstColumnTableCellsAffectedByRowSpan.push(td.attribs['id'] as string);
              remainingColumnsAffectedByRowSpan--;
            }
          }
        }
      });
    }
  });
  return {
    tableWidth,
    firstColumnTableCellsAffectedByRowSpan,
    totalNumberOfCellsPerRow,
    numberOfCellsWithNoWidths,
  };
};
