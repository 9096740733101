import { ITooltipDetails } from '../../components/UserTooltip/UserTooltip.def';
import { IAuthorDetails } from '../../models/IAuthorDetails';
import { IAuthorExtraDetails } from '../../components/About/components/TeamMember/TeamMember.def';
import { ILink } from '../../models/ILink';
import parse from 'html-react-parser';

export const getAuthorDetails = (
  author?: Queries.Maybe<Queries.WpUser>,
  requiresLongBio?: boolean,
  imageSize?: number
): IAuthorDetails => {
  const imageUrl = author?.avatar?.url || '';
  let topicOfExpertise: ILink | null = null;

  if (author?.userSocials?.upgpUserExpertise) {
    topicOfExpertise = {
      text: author.userSocials.upgpUserExpertise?.name || '',
      link: author.userSocials.upgpUserExpertise?.uri || '#',
    };
  }

  const socials =
    author?.userSocials?.upgpUserSocialLinks?.map((socialLink) => ({
      link: socialLink?.upgpUserSocialLinksUrl || '',
      name: socialLink?.upgpUserSocialLinksName || '',
    })) || [];

  if (author?.userSocials?.upgpUserEmail) {
    socials.push({
      link: `mailto:${author?.userSocials?.upgpUserEmail}`,
      name: 'email',
    });
  }
  return {
    name: author?.name || '',
    firstName: author?.firstName || '',
    designation: author?.description || '',
    imageAlt: `${author?.name}'s image`,
    bio:
      (requiresLongBio ? author?.userBios?.upgpUserRichBio : author?.userBios?.upgpUserShortBio) ||
      '',
    imageUrl: imageSize ? imageUrl.replace('s=96', 's=' + imageSize.toString()) : imageUrl,
    socials,
    url: author?.uri || '',
    topicOfExpertise,
  };
};

export const getTooltipDetails = (
  author?: Queries.Maybe<Queries.WpUser>,
  editorData?: Queries.Maybe<Queries.WpPost_Posteditorsettings>
): ITooltipDetails => {
  const authorStructure = {
    id: author?.databaseId,
    name: author?.name || '',
    designation: author?.upgpAuthorDetails?.upgpEditorJobTitle || '',
    image: author?.avatar?.url || '',
    image_alt: `${author?.name}'s image`,
    countries_visited: author?.upgpAuthorDetails?.upgpCountriesVisited || '',
    us_states_visited: author?.upgpAuthorDetails?.upgpUsStatesVisited || '',
    quick_bio: author?.upgpAuthorDetails?.upgpEditorCustomExcerpt || '',
    url: author?.uri || '#',
    posts_edited: author?.upgpAuthorDetails?.upgpAuthorCountEditedPosts || 0,
    posts_authored: author?.postsCount || 0,
  };

  const editorOneStructure = {
    id: editorData?.upgpPostEditor?.databaseId,
    name: editorData?.upgpPostEditor?.name || '',
    designation: editorData?.upgpPostEditor?.upgpAuthorDetails?.upgpEditorJobTitle || '',
    image: editorData?.upgpPostEditor?.avatar?.url || '',
    image_alt: `${editorData?.upgpPostEditor?.name}'s image`,
    countries_visited: editorData?.upgpPostEditor?.upgpAuthorDetails?.upgpCountriesVisited || '',
    us_states_visited: editorData?.upgpPostEditor?.upgpAuthorDetails?.upgpUsStatesVisited || '',
    quick_bio: editorData?.upgpPostEditor?.upgpAuthorDetails?.upgpEditorCustomExcerpt || '',
    url: editorData?.upgpPostEditor?.uri || '#',
    posts_edited: editorData?.upgpPostEditor?.upgpAuthorDetails?.upgpAuthorCountEditedPosts || 0,
    posts_authored: editorData?.upgpPostEditor?.postsCount || 0,
  };

  const editorTwoStructure = {
    id: editorData?.upgpPostEditorTwo?.databaseId,
    name: editorData?.upgpPostEditorTwo?.name || '',
    designation: editorData?.upgpPostEditorTwo?.upgpAuthorDetails?.upgpEditorJobTitle || '',
    image: editorData?.upgpPostEditorTwo?.avatar?.url || '',
    image_alt: `${editorData?.upgpPostEditorTwo?.name}'s image`,
    countries_visited: editorData?.upgpPostEditorTwo?.upgpAuthorDetails?.upgpCountriesVisited || '',
    us_states_visited: editorData?.upgpPostEditorTwo?.upgpAuthorDetails?.upgpUsStatesVisited || '',
    quick_bio: editorData?.upgpPostEditorTwo?.upgpAuthorDetails?.upgpEditorCustomExcerpt || '',
    url: editorData?.upgpPostEditorTwo?.uri || '#',
    posts_edited: editorData?.upgpPostEditorTwo?.upgpAuthorDetails?.upgpAuthorCountEditedPosts || 0,
    posts_authored: editorData?.upgpPostEditorTwo?.postsCount || 0,
  };

  return {
    postAuthor: author ? authorStructure : null,
    editorOne: editorData?.upgpPostEditor ? editorOneStructure : null,
    editorTwo: editorData?.upgpPostEditorTwo ? editorTwoStructure : null,
  };
};

export const getAuthorExtraDetails = (
  extraDetails?: Queries.Maybe<Queries.WpUser_Upgpauthordetails>
): IAuthorExtraDetails => {
  const removePTags = (html: string) => {
    let str = html.replace('<p>', '').replace('</p>', '');
    return str;
  };

  return {
    countriesVisited: extraDetails?.upgpCountriesVisited
      ? parseInt(extraDetails.upgpCountriesVisited)
      : 0,
    usStatesVisited: extraDetails?.upgpUsStatesVisited
      ? parseInt(extraDetails.upgpUsStatesVisited)
      : 0,
    highestHotelStatus: extraDetails?.upgpAuthorDetailsHighestHotelStatus || '',
    highestAirlineStatus: extraDetails?.upgpAuthorDetailsHighestAirlineStatus || '',
    favoriteAirlineAlliance: extraDetails?.upgpAuthorDetailsFavoriteAirlineAlliance || '',
    favoriteFirstClass: extraDetails?.upgpAuthorDetailsFavoriteFirstClass || '',
    // favoriteBusinessClass:  '',
    favoriteBusinessClass: extraDetails?.upgpAuthorDetailsFavoriteBusinessClass
      ? parse(removePTags(extraDetails?.upgpAuthorDetailsFavoriteBusinessClass))
      : '',
    // bestRedemptionValue:  '',
    bestRedemptionValue: extraDetails?.upgpAuthorDetailsBestRedemptionValue
      ? parse(removePTags(extraDetails?.upgpAuthorDetailsBestRedemptionValue))
      : '',
    favoriteTypeOfPoints: extraDetails?.upgpAuthorDetailsFavoriteTypePoints || '',
    // topThreeCardsInWallet: '',
    topThreeCardsInWallet: extraDetails?.upgpAuthorDetailsTopThreeCardsWallet
      ? parse(removePTags(extraDetails?.upgpAuthorDetailsTopThreeCardsWallet))
      : '',
  };
};
