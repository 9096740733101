import React, { FC } from 'react';
import { Link } from 'gatsby';
import { renderItems } from '../../utils';
import { IGuideBlocks } from './GuideBlocks.def';

const GuideBlocks: FC<IGuideBlocks> = ({
  articles,
  moreLink,
  title,
  linkClick = () => {},
  className,
}) => (
  <section className={`guideBlocks`}>
    <div className="container">
      <div className={`${title || moreLink ? 'blockHeader' : ''}`}>
        {title && (typeof title === 'string' ? <h2>{title}</h2> : title)}
        {moreLink && (
          <Link
            to={moreLink.link}
            className="btn-standard"
            onClick={() => linkClick(moreLink.link)}
          >
            {moreLink.text ? moreLink.text : 'Learn More'}
          </Link>
        )}
      </div>
      <div className={`newsContainer ${className ? className : ''}`}>
        {renderItems(articles, 'ArticleExcerpt', linkClick)}
      </div>
      {moreLink && (
        <div className="btn-wrapper">
          <Link
            to={moreLink.link}
            className="btn-standard btn-standard-mobile"
            onClick={() => linkClick(moreLink.link)}
          >
            {moreLink.text ? moreLink.text : 'Learn More'}
          </Link>
        </div>
      )}
    </div>
  </section>
);

export default GuideBlocks;
