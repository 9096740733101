import { HTMLReactParserOptions } from 'html-react-parser';

import {
  DUMMY_USER_ID,
  DUMMY_SESSION_NUMBER,
  NO_DOM,
  getFullSessionIdFromParts,
  SESSION_ID_PREFIX,
  ADD_SOURCE_SUFFIX,
} from '@throttleup/esi-components';
import { addTrailingSlash, isElement } from '..';
import {
  setValueInLS,
  getValueFromLS,
  getValueFromLSWithExpiredValue,
  isLocalStorageAllowed,
} from './localStorage';
import {
  RECORD_EXPIRED,
  NO_LOCAL_STORAGE,
  AB_TEST_GROUP_KEY,
  NEED_NEW_SESSION_VALUE,
  NEED_NEW_SESSION_KEY,
  NO_TEST_GROUP,
  INVALID_VALUE,
} from '../../constants';

interface ISourceMediumMap {
  url: string;
  source: string;
  medium: string;
}

const AD_CLICK_ID_EXPIRY_PERIOD = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
const SESSION_EXPIRY_PERIOD = 4 * 60 * 60 * 1000; //4 hr expiry in milliseconds
const USER_EXPIRY_PERIOD = 2 * 365 * 24 * 60 * 60 * 1000; // 2 year expiry in milliseconds
const GCLID_KEY = 'gclid';
const MSCLKID_KEY = 'msclkid';
const FBCLID_KEY = 'fbclid';
const TWCLID_KEY = 'twclid';
const USER_ID_KEY = 'bi_user_id';
const SESSION_NUMBER_KEY = 'bi_session_id';
const CAMPAIGN_KEY = 'bi_campaign';
const SEARCH_TERM_KEY = 'bi_search_term';
const AD_GROUP_KEY = 'bi_ad_group';
const SOURCE_KEY = 'bi_source';
const DEVICE_KEY = 'bi_device';
const AD_SET_KEY = 'bi_ad_set';
const AD_NAME_KEY = 'bi_ad_name';
const SOURCE_TYPE_PREFIX = 'ptcrs.'; //A prefix to the source type
const NUMBER_INVALID_TYPE = 666;
const MIN_AD_CLICK_ID_LENGTH = 12;
const DUMMY_AD_CLICK_ID = '777888999'; //Must be less than

const isValidClickId = (clid: string | boolean | number) => {
  return clid && typeof clid === 'string' && clid.length > MIN_AD_CLICK_ID_LENGTH;
};

//async function to send a POST request to the BigQuery clodflare worker
const sendDataToBQ = async (data = {}) => {
  // Default options are marked with *
  await fetch(`${process.env['GATSBY_SITE_URL']}/ce/`, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow', //return error if a redirect occurs
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
};

//Set the ad click ID in local storage given the ad click parameter key and the passed in url params on initial load of the client.
const setClickIdInLS = (key: string, searchParams: URLSearchParams) => {
  const clidParam = searchParams.get(key);
  setValueInLS(key, clidParam, AD_CLICK_ID_EXPIRY_PERIOD, DUMMY_AD_CLICK_ID);
};

//Set all ad click IDs in local storage given the passed in url params on initial load of the client.
export const setClickIdsInLS = (searchParams: URLSearchParams) => {
  setClickIdInLS(GCLID_KEY, searchParams);
  setClickIdInLS(MSCLKID_KEY, searchParams);
  setClickIdInLS(FBCLID_KEY, searchParams);
  setClickIdInLS(TWCLID_KEY, searchParams);
};

/**
 * Get a value from the passed in url params at the specified url param name. If it doesn't exist,
 * get it from local storage at the specified key. Otherwise the value will be set to 'none'.
 * Return an object with the following fields:
 *   value: the value to return
 *   newSession: whether or not a new session is needed because of a change to this parameter
 * */
const getSessionParam = (urlParam: string, lsKey: string, searchParams: URLSearchParams) => {
  let value: string | boolean = 'none';
  let newSession = false;
  const param = searchParams.get(urlParam);
  const lsValue = getValueFromLS(lsKey);
  if (param) {
    value = param;
    setValueInLS(lsKey, param, AD_CLICK_ID_EXPIRY_PERIOD);
    if (lsValue !== param) {
      newSession = true;
    }
    return {
      value,
      newSession,
    };
  }

  if (lsValue) value = lsValue as string;
  return {
    value,
    newSession,
  };
};

/**
 * This function is a temporary workaround for a bug where the sessionID was being set to the string 123123123 (instead of a number).
 * Note: In theory, this if clause and the similar one below it can be removed, since this fix has been
 * in place for a long time. However removing it is pending further investigation into the
 * continued presence of 123123123 sessions in the DW
 * (see https://app.hex.tech/d917b7f4-8aa9-4484-a9f2-5aa37423095e/hex/fc62608f-591e-4fe6-a50c-7411667300c5/draft/logic)
 */
const fix123session = (value: string) => {
  return value.includes('123123123') ? 123 : parseInt(value, 10);
};

/**
 * Send session and pageview events to the bi-tracking-worker based on the passed in page path and
 * the session data in Local Storage.
 * @param path - the current page path
 * @param sourceMediumMappings - the source/medium mapping definitions from the back end
 * @returns - whether or not a new userId was created
 */
export const doTrackingForBI = (path: string, sourceMediumMappings: ISourceMediumMap[]) => {
  let userId: string | boolean | number = '777888999.0123456789';
  let sessionNumber: string | boolean | number = 0;
  let userSource = getValueFromLS(SOURCE_KEY) || 'none / none';
  let userDevice = 'none';
  let newSession = false;
  let newUser = false;
  let bqData = [];
  const gclid = getValueFromLS(GCLID_KEY);
  const msclkid = getValueFromLS(MSCLKID_KEY);
  const fbclid = getValueFromLS(FBCLID_KEY);
  const twclid = getValueFromLS(TWCLID_KEY);
  const abTestGroup = getValueFromLS(AB_TEST_GROUP_KEY, NO_TEST_GROUP);
  const needNewSession = getValueFromLS(NEED_NEW_SESSION_KEY);
  let pagePath = path || 'none';
  const searchParams = new URLSearchParams(location.search);

  let retObj = getSessionParam('utm_campaign', CAMPAIGN_KEY, searchParams);
  const campaign = retObj.value;
  newSession = newSession || retObj.newSession;
  retObj = getSessionParam('utm_term', SEARCH_TERM_KEY, searchParams);
  const searchTerm = retObj.value;
  newSession = newSession || retObj.newSession;
  retObj = getSessionParam('utm_content', AD_GROUP_KEY, searchParams);
  const adGroup = retObj.value;
  newSession = newSession || retObj.newSession;
  retObj = getSessionParam('Site source', AD_SET_KEY, searchParams);
  const adSet = retObj.value;
  newSession = newSession || retObj.newSession;
  retObj = getSessionParam('Ad name', AD_NAME_KEY, searchParams);
  const adName = retObj.value;
  newSession = newSession || retObj.newSession;

  const sourceParam = searchParams.get('utm_source');
  const mediumParam = searchParams.get('utm_medium');

  //get the user device
  const oldDevice = getValueFromLS(DEVICE_KEY);
  const screenWidth = window && window.screen.width;
  if (screenWidth < 481) {
    userDevice = 'mobile';
  } else if (screenWidth < 1281) {
    userDevice = 'tablet';
  } else {
    userDevice = 'desktop';
  }
  if (oldDevice !== userDevice) {
    newSession = true;
    setValueInLS(DEVICE_KEY, userDevice, USER_EXPIRY_PERIOD);
  }
  if (needNewSession === NEED_NEW_SESSION_VALUE) {
    newSession = true;
    if (isLocalStorageAllowed()) {
      window.localStorage.removeItem(NEED_NEW_SESSION_KEY);
    }
  }

  //process userID
  userId = getValueFromLS(USER_ID_KEY);
  if (userId === false || userId === RECORD_EXPIRED) {
    userId = Date.now() + '.' + Math.trunc(Math.random() * 10000000000);
    newSession = true;
    newUser = true;
  }
  setValueInLS(USER_ID_KEY, userId as string, USER_EXPIRY_PERIOD);

  //process sessionId
  const sessionIdObj = getValueFromLSWithExpiredValue(
    SESSION_NUMBER_KEY,
    sessionNumber,
    SESSION_EXPIRY_PERIOD,
  );
  let expiredSessionValue: string | number | boolean = sessionIdObj.expiredValue;
  if (expiredSessionValue && typeof expiredSessionValue === 'string') {
    expiredSessionValue = fix123session(expiredSessionValue);
  }
  sessionNumber = sessionIdObj.value;
  if (typeof sessionNumber === 'string' && sessionNumber !== RECORD_EXPIRED) {
    sessionNumber = fix123session(sessionNumber);
  }

  //Process creation of a new session event
  if (sessionNumber === RECORD_EXPIRED || sessionNumber === 0 || newSession) {
    if (sessionNumber === RECORD_EXPIRED) {
      if (typeof expiredSessionValue === 'number') {
        sessionNumber = expiredSessionValue + 1;
      } else {
        // This should never happen
        sessionNumber = NUMBER_INVALID_TYPE;
      }
    } else {
      if (typeof sessionNumber === 'number') {
        sessionNumber += 1;
      } else {
        // This should never happen
        sessionNumber = NUMBER_INVALID_TYPE;
      }
    }

    //get the source / medium data
    let sourcePart = sourceParam || 'none';
    let mediumPart = mediumParam || 'none';
    let adSource = 'none';
    let adClickId = 'none';
    if (sourceParam === 'gglppc') {
      sourcePart = 'google';
      mediumPart = 'cpc';
    } else if (sourceParam === 'msnppc') {
      sourcePart = 'bing';
      mediumPart = 'cpc';
    } else {
      //Base the source / medium on the referrer
      const referrer = document && document.referrer;
      if (referrer === 'https://www.google.com/') {
        sourcePart = 'google';
        if (mediumPart === 'none') {
          mediumPart = 'organic';
        }
      } else if (referrer === '-' || referrer === '') {
        if (sourcePart === 'none') {
          sourcePart = 'direct';
        }
      } else if (referrer === 'https://www.bing.com/') {
        sourcePart = 'bing';
        if (mediumPart === 'none') {
          mediumPart = 'organic';
        }
      } else if (referrer) {
        //Set source / medium according to mappings in the WP Options
        const sourceData = sourceMediumMappings.find((element) => element.url === referrer);
        if (sourceData) {
          if (sourcePart === 'none') {
            sourcePart = sourceData.source;
          }
          if (mediumPart === 'none') {
            mediumPart = sourceData.medium;
          }
        } else {
          const { hostname } = new URL(referrer);
          if (sourcePart === 'none') {
            sourcePart = hostname;
          }
          if (mediumPart === 'none') {
            mediumPart = 'unknown';
          }
        }
      }
    }
    //set the ad click id and source
    if (isValidClickId(gclid) && typeof gclid === 'string') {
      adSource = 'gads';
      adClickId = gclid;
      mediumPart = 'cpc';
    } else if (isValidClickId(msclkid) && typeof msclkid === 'string') {
      adSource = 'mads';
      adClickId = msclkid;
      mediumPart = 'cpc';
    } else if (isValidClickId(fbclid) && typeof fbclid === 'string') {
      adSource = 'fb';
      adClickId = fbclid;
      mediumPart = 'cpc';
    } else if (isValidClickId(twclid) && typeof twclid === 'string') {
      adSource = 'x';
      adClickId = twclid;
      mediumPart = 'cpc';
    }
    const newSource = `${sourcePart} / ${mediumPart}`;
    if (newSource !== userSource) {
      userSource = newSource;
      setValueInLS(SOURCE_KEY, userSource, USER_EXPIRY_PERIOD);
    }
    //send session event
    const newSessionData = {
      eventType: 'session',
      id: getFullSessionIdFromParts(userId as string, sessionNumber),
      source: userSource,
      device: userDevice,
      campaign,
      adGroup,
      searchTerm,
      adSource,
      adClickId,
      abTestGroup,
      adSet,
      adName,
      rawMedium: mediumParam || 'none',
    };
    bqData.push(newSessionData);
  }
  setValueInLS(SESSION_NUMBER_KEY, sessionNumber as number, SESSION_EXPIRY_PERIOD);

  const fullSessionId = getFullSessionIdFromParts(userId as string, sessionNumber as number);
  //add pageview event to BQ data payload
  const pageviewData = {
    eventType: 'pageview',
    sessionId: fullSessionId,
    pagePath,
    isEntrance: !!newSession,
  };
  bqData.push(pageviewData);

  //Send the BQ data
  sendDataToBQ(bqData);

  return newUser;
};

const getSourceTypeString = () => {
  const gclid = getValueFromLS(GCLID_KEY);
  const msclkid = getValueFromLS(MSCLKID_KEY);
  const fbclid = getValueFromLS(FBCLID_KEY);
  const twclid = getValueFromLS(TWCLID_KEY);
  const isPaid =
    isValidClickId(gclid) ||
    isValidClickId(msclkid) ||
    isValidClickId(fbclid) ||
    isValidClickId(twclid);
  return isPaid ? 'dp' : 'pn'; //dp for paid, pn for not paid
};

//This function appends the sessionID from Local Storage to the passed in Go Link.
//If the sessionId in Local Storage is not set or is an empty string, do nothing.
//Also append the source type (paid/not paid) if the needToAddSource parameter is true.
//Return the modified link.
export const appendSessionIdToGoLinks = (goLinkUrl: string, needToAddSource = false) => {
  let userId: string | number | boolean = getValueFromLS(USER_ID_KEY);
  if (!userId || userId === NO_DOM) {
    userId = DUMMY_USER_ID; //At build time, generate a full dummy userId
  }
  let sessionNumber: string | boolean | number = getValueFromLS(SESSION_NUMBER_KEY);
  if (!sessionNumber || sessionNumber === NO_DOM) {
    sessionNumber = DUMMY_SESSION_NUMBER; //At build time, set the session ID to the dummy one
  }
  const fullSessionId = getFullSessionIdFromParts(userId as string, sessionNumber as string);
  const newSessionIdSubStr = SESSION_ID_PREFIX + fullSessionId + '/';
  let newUrl = goLinkUrl;
  newUrl = addTrailingSlash(newUrl);
  newUrl += newSessionIdSubStr;
  if (needToAddSource) {
    const cardNamePos = newUrl.includes('https://') ? 4 : 2;
    const urlParts = newUrl.split('/');
    urlParts[cardNamePos] = urlParts[cardNamePos] + ADD_SOURCE_SUFFIX;
    newUrl = urlParts.join('/');
    const sourceTypeString = getSourceTypeString();
    newUrl += SOURCE_TYPE_PREFIX + sourceTypeString + '/';
  }
  return newUrl;
};

const getFullSessionIdFromLS = () => {
  const userId = getValueFromLS(USER_ID_KEY) || DUMMY_USER_ID;
  if (document !== undefined && userId && userId !== NO_LOCAL_STORAGE) {
    const sessionNumberFromLS = getValueFromLS(SESSION_NUMBER_KEY);
    if (sessionNumberFromLS) {
      if (typeof sessionNumberFromLS === 'number') {
        return getFullSessionIdFromParts(userId as string, sessionNumberFromLS);
      } else {
        return getFullSessionIdFromParts(userId as string, NUMBER_INVALID_TYPE);
      }
    }
  }
  return getFullSessionIdFromParts(userId as string, DUMMY_SESSION_NUMBER);
};

//This function replaces the dummy session ID in the passed-in go link with the one in local storage.
//It also adds update the source_type data if necessary.
const updateSessionDataInGoLink = (
  goLink: string,
  fullSessionId: string,
  sourceTypeString: string,
) => {
  let newUrl = goLink; // Get current url
  const dummyFullSessionId = getFullSessionIdFromParts(DUMMY_USER_ID, DUMMY_SESSION_NUMBER);
  if (newUrl.includes(dummyFullSessionId)) {
    newUrl = newUrl.replace(dummyFullSessionId, fullSessionId);
  }
  if (newUrl.includes(SOURCE_TYPE_PREFIX)) {
    const sourceTypeIndex = newUrl.indexOf(SOURCE_TYPE_PREFIX);
    newUrl = newUrl.slice(0, sourceTypeIndex) + SOURCE_TYPE_PREFIX + sourceTypeString + '/';
  }
  if (newUrl.includes(ADD_SOURCE_SUFFIX) && !newUrl.includes(SOURCE_TYPE_PREFIX)) {
    newUrl = addTrailingSlash(newUrl);
    newUrl += SOURCE_TYPE_PREFIX + sourceTypeString + '/';
  }
  return newUrl;
};

// This function calls updateSessionDataInGoLink() for all Go Links on this page.
export const updateSessionDataInGoLinks = () => {
  const fullSessionId = getFullSessionIdFromLS();
  const sourceTypeString = getSourceTypeString();
  if (document !== undefined) {
    let aTags = document.getElementsByTagName('a');
    if (aTags.length && aTags.length > 0) {
      for (const element of aTags) {
        element.href = updateSessionDataInGoLink(element.href, fullSessionId, sourceTypeString);
      }
    }
  }
};

// This function returns the options object used in a call to the html-react-parser parse() function
// when used to update the session data in the go links
export const getUpdateSessionDataInGoLinksOptions = (): HTMLReactParserOptions => {
  const fullSessionId = getFullSessionIdFromLS();
  const sourceTypeString = getSourceTypeString();
  return {
    replace: (domNode) => {
      if (isElement(domNode)) {
        if (domNode?.name === 'a') {
          if (domNode?.attribs['href']?.includes('/go/')) {
            domNode.attribs['href'] = updateSessionDataInGoLink(
              domNode.attribs['href'],
              fullSessionId,
              sourceTypeString,
            );
            return domNode;
          }
        }
      }
      return domNode;
    },
  };
};

/**
 * Get the bi_user_id from local storage
 * @returns [string] the userId if found in local storage, or INVALID_VALUE
 */
export const getUserIdFromLS = () => {
  const userId = getValueFromLS(USER_ID_KEY);
  if (typeof userId === 'string') {
    return userId;
  }
  return INVALID_VALUE;
};

/**
 * Get the bi_session_id from local storage (the user's session number)
 * @returns [number] the session number if found in local storage, or 0 if not
 */
export const getSessionNumberFromLS = () => {
  const sessionNumber = getValueFromLS(SESSION_NUMBER_KEY);
  if (typeof sessionNumber === 'number') {
    return sessionNumber;
  }
  return 0;
};
