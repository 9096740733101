import { graphql, useStaticQuery } from 'gatsby';

const getSourceMediumMappings = () => {
  const data = useStaticQuery(graphql`
    query GetSourceMediumMappings {
      wp {
        upOptionsRedesign {
          trackingOptions {
            upgpTrackingOptionsSourceMediumMappings {
              upgpTrackingOptionsSourceMediumMappingsMedium
              upgpTrackingOptionsSourceMediumMappingsSource
              upgpTrackingOptionsSourceMediumMappingsUrl
            }
          }
        }
      }
    }
  `);

  return (
    data?.wp?.upOptionsRedesign?.trackingOptions?.upgpTrackingOptionsSourceMediumMappings.map(
      (element) => ({
        url: element.upgpTrackingOptionsSourceMediumMappingsUrl,
        source: element.upgpTrackingOptionsSourceMediumMappingsSource,
        medium: element.upgpTrackingOptionsSourceMediumMappingsMedium,
      })
    ) || []
  );
};

export default getSourceMediumMappings;
