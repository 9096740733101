import React, { FC } from 'react';
import HTMLContentESIWrapper from '../../wrappers/HTMLContentESIWrapper';
import {
  ICardApiShortcode,
  ICardData,
} from '@throttleup/esi-components/lib/models/ICardData/index.def';

interface IShortcodeESI {
  src: string;
  goLinkPlacing: string;
}

const ShortcodeESI: FC<IShortcodeESI> = ({ src, goLinkPlacing = '/TBL/' }) => {
  const { searchParams } = new URL(`https://example.com${src}`);
  const cardId = parseInt(searchParams.get('card_id') || '0');
  const name = searchParams.get('name');
  const containerId = `shortcode_${cardId}_${name}`;
  const renderFunction = (card: ICardData) => {
    const shortcodes = card?.shortcodes;
    if (typeof shortcodes?.length === 'undefined') {
      return '';
    }
    const shortcode = shortcodes.find((shortcode: ICardApiShortcode) => shortcode.name === name);
    return shortcode?.renderedContent || '';
  };

  return (
    <HTMLContentESIWrapper
      cardId={cardId}
      containerId={containerId}
      src={src}
      fallbackRenderFunction={renderFunction}
      extraParams={[]}
      goLinkPlacing={goLinkPlacing}
      tagName="span"
    />
  );
};

export default ShortcodeESI;
