import { TTemplate } from '../../models/TTemplate';
import { isTableFit } from './isTableFit';
import { isTableFitLg } from './isTableFitLg';

/**
 * Adjusts the table class name based on the template name and table width.
 *
 * @param {TTemplate} templateName - The name of the template.
 * @param {number} tableWidth - The width of the table.
 * @param {string} tableClassName - The current class name of the table.
 * @returns {string} - The modified table class name.
 */

export const handleScrollableTable = (
  templateName: TTemplate,
  tableWidth: number,
  tableClassName: string,
) => {
  if (isTableFitLg(templateName, tableWidth)) {
    tableClassName = `${tableClassName} table-fit-lg`;
  } else if (isTableFit(templateName, tableWidth)) {
    tableClassName = `${tableClassName} table-fit`;
  }

  return tableClassName;
};
