import React, { useState, useEffect } from 'react';
import { HtEventsBrowser } from '@ht-sdks/events-sdk-js-browser';
import HTEventsContext from '../context/HTEventsContext';
import { HTEventsContextType } from '../context/HTEventsContext/HTEventsContext.def';
import { HT_SESSION_TIMEOUT } from '../constants';
import { getSiteDomainWithoutProtocol } from '../utils/helpers/replaceDomain';
import { HT_DOMAIN_PROXY_PATH } from '../constants';

interface ChildProps {
  children: JSX.Element;
}

const HTEventsProvider = ({ children }: ChildProps) => {
  const [htevents, setHtEvents] = useState<HTEventsContextType>(null);

  useEffect(() => {
    // Assume that the Hightouch domain is being reverse-proxied at the path defined by HT_DOMAIN_PROXY_PATH
    const instance: HtEventsBrowser = HtEventsBrowser.load(
      { writeKey: process.env['HTEVENTS_WRITE_KEY'] || '' },
      {
        apiHost: getSiteDomainWithoutProtocol() + '/' + HT_DOMAIN_PROXY_PATH,
        user: {
          sessions: {
            autoTrack: true,
            timeout: HT_SESSION_TIMEOUT, //The session timeout
          },
        },
        httpCookieServiceOptions: {
          clearUrl: '/sr/clear/',
          renewUrl: '/sr/renew/',
        },
        batching: false,
      },
    );
    setHtEvents(instance);
  }, []);

  return <HTEventsContext.Provider value={htevents}>{children}</HTEventsContext.Provider>;
};

export default HTEventsProvider;
