import React, { FC, useContext, useState, useRef, useEffect } from 'react';
import Pulse from '../../components/Pulse';
import CloseModalIcon from '../../svgs/CloseModelIcon';
import EmailOptin from '../../components/EmailOptin';
import HTEventsContext from '../../context/HTEventsContext';
import { INewsletterSignupContainer } from './NewsletterSignuupContainer.def';
import { HT_TRACK_EVENTS } from '../../constants';
import { sendHtTrackEvent } from '../../utils/helpers/htApi';

const NewsletterSignupContainer: FC<INewsletterSignupContainer> = ({
  isPopup = false,
  customOptin,
}) => {
  const sectionItem = useRef<HTMLElement | null>(null);
  const [visible, setVisible] = useState(true);
  const htevents = useContext(HTEventsContext);
  let timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const sectionItemElement = sectionItem.current as HTMLElement;
    let observer: IntersectionObserver | null = null;

    if (htevents && sectionItemElement) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry?.isIntersecting) {
            const { left } = sectionItemElement.getBoundingClientRect();
            const xPosPx = Math.round(window.scrollX + left);
            const yPosPx = Math.round(window.scrollY);
            const documentHeight = document.documentElement.scrollHeight;
            const documentWidth = document.documentElement.scrollWidth;
            const xPosPct = documentWidth > 0 ? Math.round((xPosPx / documentWidth) * 100) : 0;
            const yPosPct = documentHeight > 0 ? Math.round((yPosPx / documentHeight) * 100) : 0;
            // Element is visible, start the timeout
            timeoutId.current = setTimeout(() => {
              // Send impression event when the dropdown becomes visible
              sendHtTrackEvent(htevents, HT_TRACK_EVENTS.IMPRESSION, {
                element: 'Newsletter Signup',
                xPosPx,
                yPosPx,
                xPosPct,
                yPosPct,
                placing: isPopup ? 'popup' : 'in content',
                variant: customOptin ? customOptin.title : 'Pulse',
              });
              observer?.unobserve(sectionItemElement);
            }, 1000); // 1s second delay
          } else {
            // Element is no longer visible, clear the timeout
            if (timeoutId.current) clearTimeout(timeoutId.current);
          }
        },
        { threshold: 0.5 }, // Trigger the callback as soon as 50% part of the target is visible.
      );

      observer.observe(sectionItemElement);
    }

    // Cleanup observer on component unmount
    return () => {
      if (sectionItemElement && observer) {
        observer.unobserve(sectionItemElement);
        if (timeoutId.current) clearTimeout(timeoutId.current); // Clear any running timeouts
      }
    };
  }, [htevents]);

  return (
    visible && (
      <section
        className={`${isPopup ? 'pulsePopup upgp-email-optin' : 'upgp-email-optin'}`}
        ref={sectionItem}
      >
        {isPopup && <CloseModalIcon className="closePopup" onClick={() => setVisible(!visible)} />}
        {customOptin ? (
          <EmailOptin isPopup={isPopup} emailOptin={customOptin} />
        ) : (
          <Pulse isPopup={isPopup} />
        )}
      </section>
    )
  );
};

export default NewsletterSignupContainer;
