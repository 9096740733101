import { FULL_WIDTH_SIDEBAR_MAX_WIDTH } from '../../constants';
import { TTemplate } from '../../models/TTemplate';

/**
 * Handles adjustments for non-scrollable tables based on template name and table width.
 *
 * @param {TTemplate} templateName - The name of the page/post template.
 * @param {number} tableWidth - The width of the table in pixels.
 * @returns {Object|undefined} An object containing the original and target table widths if the conditions are met, otherwise undefined.
 * @returns {number} return.originalTableWidth - The original width of the table in pixels.
 * @returns {number} return.targetTableWidth - The target width of the table in pixels, specifically for the 'Full Width Hero Image + Sidebar' template.
 */

export const handleNonScrollableTable = (templateName: TTemplate, tableWidth: number) => {
  if (
    templateName === 'Full Width Hero Image + Sidebar' &&
    tableWidth !== FULL_WIDTH_SIDEBAR_MAX_WIDTH
  ) {
    return {
      originalTableWidth: tableWidth,
      targetTableWidth: FULL_WIDTH_SIDEBAR_MAX_WIDTH,
      shouldUpdateWidth: true,
    };
  }
  return;
};
