import { graphql, useStaticQuery } from 'gatsby';

import { IWpPost } from '../../models/IWpPost';

const getLatestNewsArticles = () => {
  const articleData = useStaticQuery(graphql`{
  allWpPost(
    limit: 7
    filter: {categories: {nodes: {elemMatch: {name: {eq: "News"}}}}}
    sort: {date: DESC}
  ) {
    nodes {
      databaseId
      uri
      dateGmt
      excerpt
      title
      featuredImage {
        node {
          sourceUrl
          altText
        }
      }
      author {
        node {
          uri
          name
        }
      }
    }
  }
}`);
  const articleNodes = articleData?.allWpPost?.nodes;
  const articles =
    articleNodes &&
    articleNodes.length &&
    articleNodes.map((node: IWpPost) => ({
      databaseId: node.databaseId,
      date: node.dateGmt,
      excerpt: node.excerpt,
      link: node.uri,
      thumbnail: {
        src: node.featuredImage.node.sourceUrl,
        alt: node.featuredImage.node.altText,
      },
      title: node.title,
      author: {
        link: node.author?.node?.uri || '#',
        text: node.author?.node?.name || '',
      },
    }));

  return articles || [];
};

export default getLatestNewsArticles;
