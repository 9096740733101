import React, { FC } from 'react';
import ImageOverlay from './ImageOverlay';
import { Link } from 'gatsby';
import { IArticleExcerpt } from '../ContentTypes/Article/Article.def';
import ArrowRightIcon from '../../svgs/ArrowRightIcon';

const FeaturedArticle: FC<IArticleExcerpt> = ({
  thumbnail,
  category,
  title,
  link,
  linkClick = () => {},
}) => (
  <ImageOverlay thumbnail={thumbnail} link={link}>
    {category && (
      <p>
        <Link to={category.link} onClick={() => linkClick(category.link)}>
          {category.text}
        </Link>
      </p>
    )}
    <h3>
      <Link to={link} onClick={() => linkClick(link)}>
        {title}
        <ArrowRightIcon />
      </Link>
    </h3>
  </ImageOverlay>
);

export default FeaturedArticle;
