import React from 'react';
import ExploreTopContent from '../components/ExploreTopContent/ExploreTopContent';

interface IGExploreTopContentCategory {
  upgp_explore_top_content_category_title?: string | undefined;
  upgp_explore_top_content_category_link?: string | undefined;
  upgp_explore_top_content_category_links?: IGExploreTopContentInnerLink[] | undefined;
}
interface IGExploreTopContentInnerLink {
  upgp_explore_top_content_category_links_title?: string | undefined;
  upgp_explore_top_content_category_links_link?: string | undefined;
}

interface IExploreTopContentContainer {
  upgp_explore_top_content?: string | undefined;
  upgp_explore_top_content_categories?: IGExploreTopContentCategory[] | undefined;
}

const ExploreTopContentContainer = (props: IExploreTopContentContainer) => {
  const { upgp_explore_top_content: title, upgp_explore_top_content_categories: categoriesData } =
    props;

  let categoriesFromDB: IGExploreTopContentCategory[] = []
  if (categoriesData && Array.isArray(categoriesData)) {
    categoriesFromDB = categoriesData
  } 

  const categories = categoriesFromDB.map((category: IGExploreTopContentCategory) => ({
    title: category.upgp_explore_top_content_category_title,
    link: category.upgp_explore_top_content_category_link,
    links: category.upgp_explore_top_content_category_links,
  }));

  if (!title) {
    return null
  }

  return (
    <>
      <ExploreTopContent title={title} categories={categories} />
    </>
  );
};

export default ExploreTopContentContainer;
