import React, { FC } from 'react';

import { renderCardTableContainer } from '@throttleup/esi-components';
import HTMLContentESIWrapper from '../../wrappers/HTMLContentESIWrapper';
import { getSiteDomainWithoutProtocol } from '../../utils/helpers/replaceDomain';

interface ICardTableESI {
  src: string;
}

const CardTableESI: FC<ICardTableESI> = ({ src }) => {
  const { searchParams } = new URL(`https://example.com${src}`);
  const cardId = parseInt(searchParams.get('card_id') || '0');
  const parentId = parseInt(searchParams.get('parent_id') || '0');
  const isPpc = searchParams.get('is_ppc') === 'true';
  const isMilitary = searchParams.get('is_military') === 'true';
  const disableButton = searchParams.get('disable_button') === 'true';
  const overrideAffiliate = searchParams.get('override_affiliate') === 'true';
  const redBadge = searchParams.get('red_badge');
  const orangeBadge = searchParams.get('orange_badge');
  const containerId = `card_table_${cardId}_${parentId}`;
  const variant = searchParams.get('variant') || undefined;
  const extraParams = [
    parentId,
    isPpc,
    isMilitary,
    disableButton,
    overrideAffiliate,
    redBadge,
    orangeBadge,
    getSiteDomainWithoutProtocol(),
    variant,
  ];

  return (
    <HTMLContentESIWrapper
      cardId={cardId}
      containerId={containerId}
      src={src}
      fallbackRenderFunction={renderCardTableContainer}
      extraParams={extraParams}
      isCardTable={true}
      tagName='section'
    />
  );
};

export default CardTableESI;
