import { createContext, useContext } from 'react';
import { IModalContextType } from '../context/IModalContext.def';

const ModalContext = createContext<IModalContextType>({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const useModal = () => useContext(ModalContext);

export default ModalContext;