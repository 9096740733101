import React from 'react';
import HorizontalBrowser from '../components/HorizontalBrowser';
import { getFeaturedCreditCards, getTopCreditCards } from '../utils/static-queries';
import { ICardExcerptESI } from '../components/ContentTypes/CardExcerptESI/CardExcerptESI.def';

const TOP_CREDIT_CARD_VARIANT = 'Top Credit Cards';
const FEATURED_CARDS_VARIANT = 'Featured Cards';

const HorizontalBrowserContainer = (props: any) => {
  const {
    up_gb_horizontal_browser_title: title,
    up_gb_horizontal_browser_subtitle: subtitle,
    up_gb_horizontal_browser_see_all_link: seeAllData,
    up_gb_horizontal_browser_scrollable: scrollable,
    up_gb_horizontal_browser_variant: variant,
  } = props;
  const seeAll = {
    text: seeAllData?.up_gb_horizontal_browser_see_all_text,
    link: seeAllData?.up_gb_horizontal_browser_see_all_link,
  };
  let wpMultiCreditCard: Queries.WpMultiCreditCard | undefined = undefined;
  switch (variant) {
    case TOP_CREDIT_CARD_VARIANT:
      wpMultiCreditCard = getTopCreditCards();
      break;
    case FEATURED_CARDS_VARIANT:
      wpMultiCreditCard = getFeaturedCreditCards();
      break;
  }

  const cards: ICardExcerptESI[] = [];

  if (wpMultiCreditCard) {
    wpMultiCreditCard.fieldsForMultiTables?.tables
      ?.filter((table) => table?.cardReview?.databaseId)
      .forEach((table) => {
        const cardReview = table?.cardReview;
        if (cardReview) {
          cards.push({
            cardId: cardReview.databaseId,
          });
        }
        return null;
      });
  }

  const renderHorizontalBrowser = () => (
    <HorizontalBrowser
      title={title}
      subtitle={subtitle}
      seeAllLink={seeAll}
      cards={cards}
      notScrollable={!scrollable}
    />
  );
  switch (variant) {
    case TOP_CREDIT_CARD_VARIANT:
      return renderHorizontalBrowser();
    case FEATURED_CARDS_VARIANT:
      return <section className="featuredCards">{renderHorizontalBrowser()}</section>;
    default:
      return null;
  }
};

export default HorizontalBrowserContainer;
