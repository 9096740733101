import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import TravelHub from '../components/TravelHub';
import { IWpPost } from '../models/IWpPost';
import { IWpCategory } from '../models/IWpCategory';
import { ITravelHubTab } from '../components/TravelHub/TravelHub.def';
import { getPlaceholderWpGatsbyImg } from '../utils';
import FullFeatured from '../components/FullFeatured';
import { IArticleExcerpt } from '../components/ContentTypes/Article/Article.def';

const TravelHubContainer = (props: any) => {
  const {
    upgp_gb_travel_hub_title: title,
    upgp_gb_travel_hub_aside_title: asideTitle,
    upgp_gb_travel_hub_subtitle: subtitle,
    upgp_gb_travel_hub_more_link: moreLinkData,
    upgp_gb_travel_hub_variant: variant,
  } = props;
  const moreLink = {
    text: moreLinkData?.upgp_gb_travel_hub_more_link_group_text,
    link: moreLinkData?.upgp_gb_travel_hub_more_link_group_link,
  };
  let linksForHubVariant: ITravelHubTab[] = [];
  let articlesForSingleVariant: IArticleExcerpt[] = [];
  const linkData = useStaticQuery(graphql`{
  allTravelChildCategories: allWpCategory(
    filter: {ancestors: {nodes: {elemMatch: {name: {eq: "Travel"}}}}}
  ) {
    nodes {
      name
      uri
      posts {
        nodes {
          featuredImageThumb: featuredImage {
            node {
              sourceUrl
              altText
            }
          }
          featuredImage: featuredImage {
            node {
              sourceUrl
              altText
            }
          }
          title
          uri
        }
      }
    }
  }
  travelCategory: wpCategory(name: {eq: "Travel"}) {
    link
  }
  latestTravelPost: allWpPost(
    limit: 1
    filter: {categories: {nodes: {elemMatch: {name: {eq: "Travel"}}}}}
    sort: {date: DESC}
  ) {
    nodes {
      uri
      title
      featuredImage {
        node {
          sourceUrl
          altText
        }
      }
    }
  }
}`);
  const { allTravelChildCategories, travelCategory, latestTravelPost } = linkData;
  const latestTravelPostNode = latestTravelPost?.nodes[0];
  if (latestTravelPostNode === undefined) return null;
  const travelFeaturedImage = latestTravelPostNode?.featuredImage?.node || getPlaceholderWpGatsbyImg();

  const latestTravelPostArticleExcerpt: IArticleExcerpt = {
    thumbnail: {
      src: travelFeaturedImage.sourceUrl,
      alt: travelFeaturedImage.altText,
    },
    title: latestTravelPostNode.title,
    link: latestTravelPostNode.uri,
    category: { text: 'Travel Guide', link: travelCategory.link },
  };
  const categoryNodes = allTravelChildCategories?.nodes
    .filter((cat: IWpCategory) => !['Cruises', 'Hiking & Camping'].includes(cat.name))
    .sort();
  if (variant === 'Hub') {
    linksForHubVariant = [
      {
        text: 'All Guides',
        articles: [latestTravelPostArticleExcerpt],
      },
    ];
    categoryNodes &&
      categoryNodes.length &&
      categoryNodes.forEach((element: IWpCategory, index: number) => {
        const posts = element?.posts?.nodes;
        if (posts && posts[0]) {
          const firstPost = posts[0];
          const featuredImageNode = firstPost?.featuredImage?.node || getPlaceholderWpGatsbyImg();
          const featuredImageNodeThumb =
            firstPost?.featuredImageThumb?.node || getPlaceholderWpGatsbyImg();
          if (index < 4) {
            linksForHubVariant[0]?.articles.push({
              thumbnail: {
                src: featuredImageNodeThumb.sourceUrl,
                alt: featuredImageNodeThumb.altText,
              },
              title: firstPost.title,
              link: firstPost.uri,
              category: { text: element.name, link: element.uri },
            });
          }
          const categoryLink: ITravelHubTab = {
            text: element.name,
            articles: [
              {
                thumbnail: {
                  alt: featuredImageNode.altText,
                  src: featuredImageNode.sourceUrl,
                },
                title: firstPost.title,
                link: firstPost.uri,
                category: { text: element.name, link: element.uri },
              },
            ],
          };
          posts.slice(1, 5).forEach((post: IWpPost) => {
            const postFeaturedImageNodeThumb =
              post?.featuredImageThumb?.node || getPlaceholderWpGatsbyImg();
            categoryLink.articles.push({
              thumbnail: {
                alt: postFeaturedImageNodeThumb.altText,
                src: postFeaturedImageNodeThumb.sourceUrl,
              },
              title: post.title,
              link: post.uri,
              category: { text: element.name, link: element.uri },
            });
          });
          linksForHubVariant.push(categoryLink);
        }
      });
  } else {
    categoryNodes &&
      categoryNodes.length &&
      categoryNodes.slice(0, 4).forEach((element: IWpCategory) => {
        const posts = element?.posts?.nodes;
        if (posts && posts[0]) {
          const firstPost = posts[0];
          const featuredImageNodeThumb =
            firstPost?.featuredImageThumb?.node || getPlaceholderWpGatsbyImg();
          articlesForSingleVariant.push({
            thumbnail: {
              alt: featuredImageNodeThumb.altText,
              src: featuredImageNodeThumb.sourceUrl,
            },
            title: firstPost.title,
            link: firstPost.uri,
            category: { text: element.name, link: element.uri },
          });
        }
      });
  }
  return variant === 'Hub' ? (
    <TravelHub
      title={title}
      asideTitle={asideTitle}
      subtitle={subtitle}
      moreLink={moreLink}
      links={linksForHubVariant}
    />
  ) : (
    <FullFeatured
      featuredArticle={latestTravelPostArticleExcerpt}
      articles={articlesForSingleVariant}
      asideTitle={asideTitle}
    />
  );
};

export default TravelHubContainer;
