import noAnnualFeeIcon from '../images/category-NoAnnualFee.svg';
import advertisementIcon from '../images/category-advertisement.svg';
import airlineIcon from '../images/category-airline.svg';
import businessIcon from '../images/category-business.svg';
import cashbackIcon from '../images/category-cashback.svg';
import creditCardsIcon from '../images/category-creditcards.svg';
import cruisesIcon from '../images/category-cruises.svg';
import hotelIcon from '../images/category-hotel.svg';
import newsIcon from '../images/category-news.svg';
import shippingIcon from '../images/category-shipping.svg';
import travelIcon from '../images/category-travel.svg';
import aprIcon from '../images/category-apr.svg';
import airportIcon from '../images/category-airport.svg';
import carIcon from '../images/category-car.svg';
import militaryIcon from '../images/category-military.svg';

const NAV_ICONS: Record<string, string> = {
  'cat-credit-cards': creditCardsIcon,
  'cat-business': businessIcon,
  'cat-travel': travelIcon,
  'cat-advertisement': advertisementIcon,
  'cat-shipping': shippingIcon,
  'cat-airlines': airlineIcon,
  'cat-hotels': hotelIcon,
  'cat-news': newsIcon,
  'cat-cruises': cruisesIcon,
  'cat-CashBack': cashbackIcon,
  'cat-NoAnnualFee': noAnnualFeeIcon,
  'cat-apr': aprIcon,
  'cat-airport': airportIcon,
  'cat-military': militaryIcon,
  'cat-car': carIcon,
};

export const getNavIcon = (className: string) => {
  return NAV_ICONS[className];
};
