import React, { FC } from 'react';
import { Link } from 'gatsby';
import { IAsideText } from './FullFeatured.def';

const AsideText: FC<IAsideText> = ({ title, text, moreLink, linkClick = () => {} }) => (
  <div className="aside">
    <h2>{title}</h2>
    <p>{text}</p>
    <Link to={moreLink.link} className="btn-standard" onClick={() => linkClick(moreLink.link)}>
      {moreLink.text}
    </Link>
  </div>
);

export default AsideText;
