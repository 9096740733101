import { navigate } from 'gatsby';
import React, { FC, useState } from 'react';
import SearchIcon from '../../../svgs/SearchIcon';
import { ISearchInput } from './SearchInput.def';

const SearchInput: FC<ISearchInput> = ({
  placeholder = 'Search Upgraded Points...',
  searchText,
  setSearchText,
  handleToggleButtonSearch,
  autoFocus = false,
  executeSearch,
  disabled = false,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const handleSearch = () => {
    setErrorMessage('');
    if (!searchText) {
      setErrorMessage('Please enter a search term.');
    } else if (searchText.length < 3) {
      setErrorMessage('A search term needs to have 3 characters or more.');
    } else {
      navigateToSearch();
      handleToggleButtonSearch && handleToggleButtonSearch();
    }
  };

  const navigateToSearch = () => {
    navigate(`/search/${searchText}`);
  };
  return (
    <div className="inputWrapper">
      <div className="inputContainer">
        <input
          type="text"
          value={searchText}
          placeholder={placeholder}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          disabled={disabled}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
            if (!/search/.test(location.pathname) && searchText && searchText.length > 2) {
              executeSearch();
            }
          }}
          autoFocus={autoFocus}
          id="search-input"
        />
        <button className="searchButton" onClick={handleSearch}>
          <SearchIcon fill="#fff" />
        </button>
      </div>
      {errorMessage && <span className="searchErrorMessage">{errorMessage}</span>}
    </div>
  );
};

export default SearchInput;
