import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'gatsby';
import SignUpForm from '../SignUpForm';
import { DEFAULT_SUBSCRIPTION_LIST } from '../../constants';
import { EmailOptinProps } from './EmailOptin.def';
import { replaceDomainWithSiteDomain } from '../../utils/helpers';

const EmailOptin: React.FC<EmailOptinProps> = ({ emailOptin, isPopup }) => {
  const buttonLabel = emailOptin?.submitButtonText || 'Join now';
  const imageOptinSource: string = replaceDomainWithSiteDomain(emailOptin?.image?.sourceUrl || '') || '';
  return (
    <div className={`optin-container style-${emailOptin?.optinStyle}`}>
      <div className="optin-content">
        <div className="optin-header">
          <div className="optin-title">{emailOptin?.title}</div>
          <div className="optin-description">{parse(emailOptin?.description || '')}</div>
        </div>
        <div className="optin-form">
          <SignUpForm
            isPopup={isPopup}
            buttonLabel={buttonLabel}
            listId={emailOptin?.listid || DEFAULT_SUBSCRIPTION_LIST}
            redirectUrl={emailOptin?.redirectAfterSubmit}
            newsletterTitle={emailOptin?.title || ''}
          />
        </div>
        <div className="optin-footer">
          <p>
            We respect your <Link to="/privacy-policy/">privacy</Link>. This site is protected by
            reCAPTCHA. Google's&nbsp;
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
              privacy policy
            </a>
            &nbsp;and&nbsp;
            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
              terms of service
            </a>
            &nbsp;apply.
          </p>
        </div>
      </div>
      <div className="optin-image">
        <img
          src={imageOptinSource}
          alt={emailOptin?.image?.altText}
          className="optin-cover"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default EmailOptin;
