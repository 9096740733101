import React, { FC } from 'react';

import { ICardExcerptESI } from './CardExcerptESI.def';
import { renderCardExcerpt } from '@throttleup/esi-components';
import ESIWrapper from '../../../wrappers/ESIWrapper';

const CardExcerptESI: FC<ICardExcerptESI> = ({ cardId, containerClassName, variant }) => {
  let containerId = `card_excerpt_esi_${cardId}`;
  //Add the passed-in container class name to prevend duplicate IDs when this component is shown multiple times per cardId on one page
  if (containerClassName) containerId += '_' + containerClassName?.toLowerCase();
  const extraParams = [variant];
  //Dummy data to load if the card data JSON is not yet loaded
  const dummyCardData = {
    upgpImage: { value: null, description: '' },
    upgpCardName: { value: 'Loading...', description: '' },
    cardReviewUrl: '#',
    welcomeOfferAmountCardTables: 'Loading points',
    tagline: 'Loading...',
    upRating: '5',
    cardId: cardId,
  };
  let esiSrc = `/esi/components/?component=card_excerpt&card_id=${cardId}`;
  if (variant) esiSrc += `&variant=${variant}`;

  return (
    <ESIWrapper
      cardId={cardId}
      containerId={containerId}
      className="cardBlock"
      esiSrc={esiSrc}
      fallbackRenderFunction={renderCardExcerpt}
      dummyCardData={dummyCardData}
      extraParams={extraParams}
    />
  );
};

export default CardExcerptESI;
