import { graphql, useStaticQuery } from 'gatsby';

const getTopCreditCardContentFromWp = () => {
  const { wpUpOption } = useStaticQuery(graphql`
    query {
      wpUpOption(slug: { eq: "top-credit-card-content-cc-reviews" }) {
        creditCardReviewsTopContent {
          upgpCcReviewsTopContentVariants {
            upgpCcReviewsTopContentTitle
            upgpCcReviewsTopContentType
            upgpCcReviewsTopContentColumns {
              upgpCcReviewsTopContentColumnBottomLink
              upgpCcReviewsTopContentColumnTitle
              upgpCcReviewsTopContentColumnLinks {
                upgpCcReviewsTopContentColumnLinksText
                upgpCcReviewsTopContentColumnLinksUrl
              }
              upgpCcReviewsTopContentColumnBottomText
            }
          }
        }
      }
    }
  `);

  return wpUpOption as Queries.WpUpOption;
};

export default getTopCreditCardContentFromWp;
