import { graphql, useStaticQuery } from 'gatsby';

const getUsers = () => {
  const userData = useStaticQuery(graphql`
    query {
      allWpUser {
        nodes {
          name
          description
          databaseId
          slug
          userBios {
            upgpUserRichBio
            upgpUserShortBio
          }
          avatar {
            url
          }
          userSocials {
            upgpUserEmail
            upgpUserExpertise {
              name
              slug
            }
            upgpUserSocialLinks {
              upgpUserSocialLinksName
              upgpUserSocialLinksUrl
            }
          }
          upgpAuthorDetails {
            upgpCountriesVisited
            upgpUsStatesVisited
            upgpAuthorDetailsHighestHotelStatus
            upgpAuthorDetailsHighestAirlineStatus
            upgpAuthorDetailsFavoriteAirlineAlliance
            upgpAuthorDetailsFavoriteFirstClass
            upgpAuthorDetailsFavoriteBusinessClass
            upgpAuthorDetailsBestRedemptionValue
            upgpAuthorDetailsFavoriteTypePoints
            upgpAuthorDetailsTopThreeCardsWallet
            upgpAuthorCountEditedPosts
          }
        }
      }
    }
  `);
  return userData.allWpUser.nodes;
};

export default getUsers;
