import React, { FC, useEffect, useRef, useState } from 'react';
import { IHeaderWrapper } from './HeaderWrapper.def';
import Header from '../../components/Header';
import { STICKY_NAV_SCROLL_UP_OFFSET } from '../../constants';

const HeaderWrapper: FC<IHeaderWrapper> = ({ isTabletOrMobile, ...rest }) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState<number | null>(null);

  useEffect(() => {
    // Function to update the header height dynamically.
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    // Call the function once on mount.
    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    // Variable to track the last known scroll position.
    let lastScrollTop = 0;

    // Function to handle the scroll behavior.
    const handleScroll = () => {
      // Check if the device is a tablet or mobile.
      if (!isTabletOrMobile) return;

      // Prevent flickering when overscrolling at the bottom of the screen.
      if (window.scrollY + window.innerHeight >= document.body.offsetHeight) {
        headerRef.current?.classList.remove('sticky-nav');
        document.body.style.paddingTop = '';
        return;
      }

      // Get the current vertical scroll position.
      const scrollTop = document.documentElement.scrollTop;
      // Locate the search wrapper element inside the header, if it exists.
      const searchWrapperElement = headerRef.current?.querySelector('.search-wrapper');

      // If the search wrapper element is not found, allow toggling the sticky navigation bar
      // This check is necessary because when the search is open, scrolling would otherwise cause
      // the entire header to disappear. We prevent the sticky behavior when the search is active.
      if (!searchWrapperElement) {
        // If the user scrolls down or is at the top of the page (scrollY === 0)
        if (scrollTop > lastScrollTop || window.scrollY === 0) {
          // Remove the 'sticky-nav' class from the header to stop it from sticking
          headerRef.current?.classList.remove('sticky-nav');
          // Remove the header placeholder by resetting the body padding-top
          document.body.style.paddingTop = '';
        } else if (lastScrollTop - scrollTop > STICKY_NAV_SCROLL_UP_OFFSET) {
          // If the user scrolls up more than the offset, add the sticky-nav class.
          headerRef.current?.classList.add('sticky-nav');
          // Apply the header height as padding-top to the body.
          document.body.style.paddingTop = `${headerHeight || 0}px`;
        }
      }
      // Update lastScrollTop with the current scroll position (ensuring it's not negative).
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    // Add scroll event listener if it's a tablet or mobile
    if (isTabletOrMobile) {
      document.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Remove event listeners on cleanup.
      window.removeEventListener('resize', updateHeaderHeight);
      document.removeEventListener('scroll', handleScroll);
      // Reset body padding-top on cleanup.
      document.body.style.paddingTop = '';
    };
  }, [isTabletOrMobile, headerHeight]);

  return (
    <>
      <Header {...rest} ref={headerRef} />
    </>
  );
};

export default HeaderWrapper;