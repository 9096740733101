import React, { FC } from 'react';
import { IRelatedPost } from './RelatedPost.def';
import { Link } from 'gatsby';
import { createSourceElements, createSrc, createSrcSet } from '../../../../utils';
import { processCopyright } from '../../../../utils/helpers';
import parser from 'html-react-parser';

import {
  MED_DESKTOP_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
} from '../../../../constants';

const RelatedPost: FC<IRelatedPost> = ({ link, thumbnail, title }) => {
  const sourceElements = createSourceElements([
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
    {
      media: MED_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 500,
            height: 333,
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 400,
            height: 266,
          },
        },
      ]),
    },
  ]);

  if (thumbnail) {
    thumbnail = {
      ...thumbnail,
      src: createSrc(thumbnail.src || ''),
    };
  }

  return (
    <div className="related-post">
      <Link to={link} className="related-post__thumbnail-wrapper">
        <figure className="related-post__thumbnail">
          <picture>
            {sourceElements}
            <img {...thumbnail} loading="lazy" />
          </picture>
        </figure>
      </Link>
      <Link className="related-post__title" to={link}>
        {parser(processCopyright(title))}
      </Link>
    </div>
  );
};

export default RelatedPost;
