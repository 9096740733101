import React, { useContext } from 'react';
import parse, { Element, DOMNode } from 'html-react-parser';

import ArticleExcerpt from '../components/ContentTypes/Article/ArticleExcerpt';
import ArticleOverlay from '../components/ContentTypes/Article/ArticleOverlay';
import ArticleSimple from '../components/ContentTypes/Article/ArticleSimple';
import CardExcerptESI from '../components/ContentTypes/CardExcerptESI/CardExcerptESI';
import CategoryLink from '../components/ContentTypes/Category/CategoryLink';
import ContactCard from '../components/ContentTypes/ContactCard/ContactCard';
import TeamMember from '../components/About/components/TeamMember';
import PressBlock from '../components/Press/components/PressBlock/PressBlock';
import Testimony from '../components/Testimony/Testimony';
import { IWordPressImage } from '../models/IWordPressImage';
import PlaceholerImg from '../images/card-art-not-available.png';
import { IArticleExcerpt } from '../components/ContentTypes/Article/Article.def';
import Program from '../components/Programs/Program';
import NewCategoryItem from '../components/NewCategoryNav/components/NewCategoryItem/NewCategoryItem';
import RelatedPost from '../components/RelatedPosts/components/RelatedPost';
import { IFeaturedImageData } from '../components/Seo/Seo.def';
import { IProgram } from '../components/Programs/Program/Program.def';
import { IBreadcrumbItem } from '../models/IBreadcrumbItem';
import cardArtDefaultImg from '../images/card-art-not-available-blue.png';
import { IImageSource } from '../models/IImageSource';
import { IBreakpoint } from '../models/IBreakpoint';
import ReviewTile from '../components/ReviewTile/ReviewTile';
import { ICardApiComplianceData } from '../context/CardDataContext.def';
import CardDataContext from '../context/CardDataContext';
import { replaceWpDomainWithFastlyDomain } from './helpers';

const itemMap: any = {
  ArticleExcerpt: ArticleExcerpt,
  ArticleOverlay: ArticleOverlay,
  ArticleSimple: ArticleSimple,
  CardExcerptESI: CardExcerptESI,
  CategoryLink: CategoryLink,
  ContactCard: ContactCard,
  NewCategoryItem: NewCategoryItem,
  PressBlock: PressBlock,
  Program: Program,
  TeamMember: TeamMember,
  RelatedPost: RelatedPost,
  Testimonial: Testimony,
  ReviewTile: ReviewTile,
};

export const renderItems = (
  items: any,
  itemType: string,
  linkClick?: CallableFunction | null,
  extraProps: any = null
) => {
  const Component = itemMap[itemType];
  return (
    items &&
    items.map((item: any, index: number) => (
      <Component key={index} {...item} linkClick={linkClick} {...extraProps} />
    ))
  );
};

export const renderItemsList = (items: any, itemType: string, linkClick?: CallableFunction) => {
  const Component = itemMap[itemType];
  return items.map((item: any, index: number) => (
    <li key={index}>
      <Component {...item} linkClick={linkClick} />
    </li>
  ));
};

export const createSourceElements = (sources: IImageSource[]) => {
  return sources.map((source) => <source key={source.srcSet} {...source} />);
};

export const createSrcSet = (imageSrc: string, breakpoints: IBreakpoint[]): string => {
  return breakpoints
    .map((breakpoint) => {
      return createSingleSrcSet(imageSrc, breakpoint);
    })
    .join(', ');
};

const createSingleSrcSet = (imageSrc: string, breakpoint: IBreakpoint) => {
  return `${replaceWpDomainWithFastlyDomain(imageSrc, {
    ...breakpoint.options,
  })} ${breakpoint.isWidthBreakpoint ? `${breakpoint.options.width}w` : ''}`;
};

export const createSrc = (url: string) => {
  return replaceWpDomainWithFastlyDomain(url, undefined, true);
};

export const processMenuItems = (allWpMenuItem: Queries.WpMenuItemConnection) => {
  return allWpMenuItem.edges.map(({ node }) => {
    const { childItems, id, url, label } = node;
    if (childItems?.nodes && childItems.nodes.length > 0) {
      const subMenu = {
        categories:
          node.childItems?.nodes?.slice(0, 3)?.map((subMenuNode) => {
            return {
              title: {
                id: subMenuNode?.id || '',
                link: subMenuNode?.url || '',
                text: subMenuNode?.label || '',
              },
              links:
                subMenuNode?.childItems?.nodes?.map((subSubNode) => {
                  return {
                    id: subSubNode?.id || '',
                    link: subSubNode?.url || '',
                    text: subSubNode?.label || '',
                  };
                }) || [],
            };
          }) || [],
      };
      return {
        id: id,
        link: url || '',
        text: label || '',
        subMenu,
      };
    }

    return {
      id: id,
      link: url || '',
      text: label || '',
    };
  });
};

export const getPlaceholderGatsbyImg = () => PlaceholerImg;

export const getPlaceholderWpGatsbyImg = () => {
  return {
    sourceUrl: getPlaceholderGatsbyImg(),
    altText: 'missing image',
  };
};

export const getImageFromWpImage = (wpImage: IWordPressImage) => {
  return {
    src: wpImage?.sourceUrl || getPlaceholderGatsbyImg(),
    alt: wpImage?.altText || 'missing image',
  };
};

export const getDefaultCardArt = (cardTitle: string) => ({
  alt: cardTitle,
  src: cardArtDefaultImg,
});

export const isElement = (domNode: DOMNode): domNode is Element => {
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return hasAttributes;
};

export const getArticle = (post: Queries.WpPost): IArticleExcerpt => {
  let category = null;
  if (post?.categories?.nodes?.length) {
    category = post.categories.nodes.find((node) => node && !node.parentId);
  }

  return {
    link: post?.uri || '#',
    title: post?.title || '',
    thumbnail: {
      src: post?.featuredImage?.node?.sourceUrl
        ? post.featuredImage.node.sourceUrl
        : getPlaceholderGatsbyImg(),
      alt: post?.title || '',
    },
    excerpt: post?.excerpt || '',
    date: post?.date || '',
    category:
      (category && {
        link: category?.link || '#',
        text: category?.name || '',
      }) ||
      null,
  };
};

export const getArticles = (posts: Queries.WpPost[]): IArticleExcerpt[] => {
  return posts.map((post) => {
    let category = null;
    if (post?.categories?.nodes?.length) {
      category = post.categories?.nodes?.find((node) => node && !node.parentId);
    }

    return {
      link: post?.uri || '#',
      title: post?.title || '',
      thumbnail: {
        src: post?.featuredImage?.node?.sourceUrl
          ? post.featuredImage.node.sourceUrl
          : getPlaceholderGatsbyImg(),
        alt: post?.title || '',
        width: post?.featuredImage?.node?.width || 0,
        height: post?.featuredImage?.node?.height || 0
      },
      excerpt: post?.excerpt || '',
      date: post?.dateGmt || '',
      author: {
        text: post.author?.node?.name || '',
        link: post.author?.node?.uri || '#',
      },
      category:
        (category && {
          link: category?.link || '#',
          text: category?.name || '',
        }) ||
        null,
    };
  });
};

export const getPrograms = (posts: Queries.WpPost[]): IProgram[] => {
  return posts.map((post) => {
    return {
      link: post?.uri || '#',
      thumbnail: {
        alt: post?.title || '',
        src: post.featuredImage?.node?.sourceUrl
          ? post.featuredImage.node.sourceUrl
          : getPlaceholderGatsbyImg(),
      },
      title: post?.title || '',
    };
  });
};

export const getArticleExcerptFromWpPost = (wpArticle: Queries.WpPost) => {
  const featuredImageNode = wpArticle?.featuredImage?.node;
  const featuredImageNodeThumb: IWordPressImage = featuredImageNode
    ? {
        sourceUrl: featuredImageNode.sourceUrl || getPlaceholderGatsbyImg(),
        altText: featuredImageNode.altText || '',
      }
    : getPlaceholderWpGatsbyImg();
  const articleLink = wpArticle?.uri || '#';
  return {
    thumbnail: getImageFromWpImage(featuredImageNodeThumb),
    imageOverlay: true,
    title: wpArticle?.title || 'No Title',
    link: articleLink,
    excerpt: wpArticle?.excerpt || '',
    date: wpArticle?.dateGmt,
    moreLink: {
      link: articleLink,
      text: 'Learn More',
    },
    author: {
      text: wpArticle.author?.node?.name,
      link: wpArticle.author?.node?.uri,
    },
  };
};

export const getArticlesPerPage = (
  articles: IArticleExcerpt[],
  currentPageIndex: number,
  maxNumberOfArticlesPerPage: number
) => {
  return articles.slice(
    currentPageIndex * maxNumberOfArticlesPerPage,
    currentPageIndex * maxNumberOfArticlesPerPage + maxNumberOfArticlesPerPage
  );
};

export const getFeaturedImageDataFromWpPost = (
  post: Queries.WpPost | Queries.WpPage | undefined
) => {
  const node = post?.featuredImage?.node;
  const featuredImageData: IFeaturedImageData = {
    sourceUrl: node?.sourceUrl || '',
    caption: node?.caption || '',
    width: node?.width || 0,
    height: node?.height || 0,
  };
  return featuredImageData;
};

export const getFeaturedImageDataFromArticleExcerpt = (articleExcerpt: IArticleExcerpt) => {
  if (!articleExcerpt) return null;
  const image = articleExcerpt.thumbnail;
  if (!image) return null;
  const featuredImageData: IFeaturedImageData = {
    sourceUrl: image.src,
    caption: image.alt || '',
    width: image.width,
    height: image.height,
  };
  return featuredImageData;
};

export const getDefaultSEO = (
  pathname: string,
  pageTitle: string,
  socialTitle: string,
  ogDescription: string,
  includeCollectionPageType = false,
  extraBreadcrumbList: IBreadcrumbItem[] | null = null,
  metaDesc = null
) => {
  if (!pathname.endsWith('/')) pathname += '/';
  const yoastSchemaGraph = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'BreadcrumbList',
        '@id': pathname + '#breadcrumb',
        itemListElement: [{ '@type': 'ListItem', position: 1, name: 'Home', item: '/' }],
      },
      {
        '@type': 'WebSite',
        '@id': '#website',
        url: '/',
        name: 'UpgradedPoints.com',
        description: 'Upgrade Your Travel',
        publisher: { '@id': '/#organization' },
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: '/search/{search_term_string}',
            },
            'query-input': 'required name=search_term_string',
          },
        ],
        inLanguage: 'en-US',
      },
      {
        '@type': 'Organization',
        '@id': '#organization',
        name: 'UpgradedPoints.com',
        alternateName: 'UP',
        url: '/',
        logo: {
          '@type': 'ImageObject',
          inLanguage: 'en-US',
          '@id': '#/schema/logo/image/',
          url: 'wp-content/uploads/2020/09/favicon.png',
          contentUrl: 'https://upgradedpoints.com/wp-content/uploads/2020/09/favicon.png',
          width: 512,
          height: 512,
          caption: 'UpgradedPoints.com',
        },
        image: { '@id': '#/schema/logo/image/' },
        sameAs: [
          'https://www.facebook.com/upgradedpoints/',
          'https://twitter.com/upgradedpoints',
          'https://mastodon.social/@upgradedpoints',
          'https://instagram.com/upgradedpoints/',
          'https://www.pinterest.com/upgradedpoints/',
          'https://www.linkedin.com/company/upgraded-points',
          'https://www.wikidata.org/wiki/Q97119102',
        ],
      },
    ],
  };
  if (includeCollectionPageType) {
    yoastSchemaGraph['@graph'].push({
      '@type': 'CollectionPage',
      '@id': pathname,
      url: pathname,
      name: pageTitle,
      isPartOf: { '@id': '#website' },
      breadcrumb: { '@id': '#breadcrumb' },
      inLanguage: 'en-US',
    });
  } else {
    //Use the default WebPage type
    const currentDateTime = new Date().toISOString();
    yoastSchemaGraph['@graph'].push({
      '@type': 'WebPage',
      '@id': pathname,
      url: pathname,
      name: pageTitle,
      isPartOf: {
        '@id': '#website',
      },
      datePublished: currentDateTime,
      dateModified: currentDateTime,
      description: ogDescription,
      breadcrumb: {
        '@id': pathname + '#breadcrumb',
      },
      inLanguage: 'en-US',
      potentialAction: [
        {
          '@type': 'ReadAction',
          target: [pathname],
        },
      ],
    });
  }
  let bcFullPath = '/';
  if (extraBreadcrumbList) {
    extraBreadcrumbList.forEach((bc: IBreadcrumbItem, index: number) => {
      bcFullPath += bc.slug + '/';
      const schemaListItem = {
        '@type': 'ListItem',
        position: index + 2,
        name: bc.name,
        item: bcFullPath,
      };
      const breadcrumbListObj = yoastSchemaGraph['@graph'].find(
        (g) => g['@type'] === 'BreadcrumbList'
      );
      breadcrumbListObj?.itemListElement?.push(schemaListItem);
    });
  }

  return {
    metaRobotsNofollow: 'follow',
    metaRobotsNoindex: 'index',
    metaDesc: metaDesc,
    canonical: pathname,
    opengraphType: 'article',
    opengraphImage: {
      sourceUrl: '/wp-content/uploads/2021/05/UP-Logo-stacked-colour.jpg',
      height: 1279,
      width: 3000,
      mediaType: 'image/jpeg',
    },
    opengraphTitle: socialTitle,
    opengraphDescription: ogDescription,
    opengraphUrl: pathname,
    schema: { raw: JSON.stringify(yoastSchemaGraph) },
  };
};

export const verifyEmail = (email: string) => {
  return /^[\w-\+~\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
};

export const isCreditCardsReviewPage = (pathname: string) => {
  return /\/credit-cards\/reviews\/[a-zA-Z-]*\//.test(pathname);
};

export const isPreview = (pathname: string) => {
  return /\/preview\/.*/.test(pathname);
};

// String all html tags from the passed in string
export const stripTags = (stringWithTags: string) => {
  const processElement = (element) => {
    let retString = '';
    if (!element) return retString;
    if (typeof element === 'string') {
      retString += element;
    } else if (typeof element === 'object' && element.type) {
      retString = processElement(element.props?.children);
    } else if (Array.isArray(element)) {
      element.forEach((childElement) => {
        retString += processElement(childElement);
      });
    }
    return retString;
  };

  const element = parse(stringWithTags);
  if (!Array.isArray(element)) {
    return stringWithTags;
  }
  return processElement(element);
};

export const getNeedToAddSourceFromCardLink = (cardLink: string) => {
  const { cardDataJson } = useContext(CardDataContext);
  const allCardData = cardDataJson?.data;
  const cardDataForCard =
    (allCardData &&
      allCardData.length &&
      allCardData.length > 0 &&
      allCardData.find((cd) => cd.affiliateLinkReviewPage === cardLink)) ||
    null;
  return cardDataForCard?.upgpCardReviewAddTrackingSourceType || false;
};

const formatStringToCamelCase = (str: string) => {
  const splitted = str.split('-');
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0]?.toUpperCase() + word.slice(1))
      .join('')
  );
};

export const getStyleObjectFromString = (str: string) => {
  const style: Record<string, string> = {};
  str.split(';').forEach((el) => {
    const [property, value] = el.split(':');
    if (!property) return;

    const formattedProperty = formatStringToCamelCase(property.trim());

    if (formattedProperty && value) {
      style[formattedProperty] = value.trim();
    }
  });

  return style;
};

export const removeTrailingSlash = (str: string) => str.replace(/\/+$/, '');

export const addTrailingSlash = (str: string) => {
  if (!str.endsWith('/')) {
    return str + '/';
  }
  return str;
};

export const addLeadingAndTralingSlashes = (link: string) => {
  if (!link.startsWith('/')) {
    link = `/${link}`;
  }

  if (!link.endsWith('/')) {
    link = `${link}/`;
  }

  return link;
};

export const processImageApiComplianceValue = ({ value }: ICardApiComplianceData) =>
  typeof value?.length !== 'undefined' ? value.toString() : '';

export const getExistingHtml = (selector: string, property: string) => {
  const element = document.querySelector(selector);
  return element ? element[property] : null;
};

/**
 * Create the 'rel' attribute for an external link that has the target='_blank' attribute.
 * Given the existing attribute value from the link, add the 'noopener' property if it
 * doesn't already have it.
 * */
export const appendNoopenerSafely = (rel: string | undefined) => {
  let newRel = rel || 'noopener';
  return newRel + (newRel.includes('noopener') ? '' : ' noopener');
};


/**
 * Checks if a given url is included in a list of urls separated by newlines.
 * @param urlListString The string containing urls separated by newlines.
 * @param targetUrl The url to check against the list.
 * @returns true if the targetUrl is included in the urlListString, false otherwise.
 */
export function isUrlInList(urlListString: string | null | undefined, targetUrl: string | null | undefined): boolean {
  // Combine checks to ensure inputs are non-empty strings
  if (typeof urlListString !== 'string' || urlListString.trim() === '' ||
    typeof targetUrl !== 'string' || targetUrl.trim() === '') {
  return false;
  }

  // Split urlListString into an array by each newline, clean each URL.
  const urlArray = urlListString.split('\n');
  if ( ! urlArray.length ) return false

  const urls = urlArray.map(url => url.replace(/\r/g, '').trim())

  // Check if targetUrl is included in the array of clean URL.
  return urls.includes(targetUrl);
}


/**
 * Function to try to obtain the domain from the href attribute of a link. The function should be
 * robust enough to support all that could be added to the href attribute of a link.
 *
 * @param url String that comes from the href of an anchor tag.
 * @returns String that is the domain of the href or empty if no domain is possible to parse.
 */
export const getDomainFromUrl = (url: string | undefined) => {
  if (!url) {
    return '';
  }

  // Create a new variable to modify the URL
  let modifiedUrl = url;

  // Check if the URL does not start with http:// or https://
  if (!/^https?:\/\//i.test(modifiedUrl)) {
    modifiedUrl = 'https://' + modifiedUrl;
  }

  // Use a regex to match the domain part of the URL
  const domain = modifiedUrl.match(/^https?:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  
  return (domain && typeof domain[1] === 'string') ? domain[1] : '';
}
