import { graphql, useStaticQuery } from 'gatsby';

const getKeyTakeawaysOptions = () => {
  const { wpUpOption } = useStaticQuery(graphql`
    query {
      wpUpOption(slug: { eq: "key-takeaways-global-options" }) {
        keyTakeawaysGlobalOptions {
          upgpGlobalKtTitle
        }
      }
    }
  `);

  return wpUpOption?.keyTakeawaysGlobalOptions?.upgpGlobalKtTitle;
};

export default getKeyTakeawaysOptions;
