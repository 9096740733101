import { ReactElement, useContext, useEffect, useState } from 'react';
import {
  doTrackingForBI,
  getUserIdFromLS,
  updateSessionDataInGoLinks,
} from '../utils/helpers/goLinks';
import { useLocation } from '@reach/router';
import { getSourceMediumMappings } from '../utils/static-queries';
import HTEventsContext from '../context/HTEventsContext';
import { sendHtIdentifyEvent } from '../utils/helpers/htApi';
import { NO_LOCAL_STORAGE } from '../constants';

const TrackingWrapper = ({ children }: { children: ReactElement }) => {
  const location = useLocation();
  const path = location.pathname;
  const sourceMediumMappings = getSourceMediumMappings();
  const [newUserCreated, setNewUserCreated] = useState(false);
  const htevents = useContext(HTEventsContext);

  useEffect(() => {
    const newUser = doTrackingForBI(path, sourceMediumMappings);
    if (newUser) {
      setNewUserCreated(true);
    }
    updateSessionDataInGoLinks();
  }, [path]);

  useEffect(() => {
    const sendHtIdentify = async () => {
      const biUserId = getUserIdFromLS();
      let htUserId = null;
      if (biUserId === NO_LOCAL_STORAGE) {
        //If there is no local storage, HT will generally not be functional,
        //but send an impression event anyway to indicate this about this user in the DW.
        sendHtIdentifyEvent(htevents, biUserId);
      } else {
        const htUser = await htevents?.user();
        if (htUser) {
          htUserId = htUser.id();
        }
        //Send an Identify event when a new bi_user_id is created,
        //or when it does not match the current HT userId.
        if (newUserCreated || biUserId !== htUserId) {
          sendHtIdentifyEvent(htevents, biUserId);
        }
      }
    };

    sendHtIdentify();
  }, [htevents, newUserCreated]);

  return children;
};

export default TrackingWrapper;
