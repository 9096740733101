import React, { FC } from 'react';
import { CAP_ONE_DISCLAIMER_TEXT } from '../../constants';
import { ICapOneDisclaimerTooltip } from './ICapOneDisclaimerTooltip';

const CapOneDisclaimerTooltip: FC<ICapOneDisclaimerTooltip> = ({ closeClick }) => {
  const handleCloseClick = () => {
    closeClick();
  };

  return (
    <span className="inline-tooltip up-cap-one-disclaimer__tooltip">
      <button className="closeTooltip" onClick={handleCloseClick}>
        ×
      </button>
      {CAP_ONE_DISCLAIMER_TEXT}
    </span>
  );
};

export default CapOneDisclaimerTooltip;
