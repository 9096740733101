import React, { FC } from 'react';
import { IProgram } from './Program.def';
import { Link } from 'gatsby';
import ArrowRightIcon from '../../../svgs/ArrowRightIcon';
import { processCopyright } from '../../../utils/helpers';
import parser from 'html-react-parser';
import { createSourceElements, createSrc, createSrcSet } from '../../../utils';
import {
  MEDIUM_TAB_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
} from '../../../constants';

const Program: FC<IProgram> = ({ link, thumbnail, title, category }) => {
  let sourceElements = createSourceElements([
    {
      media: MEDIUM_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 500,
            height: 236,
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 450,
            height: 338,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);

  if (thumbnail) {
    thumbnail = {
      ...thumbnail,
      src: createSrc(thumbnail.src || ''),
    };
  }

  return (
    <article className="program">
      <header>
      <figure className="program__image-wrapper">
        <Link to={link}>
          <picture>
            {sourceElements}
            <img {...thumbnail} loading="lazy" />
          </picture>
        </Link>
      </figure>
      </header>
      <div className="program__content">
        {category?.link && (
          <Link to={category.link} className="program__content__category">
            {category.text}
          </Link>
        )}
        {!category?.link && category?.text && (
          <span className="program__content__category">{category.text}</span>
        )}
        <h3 className="program__content__title">
          <Link to={link}>{parser(processCopyright(title))}</Link>
        </h3>
      </div>
    </article>
  );
};

export default Program;
