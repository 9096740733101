import React, { FC, useState } from 'react';
import { Link } from 'gatsby';

interface IInlineTooltipWrapper {
  className: string;
  linkClassName?: string;
  linkTo?: string;
  linkChildren: string;
  TooltipComponentName: React.ElementType | null;
  tooltipComponentProps?: object;
  activeTooltip?: string;
  setActiveTooltip?: (tooltipLinkClassName: string) => void;
}

const InlineTooltipWrapper: FC<IInlineTooltipWrapper> = ({
  className,
  linkClassName,
  linkTo,
  linkChildren,
  TooltipComponentName,
  tooltipComponentProps,
  activeTooltip,
  setActiveTooltip,
}) => {
  let isActive = false;
  if(linkClassName) {
    isActive = activeTooltip === linkClassName;
  }
  const [isClicked, setIsClicked] = useState(false);
  const handleLinkClick = (e: any) => {
    if (window.innerWidth < 800 && TooltipComponentName) {
      e.preventDefault();
      if (setActiveTooltip && linkClassName) {
        // We using linkClassName because it is unique to each author tooltip
        setActiveTooltip(linkClassName);
      } else {
        // We keeping this functionality because it is used by CapOne Disclaimer
        setIsClicked(true);
      }
    }
  };

  const handleCloseTooltip = () => {
    if (setActiveTooltip) {
      setActiveTooltip('');
    } else {
      // We keeping this functionality because it is used by CapOne Disclaimer
      setIsClicked(false);
    }
  };

  let containerClassName = className + ' inline-tooltip-container';
  if (isClicked || isActive) {
    containerClassName += ' open-tooltip';
  }
  
  const AuthorLink = () => TooltipComponentName ? (
    <span tabIndex={0} className={linkClassName} onClick={handleLinkClick} aria-label="Open Tooltip">
      {linkChildren}
    </span>
  ) : (
    <Link className={linkClassName} to={linkTo || ''} onClick={handleLinkClick}>
      {linkChildren}
    </Link>
  )
  
  return (
    <span className={containerClassName}>
      {linkTo ? (
        <AuthorLink/>
      ) : (
        <button className={linkClassName} onClick={handleLinkClick}>
          {linkChildren}
        </button>
      )}
      {TooltipComponentName && (
        <TooltipComponentName {...tooltipComponentProps} closeClick={handleCloseTooltip} />
      )}
    </span>
  );
};

export default InlineTooltipWrapper;
