import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { useModal } from './../../context/ModalContext';
import useClickOutside from '../../hooks/useClickOutside';

const ValuationModal = () => {
  const { closeModal } = useModal();
  const ref = useRef(null);

  useClickOutside(ref, closeModal);

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? closeModal() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [closeModal]);

  return (
    <div className="valuation-modal-container">
      <div className="valuation-modal-content" ref={ref}>
        <div className="valuation-button-container">
          <button className="valuation-button" onClick={closeModal}>
            <p>&times;</p>
          </button>
        </div>
        <div className="valuation-text">
          <p>
            <strong>UP's Bonus Valuation</strong>
          </p>
          <hr />
          <p>
            This bonus value is an estimated valuation&nbsp;
            <Link to={`/travel/points-and-miles-valuations/`}>calculated by UP</Link> after
            analyzing redemption options, transfer partners, award availability and how much UP
            would pay to buy these points.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ValuationModal;
