/**
 * Cleans a string by removing all non-alphanumeric characters except underscores,
 * trimming whitespace from the beginning and end, and converting all characters to lowercase.
 * 
 * The function first trims any leading or trailing whitespace. Then, it uses a regular expression
 * to remove any characters that are not letters, numbers, or underscores. Finally, it converts
 * the remaining characters to lowercase.
 *
 * This is useful for creating standardized, simplified versions of strings where only
 * basic text characters are needed, and casing is not important.
 *
 * @param {string} str - The string to be cleaned.
 * @return {string} The cleaned string.
 */
export const cleanString = (str: string) => str.replace(/[^a-zA-Z]/g, '').toLowerCase();
