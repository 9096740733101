import facebookIconBlue from '../images/social-facebook-blue.svg';
import twitterIconBlue from '../images/social-twitter-blue.svg';
import instagramIconBlue from '../images/social-instagram-blue.svg';
import emailIconBlue from '../images/social-email-blue.svg';
import facebookIcon from '../images/social-facebook.svg';
import twitterIcon from '../images/social-twitter.svg';
import instagramIcon from '../images/social-instagram.svg';
import emailIcon from '../images/social-email.svg';
import socialPinterest from '../images/social-pinterest.svg';
import socialPinterestBlue from '../images/social-pinterest-blue.svg';
import socialLinkedIn from '../images/social-linkedin.svg';
import socialLinkedInBlue from '../images/social-linkedin-blue.svg';
import socialYoutube from '../images/social-youtube.svg';
import socialYoutubeBlue from '../images/social-youtube-blue.svg';

const socialIcons = [
  {
    name: 'facebook',
    icon: {
      blue: facebookIconBlue,
      white: facebookIcon,
    },
  },
  {
    name: 'twitter',
    icon: {
      blue: twitterIconBlue,
      white: twitterIcon,
    },
  },
  {
    name: 'instagram',
    icon: {
      blue: instagramIconBlue,
      white: instagramIcon,
    },
  },
  {
    name: 'email',
    icon: {
      blue: emailIconBlue,
      white: emailIcon,
    },
  },
  {
    name: 'pinterest',
    icon: {
      white: socialPinterest,
      blue: socialPinterestBlue,
    },
  },
  {
    name: 'linkedin',
    icon: {
      white: socialLinkedIn,
      blue: socialLinkedInBlue,
    },
  },
  {
    name: 'youtube',
    icon: {
      white: socialYoutube,
      blue: socialYoutubeBlue,
    },
  },
];

export const getSocialIcon = (socialName: string, color?: string) => {
  const social = socialIcons.find(
    (social) => social.name.toLowerCase() === socialName.toLowerCase()
  );

  if (color === 'blue') {
    return social?.icon.blue;
  }

  return social?.icon.white;
};
