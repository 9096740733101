import { ITableDataFormatted } from '../../components/TransferPartnerCalculator/TableInteractive.def';
import { PartnersNames, BonusPoints } from '../../constants';

// Helper functions for TPT Table - TableInteractive

/**
 * Replaces the input version of calculated points
 * with thousand comma separator. F.e. 50000 -> 50,000
 * @param {string | number} num The user's input points.
 * @returns {string} Comma separated thousands version.
 */
export const addCommas = (num: number | string): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Removes the last portion of the partnerName, coming from WP in order
 * to check by name. F.e. United Airlines-Miles -> United Airlines
 * @param {string} partnerName Partner Name, coming from WP.
 * @returns {string} Partner Name without Miles / Avios portion.
 */
export const removeMilesAvios = (partnerName: string): string => {
  return partnerName.split('-')[0] || '';
};

/**
 * Removes non numeric characters
 * @param {string | number} num The user's input points.
 * @returns {string} Cleaned points, without non numerics.
 */
export const removeNonNumeric = (num: string | number): string => {
  return num.toString().replace(/[^0-9]/g, '');
};

/**
 * Calculates the Marriott Bonus points.
 * For every 60,000 Marriott points that are transferred to a partner
 * an extra 5,000 points are added to the total.
 * Special cases - United Airlines - 10,000 points, Air New Zealand - 25.
 * @param {number} points The user's input points.
 * @param {string} partnerName Partner's name to be checked for special cases.
 * @returns {number} Calculated bonus.
 */

export const calculateMarriottBonus = (points: Number, partnerName: string): number => {
  let partnerToCheck = removeMilesAvios(partnerName);
  let bonusPoints = 0;
  if (partnerToCheck === PartnersNames.UNITED_AIRLINES) {
    bonusPoints = BonusPoints.MARRIOTT_UNITED_AIRLINES;
  } else if (partnerToCheck === PartnersNames.AIR_NEW_ZEALAND) {
    bonusPoints = BonusPoints.MARRIOTT_AIR_NEW_ZEALAND;
  } else {
    bonusPoints = BonusPoints.DEFAULT;
  }
  let quotient = (points as number) / 60000;
  let bonus = Math.floor(quotient) * bonusPoints;

  return bonus;
};

/**
 * Calculates all the points that populate the table.
 * American Airlines, Avianca Airlines, Delta and Korean Air do not
 * get Marriott Bonus
 * @param {ITableDataFormatted[]} data All table information.
 * @param {number} userPoints User's input points, intially set to 50000.
 * @returns {ITableDataFormatted[] | []} Calculated points table.
 */
export const calculatePoints = (
  data: ITableDataFormatted[],
  userPoints: number = 50000,
): ITableDataFormatted[] | [] => {
  if (!data || data.length === 0) {
    return [];
  }

  const TableWithData = data.map((item) => {
    const partnerNameToCheck = removeMilesAvios(item.tptFlexiblePointsPartnersPartnerName);

    return {
      ...item,
      calculatedPoints:
        item.flexiblePoints === 'Marriott Bonvoy' &&
        partnerNameToCheck !== PartnersNames.AMERICAN_AIRLINES &&
        partnerNameToCheck !== PartnersNames.AVIANCA_AIRLINES &&
        partnerNameToCheck !== PartnersNames.DELTA &&
        partnerNameToCheck !== PartnersNames.KOREAN_AIR
          ? addCommas(
              Math.round(
                (userPoints / item.tptFlexiblePointsPartnersTransferRatioBase) *
                  item.tptFlexiblePointsPartnersTransferRatio,
              ) + calculateMarriottBonus(userPoints, item.tptFlexiblePointsPartnersPartnerName),
            )
          : addCommas(
              Math.round(
                (userPoints / item.tptFlexiblePointsPartnersTransferRatioBase) *
                  item.tptFlexiblePointsPartnersTransferRatio,
              ),
            ),
    };
  });

  return TableWithData;
};
