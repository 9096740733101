import { IFastlyOptions } from '../../models/IFastlyOptions';

export const replaceDomainWithSiteDomain = (url: string) => {
  if (!url) return null; //Exit early if url is not defined
  const siteUrl = process.env['GATSBY_SITE_URL'];
  if (!siteUrl) return url; //Exit early if site url is not defined
  const urlParts = url.split('/');
  if (urlParts[0]?.includes('http')) {
    //First, make sure we use https not http
    if (urlParts[0] === 'http:') {
      urlParts[0] = 'https:';
    }
    const siteDomain = siteUrl.split('/')[2];
    if (siteDomain) urlParts[2] = siteDomain;
    return urlParts.join('/');
  }
  //url has no domain, just prepend it
  return siteUrl + url;
};

export const getSiteDomainWithoutProtocol = () =>
  process.env['GATSBY_SITE_URL']?.replace(/^https?:\/\//, '');

export const prependUpgradedPointsDomain = (url: string) => {
  const siteUrl = process.env['GATSBY_SITE_URL'];
  if (!url) return null; //Exit early if url is not defined
  //If the url already has a domain, call the replace function
  if (url.includes('https://') || url.includes('http://')) {
    return replaceDomainWithSiteDomain(url);
  }
  if (!url.startsWith('/')) url = '/' + url;
  return siteUrl + url;
};

export const replaceWpDomainWithFastlyDomain = (
  url: string,
  options?: IFastlyOptions,
  isFallbackImage: boolean = false,
) => {
  const wpDomain = process.env['GATSBY_WORDPRESS_URL'];
  const fastlyDomain = process.env['GATSBY_SITE_URL'];
  let newUrl = url;
  if (wpDomain && fastlyDomain && (newUrl.startsWith('/') || newUrl.startsWith(wpDomain))) {
    newUrl = url.replace(wpDomain, fastlyDomain);

    if (newUrl.startsWith('/')) {
      newUrl = fastlyDomain + newUrl;
    }

    // Add Default Query Params
    if (!/\?[a-z0-9]*=[a-z0-9-%]*/.test(newUrl)) {
      newUrl = `${newUrl}?auto=webp&disable=upscale`;

      if (isFallbackImage) {
        newUrl = `${newUrl}&width=1200`;
      }
    }

    if (options) {
      newUrl = `${newUrl}&width=${options?.width}`;
      if (options?.height) {
        newUrl = `${newUrl}&height=${options?.height}`;
      }
      if (options?.fit) {
        newUrl = `${newUrl}&fit=${options.fit}`;
      } else if (options?.width && options?.height) {
        newUrl = `${newUrl}&fit=crop`;
      }
      if (options?.dpr) {
        newUrl = `${newUrl}&dpr=${options.dpr}`;
      }
    }
  }

  if (newUrl && !newUrl.startsWith('http')) {
    newUrl = `https://${newUrl}`;
  }
  return newUrl;
};

export const removeDomain = (url: string) => {
  if (!url) return null; //Exit early if url is not defined
  const urlParts = url.split('/');
  if (urlParts[0]?.includes('http')) {
    return '/' + urlParts.slice(3).join('/');
  }
  //url has no domain, just return it
  return url;
};

/**
 * Function to get the domain only wihtout the www.
 *
 * @param url String URL
 * @returns String Domain without the www.
 */
export const getDomainWithoutWWW = (url: string) => {
  try {
    let hostname = new URL(url).hostname;
    
    // Remove "www." if present at the beginning
    return hostname.replace(/^www\./, '');
  } catch (e) {
    console.error('Invalid URL: ', e);
    return '';
  }
}
