import { graphql, useStaticQuery } from 'gatsby';
import { IWpPost } from '../../models/IWpPost';

const getLatestCreditCardPosts = () => {
  const postData = useStaticQuery(graphql`{
  allParentPosts: allWpPost(
    filter: {categories: {nodes: {elemMatch: {name: {eq: "Credit Cards"}}}}}
    limit: 10
    sort: {date: DESC}
  ) {
    nodes {
      title
      uri
      date
      featuredImage {
        node {
          sourceUrl
          altText
        }
      }
    }
  }
  allChildPosts: allWpPost(
    filter: {categories: {nodes: {elemMatch: {ancestors: {nodes: {elemMatch: {name: {eq: "Credit Cards"}}}}}}}}
    limit: 10
    sort: {date: DESC}
  ) {
    nodes {
      title
      uri
      date
      featuredImage {
        node {
          sourceUrl
          altText
        }
      }
    }
  }
}`);

  const { allParentPosts, allChildPosts } = postData;
  return allParentPosts.nodes.sort((a: IWpPost, b: IWpPost) => (a.date < b.date ? 1 : -1));
};

export default getLatestCreditCardPosts;
