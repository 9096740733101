import React, { FC } from 'react';
import parser from 'html-react-parser';

import { ICardSticky } from './CardSticky.def';
import GoLink from '../GoLink/GoLink';
import TimesIcon from '../../svgs/TimesIcon';
import useIsMobile from '../../hooks/useIsMobile';
import { processCopyright } from '../../utils/helpers';
import TooltipContainer from '../../containers/TooltipContainer';
import OrangeBadge from '../base/OrangeBadge/OrangeBadge';
import RedBadge from '../base/RedBadge/RedBadge';

const CardSticky: FC<ICardSticky> = ({
  title,
  cardImage,
  moreButton,
  feature,
  tooltipText,
  neeedToAddSource,
  handleIsStickyActive,
  disclaimer,
  badges,
  terms,
}) => {
  const isMobile = useIsMobile();
  const placing = '/TRS/';

  return (
    <div className="cardSticky">
      {typeof cardImage?.src === 'string' && (
        <div className="thumbnailContainer">
          <figure className="cardThumbnail">
            <GoLink
              variant="card-review-thumbnail"
              link={moreButton.link || ''}
              thumbnail={cardImage}
              placingFromProps={placing}
              needToAddSource={neeedToAddSource}
            />
          </figure>
          {terms && terms.link && terms.text && (
            <GoLink
              link={terms.link}
              variant="plain"
              text={terms.text}
              placingFromProps={placing}
            />
          )}
        </div>
      )}
      <div className="cardTitle">
        <div className="cardBadges">
          {badges.redBadgeText && <RedBadge redBadgeText={badges?.redBadgeText} />}
          {badges.orangeBadgeText && <OrangeBadge orangeBadgeText={badges?.orangeBadgeText} />}
        </div>
        <p className="cardHeading">
          <GoLink
            link={moreButton.link || ''}
            text={parser(processCopyright(title))}
            variant="plain"
            placingFromProps={placing}
            needToAddSource={neeedToAddSource}
          />
        </p>
      </div>
      <div className="feature">
        <div className="welcome-offer-wrapper">
          <label>
            {feature.title}
            <TooltipContainer tooltipText={tooltipText} />
          </label>
          <p className="offerDetails">{feature.subTitle}</p>
        </div>
      </div>
      <div className="cardCloseBtn" onClick={handleIsStickyActive}>
        <TimesIcon fill={isMobile ? '#fff' : '#003458'} className="sticky-close-button" />
        <span>close</span>
      </div>
      <div className="cardActionBtn">
        <GoLink
          link={moreButton.link || ''}
          text={moreButton.text || 'Learn More'}
          variant="card-table-button-sticky"
          placingFromProps={placing}
          needToAddSource={neeedToAddSource}
        />
        {disclaimer && <p className="disclaimerText">{disclaimer}</p>}
      </div>
    </div>
  );
};

export default CardSticky;
