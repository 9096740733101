import React, { FC } from 'react';
import LockIcon from '../../svgs/LockIcon';
import { appendSessionIdToGoLinks } from '../../utils/helpers/goLinks';
import { IGoLink } from './GoLink.def';
import {
  createSourceElements,
  createSrc,
  createSrcSet,
  removeTrailingSlash,
} from '../../utils';
import { prependUpgradedPointsDomain } from '../../utils/helpers';
import { MOBILE_MAX_WIDTH_MQ, SMALL_TAB_MIN_WIDTH_MQ } from '../../constants';

const GoLink: FC<IGoLink> = ({
  link,
  text = '',
  variant,
  thumbnail,
  placingFromProps = null,
  needToAddSource = false,
  children = null,
  classNameFromProps,
}) => {
  let placing = placingFromProps ? placingFromProps : '';
  let className = classNameFromProps ? classNameFromProps : '';
  let content = (
    <>
      <LockIcon />
      {text}
    </>
  );
  let linkWithDomain = prependUpgradedPointsDomain(link);
  const sourceElements = createSourceElements([
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 450,
            height: 281,
            fit: 'bounds',
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 500,
            fit: 'bounds',
          },
        },
      ]),
    },
  ]);

  if (thumbnail) {
    thumbnail = {
      ...thumbnail,
      src: createSrc(thumbnail.src || ''),
      width: 270,
      height: 172.8,
    };
  }

  if (variant && ['card-table-thumbnail', 'card-table-terms'].includes(variant)) {
    placing = '/TRD/';
  }
  if (
    variant &&
    ['special-offer', 'special-offer-thumbnail', 'special-offer-heading'].includes(variant)
  ) {
    placing = '/SO/';
  }
  switch (variant) {
    case 'card-table-button':
      className = 'moreLink';
      break;
    case 'card-table-button-sticky':
      className = 'stickyButton';
      break;
    case 'card-table-thumbnail':
    case 'card-review-thumbnail':
      content = (
        <picture>
          {sourceElements}
          <img src={createSrc(thumbnail?.src || '')} alt={thumbnail?.alt} loading="lazy" />
        </picture>
      );
      break;
    case 'card-table-terms':
      content = <span className="thumbnailTerms">{text}</span>;
      break;
    case 'special-offer':
      className = 'upBtn';
      break;
    case 'special-offer-thumbnail':
      content = (
        <figure>
          <picture>
            {sourceElements}
            <img {...thumbnail} loading="lazy" />
          </picture>
        </figure>
      );
      break;
    case 'special-offer-heading':
    case 'plain':
      content = <span>{text}</span>;
      break;
    default:
      if (children) {
        content = children;
      }
  }
  //Prevent double slack between link and placing
  if (linkWithDomain?.endsWith('/') && placing.startsWith('/')) {
    linkWithDomain = removeTrailingSlash(linkWithDomain);
  }
  const linkUrl = appendSessionIdToGoLinks(linkWithDomain + placing, needToAddSource);

  return (
    <a href={linkUrl} className={className} rel="sponsored nofollow" target="_blank">
      {content}
    </a>
  );
};

export default GoLink;
