import React, { useContext } from 'react';
import CardDataContext from '../../context/CardDataContext';
import { DEFAULT_CTA_LANGUAGE, ICardData } from '@throttleup/esi-components';
import { IWeRecommend } from '../../components/WeRecommend/WeRecommend.def';
import { processApiComplianceValues, defaultApiComplianceObject } from '@throttleup/esi-components';
import { getDefaultCardArt, processImageApiComplianceValue } from '..';
import { getABTestBucketAllocations } from './abTest';
import { WO_AB_TEST_NAME } from '../../constants';
import {
  getGoLink,
  getWelcomeInfoForCardTable,
  getWelcomeOfferAmountForCardTable,
} from '@throttleup/esi-components/lib/compliance';

export const getSpecialOffer = (specialOfferCardId = 0): IWeRecommend | null => {
  const { cardDataJson } = useContext(CardDataContext);
  const card = cardDataJson?.data?.find(
    (card: ICardData) => parseInt(`${card?.cardId}`) === parseInt(`${specialOfferCardId}`),
  );

  if (card) {
    const woTestBucket = getABTestBucketAllocations([WO_AB_TEST_NAME], card)[0];
    const title = processApiComplianceValues(card.upgpCardName || defaultApiComplianceObject) || '';
    return {
      benefits: getWelcomeInfoForCardTable(card, woTestBucket),
      ctaButton: {
        link: getGoLink(card, woTestBucket) || '#',
        text: card.ctaLanguage || DEFAULT_CTA_LANGUAGE,
      },
      thumbnail: card.upgpImage?.value
        ? {
            src: processImageApiComplianceValue(card.upgpImage || defaultApiComplianceObject) || '',
            alt: title,
          }
        : getDefaultCardArt(title),
      title,
      welcomeOfferText: getWelcomeOfferAmountForCardTable(card, woTestBucket) || null,
      ratesAndFees: {
        link: card.ratesFeesLink,
        text: 'Terms Apply / Rates & Fees',
      },
      addTrackingSourceType: card.upgpCardReviewAddTrackingSourceType || false,
    };
  }

  return null;
};

export const renderError = (message: string) => <span className="upgpContentError">{message}</span>;
