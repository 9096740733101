import React, { useState, useEffect } from 'react';
import CCMContext from '../context/CCMContext';
import { getCookie } from '../utils/helpers/cookie';
import { CCM_DECISION_COOKIE_NAME } from '../constants';

interface ChildProps {
  children: JSX.Element;
}

const CCMProvider = ({ children }: ChildProps) => {
  const [ccmPreference, setCcmPreference] = useState('');
  const [changeHandlerAdded, setChangeHandlerAdded] = useState(false);

  useEffect(() => {
    const cookieVal = getCookie(CCM_DECISION_COOKIE_NAME);
    if (cookieVal) {
      setCcmPreference(cookieVal);
    }
  }, []);

  //Add the scripts from TrustArc to handle the automatic refresh of the page when the user consent changes
  useEffect(() => {
    if (!changeHandlerAdded) {
      document.body.addEventListener('click', function (event) {
        if (
          event &&
          event.target &&
          (event.target.id === 'truste-consent-button' ||
            event.target.id === 'truste-consent-required')
        ) {
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      });
      var _STATE = {};
      function runOnce() {
        if (!_STATE.hasRunOnce && window.truste && truste.eu && truste.eu.prefclose) {
          console.log('doing run once');
          _STATE.oldValue =
            truste.eu.bindMap.prefCookie &&
            truste.eu.bindMap.prefCookie.split(':')[0].replace(/[^\d.]/g, '-');
          _STATE.oldMethod = truste.eu.prefclose;
          truste.eu.prefclose = function () {
            _STATE.oldMethod();
            if (
              truste.eu.bindMap.prefCookie &&
              truste.eu.bindMap.prefCookie.split(':')[0].replace(/[^\d.]/g, '-') !== _STATE.oldValue
            )
              setTimeout(function () {
                window.location.reload();
              }, 20);
          };
          _STATE.hasRunOnce = true;
          _STATE.i && clearInterval(_STATE.i);
        }
      }
      _STATE.i = setInterval(runOnce, 10);
      setChangeHandlerAdded(true);
    }
  }, [changeHandlerAdded]);

  return <CCMContext.Provider value={ccmPreference}>{children}</CCMContext.Provider>;
};

export default CCMProvider;
