import { graphql, useStaticQuery } from 'gatsby';

const getAllTopSliders = () => {
  const { wpUpOption } = useStaticQuery(graphql`
    query {
      wpUpOption(slug: { eq: "credit-cards-review-top-slider" }) {
        creditCardReviewTopSlider {
          upgpCcReviewTopSliders {
            dealCardName
            hideOn
            displayTopSlider
            displayOn {
              displayCategory
              displayPagesUrl
              displayPosts {
                ... on WpPost {
                  databaseId
                }
              }
              displayPages {
                ... on WpPage {
                  databaseId
                }
              }
              displayCategories {
                databaseId
                slug
              }
              displayTags {
                databaseId
                slug
              }
            }
          }
        }
      }
    }
  `);

  return wpUpOption as Queries.WpUpOption;
};

export default getAllTopSliders;
