import React, { FC, useState } from 'react';
import HubHeader from '../FeaturedHub/HubHeader';
import AsideArticles from '../FullFeatured/AsideArticles';
import FeaturedArticle from '../FullFeatured/FeaturedArticle';
import { ITravelHub } from './TravelHub.def';

const TravelHub: FC<ITravelHub> = ({ title, subtitle, moreLink, links = [], asideTitle }) => {
  const [selectedTabId, setSelectedTabId] = useState(links[0]?.text);
  const selectedTab = links.find((link) => link.text === selectedTabId);
  const featuredArticle = selectedTab?.articles[0];
  const articles = selectedTab?.articles.slice(1);
  return (
    <section className={`hub bg-deepblue`}>
      <div className="container">
        <HubHeader
          title={title}
          subtitle={subtitle}
          moreLink={moreLink}
          links={links}
          linkClick={setSelectedTabId}
          activeTab={selectedTabId || ''}
        />
      </div>
      <section className={`fullFeatured`}>
        <div className="fullFeaturedWrapper">
          {featuredArticle && <FeaturedArticle {...featuredArticle} />}
          {articles && <AsideArticles title={asideTitle || ''} articles={articles} />}
        </div>
      </section>
    </section>
  );
};

export default TravelHub;
