import React, { FC } from 'react';
import { UpRating } from '@throttleup/esi-components';
import { ITestimony } from './Testimony.def';
import { replaceWpDomainWithFastlyDomain } from '../../utils/helpers';

const Testimony: FC<ITestimony> = ({
  name,
  rating,
  thumbnail,
  reviewContent,
  reviewText,
  designation,
}) => {
  //TODO: add option to GB block to use the local version of the thumbnail?
  //Or use local version if thumbnail is undefined from CMS?
  //const staticImageSrc = '/testimonials/' + name.split(' ')[0]?.toLowerCase() + '.png';
  return (
    <div className="testimony">
      <div className="reviewCopy">
        <div className="reviewCopyContents">
          {reviewText && <p>{reviewText}</p>}
          {reviewContent && reviewContent}
        </div>
      </div>

      <div className="testimonyDetails">
        <div className="readerDetails">
          <div className="testimonyThumbnail">
            <figure>
              <img src={replaceWpDomainWithFastlyDomain(thumbnail)} alt={name} loading="lazy" />
            </figure>
          </div>
          <div className="testimonyRating">
            <UpRating rating={rating} />
          </div>

          <h5>{name}</h5>
          {designation && <span>{designation}</span>}
        </div>
      </div>
    </div>
  );
};

export default Testimony;
