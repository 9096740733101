import { getStyleObjectFromString, isElement } from '..';
import { MOBILE_COL_MAX_WIDTH, MOBILE_FIRST_COL_MAX_WIDTH } from '../../constants';
import { IComputeTableCellMetrics } from '../../models/IComputeTableCellMetrics/IComputeTableCellMetrics';

export const computeTableCellMetrics = ({
  tableOptions,
  currentCellWidth,
  id,
  isFirstColumnCell,
  cellStyleString,
  parentElement,
  widthForCellWithNoWidth,
}: IComputeTableCellMetrics) => {
  const {
    firstColumnTableCellsAffectedByRowSpan = [],
    originalTableWidth,
    targetTableWidth,
    shouldUpdateWidth,
    tableHasTwoColumns,
    numberOfCellsWithNoWidths = 0,
  } = tableOptions;
  let newCellWidth = currentCellWidth;
  const cellStyle = cellStyleString ? getStyleObjectFromString(cellStyleString) : {};
  let maxCellWidth = 0;
  let allColumnsEquals = false;
  let hasFirstColumnSiblingAffectedByRowSpan = false;
  parentElement?.children.forEach((td, cellIndex) => {
    if (isElement(td)) {
      const cellId = td.attribs['id'] || '';
      const styleString = td.attribs['style'];
      const widthMatch = styleString?.match(/width\s*:\s*(\d+(?:\.\d+)?)px/);
      let cellWidth = 0;
      if (firstColumnTableCellsAffectedByRowSpan.includes(cellId)) {
        hasFirstColumnSiblingAffectedByRowSpan = true;
      }
      if (widthMatch && widthMatch[1]) {
        cellWidth += parseFloat(widthMatch[1]);
        if (cellIndex !== 0) {
          allColumnsEquals = cellWidth === maxCellWidth;
        }
        if (cellWidth > maxCellWidth) {
          maxCellWidth = cellWidth;
        }
      }
    }
  });

  if (hasFirstColumnSiblingAffectedByRowSpan) {
    // Removes the width value, allowing the row with rowspan to determine the table's layout
    cellStyle['width'] = 'unset';
  } else {
    if (shouldUpdateWidth) {
      if (numberOfCellsWithNoWidths === 0) {
        newCellWidth = (currentCellWidth / originalTableWidth) * targetTableWidth;
      }
      cellStyle['width'] = `${newCellWidth}px`;
    }

    if (widthForCellWithNoWidth > maxCellWidth) {
      maxCellWidth = widthForCellWithNoWidth;
    }

    if (tableHasTwoColumns) {
      const minCellWidth = shouldUpdateWidth
        ? (newCellWidth / targetTableWidth) * 100
        : (currentCellWidth / originalTableWidth) * 100;
      cellStyle['minWidth'] = `${minCellWidth}%`;
    } else {
      let minCellWidth = currentCellWidth;
      if (maxCellWidth > MOBILE_COL_MAX_WIDTH) {
        minCellWidth = (MOBILE_COL_MAX_WIDTH / maxCellWidth) * currentCellWidth;
      }
      if ((isFirstColumnCell && minCellWidth > MOBILE_FIRST_COL_MAX_WIDTH) || allColumnsEquals) {
        minCellWidth = MOBILE_FIRST_COL_MAX_WIDTH;
      }
      cellStyle['minWidth'] = `${minCellWidth}px`;
    }
  }

  let cellClassName: string | undefined;
  if (isFirstColumnCell && id && firstColumnTableCellsAffectedByRowSpan.includes(id)) {
    cellClassName = 'no-sticky';
  }

  return {
    cellClassName,
    cellStyle,
  };
};
