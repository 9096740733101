import React, { FC } from 'react';
import { IPressBlock } from './PressBlock.def';

const PressBlock: FC<IPressBlock> = ({ pressDate, pressTitle, publicationName, link }) => {
  return (
    <div className="pressBlock">
      <figure className="pressThumbnail">{publicationName}</figure>

      <a href={link} rel="nofollow noopener" target="_blank" className="pressContent">
        <time>{pressDate}</time>
        <p>{pressTitle}</p>
      </a>
    </div>
  );
};

export default PressBlock;
