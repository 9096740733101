import React, { FC } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'gatsby';
import { IAsideMeta } from './FullFeatured.def';
import BreadCrumbs from '../base/BreadCrumbs';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const AsideMeta: FC<IAsideMeta> = ({
  headline,
  mainTitle,
  author = {},
  authorLink,
  lastUpdated,
  datePublished = null,
  linkClick = () => {},
  breadCrumbs = [],
}) => {
  /**
   * This adjustment is required for those cases where the modified date is lower than the publish
   * date. This happens when a post has been published from a schedule.
   */
  let updatedDate = lastUpdated;
  if (datePublished && lastUpdated) {
    updatedDate = new Date(lastUpdated) > new Date(datePublished) ? lastUpdated : datePublished;
  }

  return (
    <div className="aside">
      <div>
        {breadCrumbs.length > 0 && <BreadCrumbs breadCrumbs={breadCrumbs} />}
        {headline && <p className="eyebrow">{headline}</p>}
        <h1 className="text-3xl md:text-5xl">{mainTitle}</h1>
        <div className="postMeta">
          {author.name && authorLink && (
            <>By 
              <Link to={authorLink} onClick={() => linkClick(authorLink)}>
                {author.thumbnail && (
                  <figure>
                    <img src={author.thumbnail.src} alt={author.thumbnail.alt} loading="lazy" />
                  </figure>
                )}
                <span>{author.name}</span>
              </Link>
            </>
          )}
          {lastUpdated && (
            <span className="postDate">
              Updated:{' '}
              <meta
                // @ts-ignore
                itemprop="datePublished"
                content={dayjs
                  .utc(datePublished ? datePublished : lastUpdated)
                  .tz('America/Chicago')
                  .format('YYYY-MM-DDTHH:mm:ssZ')}
              />
              <time
                // @ts-ignore
                itemprop="dateModified"
                className="date updated"
                datetime={dayjs
                  .utc(updatedDate)
                  .tz('America/Chicago')
                  .format('YYYY-MM-DDTHH:mm:ssZ')}
              >
                {dayjs.utc(updatedDate).tz('America/Chicago').format('MMMM D, YYYY, h:mma z')}
              </time>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AsideMeta;
