import { graphql, useStaticQuery, } from 'gatsby';

const getTopPartnerOffers = () => {
  const { wpUpOption } = useStaticQuery(graphql`
    query {
      wpUpOption(slug: { eq: "top-partner-offers" }) {
        topPartnerOffers {
          modalMessage
          upgpTopPartnerOffers {
            dealCardName
          }
        }
      }
    }
  `);

  return wpUpOption as Queries.WpUpOption;
};

export default getTopPartnerOffers;
