import {
  DEFAULT_EXPIRY_PERIOD,
  INVALID_VALUE,
  NO_LOCAL_STORAGE,
  RECORD_EXPIRED,
} from '../../constants';
import { NO_DOM } from '@throttleup/esi-components';

// Return true if Local Storage is available in the browser, false otherwise. Assumes the existence of 'window'
export const checkLS = () => {
  try {
    if (typeof window.localStorage === 'undefined') return false;
    const test = 'test';
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

// Return an object used for getting and setting Local Storage. The object should contain the following parameters:
// value: the passed in value.
// expiryDate: a date object representation of the passed-in expiryPeriod in milliseconds.
export const getExpiryRecord = (value: string | number, expiryPeriod: number) => {
  var expiryDate = new Date().getTime() + expiryPeriod;
  return {
    value: value,
    expiryDate: expiryDate,
  };
};

// Set the passed in value in Local Storage at the specified key.
// If the passed in value is empty and a default value is specified,
// set LS to the passed in defaultVal (only if there is already no value at the specified key).
export const setValueInLS = (
  key: string,
  val: string | number | null,
  expiryPeriod: number,
  defaultVal: any | null = null
) => {
  if (typeof window === 'undefined') return;
  if (checkLS()) {
    if (val) {
      const valueRecord = getExpiryRecord(val, expiryPeriod);
      window.localStorage.setItem(key, JSON.stringify(valueRecord));
    } else {
      const valueFromLS = window.localStorage.getItem(key);
      if (!valueFromLS && defaultVal) {
        const valueRecord = getExpiryRecord(defaultVal, expiryPeriod);
        window.localStorage.setItem(key, JSON.stringify(valueRecord));
      }
    }
  }
};

// Return an object containing the value from Local Storage and the expired value (if expired) for the specified key.
// Set the value in Local Storage to the passed in default if the key does not exist.
// If the key does not exist and there is no value in Local Storage, return false
export const getValueFromLSWithExpiredValue = (
  key: string,
  defaultVal: string | number | null = null,
  expiryPeriod: number = DEFAULT_EXPIRY_PERIOD
) => {
  let retValueObj: { value: string | number | boolean; expiredValue: string | boolean } = {
    value: defaultVal === null ? false : defaultVal,
    expiredValue: false,
  };
  if (typeof window === 'undefined') {
    retValueObj.value = NO_DOM;
    return retValueObj;
  }
  const isLSAvailable = checkLS();
  if (!isLSAvailable) {
    // No local storage.
    retValueObj.value = NO_LOCAL_STORAGE;
    return retValueObj;
  }
  const valueFromLS = window.localStorage.getItem(key);

  // If the key does not exist in local storage
  if (!valueFromLS) {
    if (defaultVal || defaultVal === 0) {
      //value does not exist yet in local storage. Set it to the passed in default
      const valueRecord = getExpiryRecord(defaultVal, expiryPeriod);
      window.localStorage.setItem(key, JSON.stringify(valueRecord));
    } else {
      retValueObj.value = false;
    }
    return retValueObj;
  }

  // A value exists in local storage for the key.
  const valueObj = JSON.parse(valueFromLS);
  // Check if it's valid.
  if (!valueObj) {
    // value is empty or null.
    retValueObj.value = INVALID_VALUE;
    return retValueObj;
  }
  // Check for expiry.
  if (new Date().getTime() < valueObj.expiryDate) {
    retValueObj.value = valueObj.value;
  } else {
    //value in local stroage expired.
    retValueObj.value = RECORD_EXPIRED;
    retValueObj.expiredValue = valueObj.value;
  }
  return retValueObj;
};

// wrapper function for getValueFromLSWithExpiredValue to return just the value.
export const getValueFromLS = (
  key: string,
  defaultVal: string | null = null,
  expiryPeriod?: number
) => {
  return getValueFromLSWithExpiredValue(key, defaultVal, expiryPeriod).value;
};

// Function to validate if the local storage is allowed in the current session.
// Local Storage can be disabled if the client disables first-party cookies.
export const isLocalStorageAllowed = () => {
  try {
    return typeof window !== 'undefined' && typeof window?.localStorage !== 'undefined';
  }
  catch (e) {
    console.error('Access to Local Storage is not allowed.', e);
    return false;
  }
}
