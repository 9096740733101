import React, { useState, ReactNode } from 'react';
import ModalContext from '../context/ModalContext';
import ValuationModal from '../components/ValuationModal';

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
      {isModalOpen && <ValuationModal />}
    </ModalContext.Provider>
  );
};