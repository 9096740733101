import React, { FC } from 'react';
import { ITeamMember } from './TeamMember.def';
import { Link } from 'gatsby';
import { getSocialIcon } from '../../../../utils/getSocialIcon';

const TeamMember: FC<ITeamMember> = ({
  description,
  designation,
  fullName,
  thumbnail,
  moreLink,
  socials = [],
  isAuthorHeader,
  topicOfExpertise,
  articlesPublished,
  articlesEdited,
  isAboutMeetTeam = false,
  isInScroller = false,
  extraAuthorDetails,
}) => {
  function createMarkup(content: string) {
    return { __html: content };
  }
  const rootClassName = isInScroller ? 'cardBlock' : 'newsBlock';
  let heightAndWidth;
  if (thumbnail.src && typeof thumbnail.src === 'string' && thumbnail.src.includes('s=')) {
    const url = new URL(thumbnail.src);
    const s = url.searchParams.get('s');
    if (s) {
      heightAndWidth = {
        width: `${s}px`,
        height: `${s}px`,
      };

      if (isAuthorHeader) {
        heightAndWidth = {
          width: `${parseInt(s) / 2}px`,
          height: `${parseInt(s) / 2}px`,
        };
      }
    }
  }
  return (
    <>
      {isAboutMeetTeam ? (
        <div className="team-member">
          <header className="team-member__header">
            <figure style={heightAndWidth} className="team-member__thumbnail">
              <img {...heightAndWidth} src={thumbnail.src} alt={thumbnail.alt} loading="lazy" />
            </figure>
            <div className="team-member__content">
              <h2>
                <Link to={moreLink?.link || '#'}>{fullName}</Link>
              </h2>
              <p className="designation" dangerouslySetInnerHTML={createMarkup(designation)}></p>
            </div>
          </header>
          <div className="team-member__bio">
            {description}
            <p>
              <strong>Countries Visited: </strong> {extraAuthorDetails.countriesVisited} <br />
              <strong>U.S. States Visited: </strong> {extraAuthorDetails.usStatesVisited} <br />
              {extraAuthorDetails.highestHotelStatus && (
                <>
                  <strong>Highest Hotel Status: </strong> {extraAuthorDetails.highestHotelStatus}{' '}
                  <br />
                </>
              )}
              {extraAuthorDetails.highestAirlineStatus && (
                <>
                  <strong>Highest Airline Status: </strong>
                  {extraAuthorDetails.highestAirlineStatus}
                  <br />
                </>
              )}
              {extraAuthorDetails.favoriteAirlineAlliance && (
                <>
                  <strong>Favorite Airline Alliance: </strong>
                  {extraAuthorDetails.favoriteAirlineAlliance}
                  <br />
                </>
              )}
              {extraAuthorDetails.favoriteFirstClass && (
                <>
                  <strong>Favorite First Class: </strong> {extraAuthorDetails.favoriteFirstClass}{' '}
                  <br />
                </>
              )}
              {extraAuthorDetails.favoriteBusinessClass && (
                <>
                  <strong>Favorite Business Class: </strong>{' '}
                  {extraAuthorDetails.favoriteBusinessClass}
                  <br />
                </>
              )}
              {extraAuthorDetails.bestRedemptionValue && (
                <>
                  <strong>Best Redemption Value: </strong> {extraAuthorDetails.bestRedemptionValue}
                  <br />
                </>
              )}
              {extraAuthorDetails.favoriteTypeOfPoints && (
                <>
                  <strong>Favorite Type of Points: </strong>{' '}
                  {extraAuthorDetails.favoriteTypeOfPoints}
                  <br />
                </>
              )}
              {extraAuthorDetails.topThreeCardsInWallet && (
                <>
                  <strong>Top 3 Cards in Wallet: </strong>{' '}
                  {extraAuthorDetails.topThreeCardsInWallet}
                  <br />
                </>
              )}
            </p>
          </div>
        </div>
      ) : (
        <article className={rootClassName}>
          <header className={`aboutHeaderInfo ${isAuthorHeader ? 'aboutHeaderInfoAuthorHeader' : ''}`}>
            <div className={`aboutProfile ${isAuthorHeader ? 'aboutProfileAuthorHeader' : ''}`}>
              <figure
                style={heightAndWidth}
                className={`${isAuthorHeader ? 'isAuthorHeader' : ''}`}
              >
                <img {...heightAndWidth} src={thumbnail.src} alt={thumbnail.alt} loading="lazy" />
              </figure>
            </div>
            <div className={`aboutHeaderInfoContent ${isAuthorHeader ? 'singleAuthorPage' : ''}`}>
              <div className='aboutTitleAndDesignation'>
                {isAuthorHeader ? <h1>{fullName}</h1> : <h2>{fullName}</h2>}
                <p className="designation" dangerouslySetInnerHTML={createMarkup(designation)}></p>
              </div>
              <p className="articlesPublished">
                {articlesPublished && <span>{articlesPublished}</span>}
                {articlesEdited && articlesPublished && <span className="text-spacer"> , </span>}
                {articlesEdited && <span>{articlesEdited}</span>}
              </p>
              {topicOfExpertise && topicOfExpertise?.link && (
                <Link
                  to={topicOfExpertise?.link}
                  className={`${topicOfExpertise?.text
                    ?.toLowerCase()
                    .replaceAll(' ', '-')} topicOfExpertise`}
                >
                  {topicOfExpertise?.text}
                </Link>
              )}
              {socials.length > 0 && isAuthorHeader && (
                <ul className="social">
                  {socials.map((social) => {
                    const { link, name } = social;
                    return (
                      <li key={name}>
                        <a href={link} target="__blank" rel="noopener">
                          <img src={getSocialIcon(name)} alt={`${name} icon`} />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </header>
          {!isAuthorHeader && (
            <div className="newsBlockContent">
              <div className="teamBlockDescription">{description}</div>
              {moreLink && (
                <a href={moreLink.link} className="btn-standard btn-small text-xs">
                  {moreLink.label}
                </a>
              )}
              {socials.length > 0 && (
                <footer>
                  <ul className="social">
                    {socials.map((social) => {
                      const { link, name } = social;
                      return (
                        <li key={name}>
                          <a href={link} target="__blank" rel="noopener">
                            <img src={getSocialIcon(name, 'blue')} alt={`${name} icon`} />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </footer>
              )}
            </div>
          )}
        </article>
      )}
    </>
  );
};

export default TeamMember;
