import React, { FC } from 'react';
import { IHowWeWork } from './HowWeWork.def';

const HowWeWork: FC<IHowWeWork> = ({ title, points }) => {
  return (
    <section className="howWeWork container">
      <h2>{title}</h2>
      {points.map(({ content, title }) => {
        return (
          <div key={title}>
            <h4>{title}</h4>
            {content}
          </div>
        );
      })}
    </section>
  );
};

export default HowWeWork;
