import React, { FC } from 'react';
import { IContactCard } from './ContactCard.def';
import facebookIcon from '../../../images/social-facebook-blue.svg';
import twitterIcon from '../../../images/social-twitter-blue.svg';
import instagramIcon from '../../../images/social-instagram-blue.svg';
import emailIcon from '../../../images/social-email-blue.svg';

const ContactCard: FC<IContactCard> = ({
  title,
  thumbnail,
  children,
  alt,
  variant,
  showSocialIcons,
}) => {
  return (
    <div className={`contactBlock ${variant ? 'bg-lighterBlue' : ''}`}>
      {thumbnail && alt && (
        <figure>
          <img src={thumbnail} alt={alt} loading="lazy" />
        </figure>
      )}
      <h3 className={`${showSocialIcons ? 'other-ways' : ''}`}>{title}</h3>
      {showSocialIcons && (
        <div className="social-wrapper">
          <ul className="social">
            <li>
              <a href="#">
                <img src={facebookIcon} alt="Facebook icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src={twitterIcon} alt="Twitter icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src={instagramIcon} alt="Instagram icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src={emailIcon} alt="Email icon" />
              </a>
            </li>
          </ul>
        </div>
      )}
      {children}
    </div>
  );
};

export default ContactCard;
