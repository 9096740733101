import { ReactElement, useEffect } from 'react';

interface IDOMEffectWrapper {
  isMobile: boolean;
  pageContext: any;
  children: ReactElement;
}

const DOMEffectWrapper = ({ isMobile, pageContext, children }: IDOMEffectWrapper) => {
  useEffect(() => {
    /*
    The code below will show the scroll notice on mobile devices if the table with is greater than the device width.
    */
    const tables = document.querySelectorAll('table') || [];
    tables.forEach((table) => {
      const tbody = table.querySelector('tbody');
      let scrollNotice;
      if (table.previousElementSibling?.textContent === 'SCROLL FOR MORE') {
        scrollNotice = table.previousElementSibling;
      } else if (table.parentElement?.previousElementSibling?.textContent === 'SCROLL FOR MORE') {
        scrollNotice = table.parentElement.previousElementSibling;
      }
      if (tbody && scrollNotice) {
        if (
          (isMobile && tbody.offsetWidth > window.innerWidth) ||
          (!isMobile && tbody.offsetWidth > (table.parentElement?.offsetWidth || 900))
        ) {
          scrollNotice.classList.add('show');
        }
      }
    });
  }, [isMobile]);

  useEffect(() => {
    const valuationModal = window.document.getElementsByClassName('valuation-modal-container');
    if (valuationModal && valuationModal[0]) {
      (valuationModal[0] as HTMLElement).style.display = 'none';
    }
  }, [pageContext]);

  return children;
};

export default DOMEffectWrapper;
