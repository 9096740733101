import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, concat } from '@apollo/client';

const authMiddleware = new ApolloLink((operation, forward) => {
  if (typeof window?.localStorage === 'undefined') {
    return forward(operation);
  }
  
  // add the authorization to the headers
  const token = window.localStorage['token'];
  const path = window.location.pathname;
  if (path === '/preview/' && token) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    }));
  }

  const userToken = window.localStorage.getItem('user_token');
  if (userToken) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${userToken}`,
      },
    }));
  }

  return forward(operation);
});

const httpLink = new HttpLink({ uri: `${process.env['GATSBY_WORDPRESS_API_URL']}`, fetch });

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export default client;
