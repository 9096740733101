import React, { FC } from 'react';
import HTMLContentESIWrapper from '../../wrappers/HTMLContentESIWrapper';
import { WO_AB_TEST_NAME } from '../../constants';
import {
  ICardApiShortcode,
  ICardData,
} from '@throttleup/esi-components/lib/models/ICardData/index.def';
import { WO_AB_TEST_BUCKET_ALLOCATION_NONE } from '@throttleup/esi-components';

interface IShortcodeESI {
  src: string;
  goLinkPlacing: string;
}

const ShortcodeESI: FC<IShortcodeESI> = ({ src, goLinkPlacing = '/TBL/' }) => {
  const { searchParams } = new URL(`https://example.com${src}`);
  const cardId = parseInt(searchParams.get('card_id') || '0');
  const name = searchParams.get('name');
  const containerId = `shortcode_${cardId}_${name}`;
  const abTests = [WO_AB_TEST_NAME];

  const renderFunction = (card: ICardData, woTestBucket: string) => {
    const shortcodes = card?.shortcodes;
    if (typeof shortcodes?.length === 'undefined') {
      return '';
    }

    let shortcode = shortcodes.find((shortcode: ICardApiShortcode) => shortcode.name === name);
    // If assigned to a bucket in the Welcome Offer AB Test, search for the name with the correct
    // variant of the shortcode based on the value of woTestBucket. If the variant is not found
    // assume that the shortcodes is not being tested and render the original one.
    if (woTestBucket && woTestBucket !== WO_AB_TEST_BUCKET_ALLOCATION_NONE) {
      const woTestShortcode = shortcodes.find(
        (shortcode: ICardApiShortcode) =>
          shortcode.name === name + '-' + WO_AB_TEST_NAME + '-bucket-' + woTestBucket,
      );
      if (woTestShortcode) {
        shortcode = woTestShortcode;
      }
    }

    return shortcode?.renderedContent || '';
  };

  return (
    <HTMLContentESIWrapper
      cardId={cardId}
      containerId={containerId}
      src={src}
      fallbackRenderFunction={renderFunction}
      extraParams={[]}
      goLinkPlacing={goLinkPlacing}
      tagName="span"
      abTests={abTests}
    />
  );
};

export default ShortcodeESI;
