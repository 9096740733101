import React from 'react';
import { createSourceElements, createSrc, createSrcSet } from '..';
import {
  LARGE_TAB_MIN_WIDTH_MQ,
  MEDIUM_TAB_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
} from '../../constants';
import { getStyleObjectFromString } from '..';

export const renderImage = (attribs: { [name: string]: string }, useSourceElements: boolean = true) => {
  const {
    'data-src': dataSrc,
    'data-srcset': dataSrcSet = '',
    'data-lazy-src': dataLazySrc,
    'data-lazy-srcset': dataLazySrcSet,
    src: placeholderSrc,
    class: className,
    sizes,
    height,
    width,
    ...rest
  } = attribs;

  return createInArticleImage(dataSrc || '', rest, width || '', height || '', className || '', useSourceElements);
};

export const createInArticleImage = (
  dataSrc: string,
  { style, ...rest }: Record<string, string>,
  width: string,
  height: string,
  className: string,
  useSourceElements: boolean
) => {
  const imgStyle = style ? getStyleObjectFromString(style) : {};

  // This code will check if the src url contains any width and height if it does then they will be removed from the src url
  const src = /-\d*x\d*\./.test(dataSrc) ? dataSrc.replace(/-\d*x\d*\./, '.') : dataSrc;

  const sourceElements = createSourceElements([
    {
      media: LARGE_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 900,
            fit: 'cover',
          },
        },
      ]),
    },
    {
      media: MEDIUM_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 740,
            fit: 'cover',
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 620,
            fit: 'cover',
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);
  return (
    <picture>
      {useSourceElements && sourceElements}
      <img
        className={className}
        src={useSourceElements ? createSrc(src) : dataSrc}
        style={imgStyle}
        {...rest}
        width={width}
        height={height}
        loading="lazy"
      />
    </picture>
  );
};
