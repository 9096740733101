import React, { useContext } from 'react';
import CardDataContext from '../../context/CardDataContext';
import { ICardData } from '@throttleup/esi-components';
import { IWeRecommend } from '../../components/WeRecommend/WeRecommend.def';
import { processApiComplianceValues, defaultApiComplianceObject } from '@throttleup/esi-components';
import { getDefaultCardArt, processImageApiComplianceValue } from '..';

export const getSpecialOffer = (specialOfferCardId = 0): IWeRecommend | null => {
  const { cardDataJson } = useContext(CardDataContext);
  const card = cardDataJson?.data?.find(
    (card: ICardData) => parseInt(`${card?.cardId}`) === parseInt(`${specialOfferCardId}`)
  );

  if (card) {
    const title = processApiComplianceValues(card.upgpCardName || defaultApiComplianceObject) || '';
    return {
      benefits: processApiComplianceValues(card.upgpWelcomeInfo || defaultApiComplianceObject),
      link: card.affiliateLinkReviewPage || '#',
      thumbnail: card.upgpImage?.value
        ? {
            src: processImageApiComplianceValue(card.upgpImage || defaultApiComplianceObject) || '',
            alt: title,
          }
        : getDefaultCardArt(title),
      title,
      welcomeOfferText: card.welcomeOfferAmountCardTables || null,
      ratesAndFees: {
        link: card.ratesFeesLink,
        text: 'Terms Apply / Rates & Fees',
      },
      addTrackingSourceType: card.upgpCardReviewAddTrackingSourceType || false,
    };
  }

  return null;
};

export const renderError = (message: string) => <span className="upgpContentError">{message}</span>;
