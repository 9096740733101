import React, { FC } from 'react';
import { renderWelcomeOfferTooltip } from '@throttleup/esi-components';
import HTMLContentESIWrapper from '../../wrappers/HTMLContentESIWrapper';

interface IWelcomeOfferTooltipESI {
  src: string;
}

const WelcomeOfferTooltipESI: FC<IWelcomeOfferTooltipESI> = ({ src }) => {
  const { searchParams } = new URL(`https://example.com${src}`);
  const cardId = parseInt(searchParams.get('card_id') || '0');
  const parentId = parseInt(searchParams.get('parent_id') || '0');
  const containerId = `welcome_offer_tooltip_${cardId}`;
  const extraParams = [parentId];

  return (
    <HTMLContentESIWrapper
      cardId={cardId}
      containerId={containerId}
      src={src}
      fallbackRenderFunction={renderWelcomeOfferTooltip}
      extraParams={extraParams}
    />
  );
};

export default WelcomeOfferTooltipESI;
