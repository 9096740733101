import React, { FC, useEffect, useContext, useState } from 'react';
import CardDataContext from '../../context/CardDataContext';
import { ITopSlider, PostContext, SingleTopSlider, TopSliderObject } from './TopSlider.def';
import { getAllTopSliders } from '../../utils/static-queries';
import CardSticky from '../../components/CardSticky/CardSticky';
import { ICardApiComplianceData } from '../../context/CardDataContext.def';
import { processImageApiComplianceValue } from '../../utils';
import { getDefaultCardArt } from '../../utils';
import { isUrlInList } from '../../utils';
import { SCROLL_SPACE_TOP_SLIDER, TEMPLATE_NAMES } from '../../constants';
import {
  getTerms,
  getGoLink,
  getWelcomeOfferAmountForCardTable,
  getWelcomeOfferExplanationForCardTable,
  DEFAULT_CTA_LANGUAGE,
} from '@throttleup/esi-components';
import { IImgProps } from '../../models/IImgProps';

/**
 * Searches for a matching 'TopSlider' based on the current post context.
 * The function checks various conditions such as URL, category, tags, and specific posts
 * to find a match in the provided 'topSliderObject'. Additionally, it hides a 'TopSlider'
 * if any URL specified in the 'hideOn' field matches the post URL.
 *
 * @param {TopSliderObject} topSliderObject - The object containing all available top sliders.
 * @param {PostContext} postContext - The context of the current post.
 * @returns {SingleTopSlider | null} A matching top slider or null if no match is found, or if the top slider should be hidden.
 */

export const findMatchingTopSlider = (
  topSliderObject: TopSliderObject,
  postContext: PostContext,
): SingleTopSlider | null => {
  // When handling a "CreditCardReview" post, we return immediately with a special object.
  // This approach is taken because credit card reviews have unique display requirements
  // The object returned includes 'dealCardName', directly linked to the post's unique ID
  // for straightforward identification, and an empty 'displayOn' array, indicating
  // no additional display conditions are needed for these types of posts.
  if (
    postContext &&
    ['CreditCardReview', 'Credit Card Review'].includes(postContext?.templateName)
  ) {
    return { dealCardName: postContext?.databaseId, displayOn: [] };
  }

  if (!topSliderObject || !postContext || !Array.isArray(topSliderObject.upgpCcReviewTopSliders)) {
    return null;
  }

  for (const topSlider of topSliderObject?.upgpCcReviewTopSliders) {
    // Continue if any hideURL matches the post URL or displayTopSlider is set false.
    if (
      (topSlider?.hideOn && postContext.link && isUrlInList(topSlider.hideOn, postContext.link)) ||
      !topSlider.displayTopSlider
    ) {
      continue;
    }

    for (const condition of topSlider?.displayOn) {
      // Check for URL match
      if (
        condition.displayPagesUrl &&
        postContext.link &&
        isUrlInList(condition.displayPagesUrl, postContext.link)
      ) {
        return topSlider;
      }

      // Check for category match.
      if (condition.displayCategories) {
        const categorySlugs = postContext.categories?.nodes?.map((node) => node?.slug);
        for (const category of condition.displayCategories) {
          if (categorySlugs?.includes(category?.slug)) {
            return topSlider;
          }
        }
      }

      // Check for tag match.
      if (condition.displayTags) {
        const postTags = postContext?.tags?.nodes?.map((node) => node?.slug);
        for (const tag of condition.displayTags) {
          if (postTags?.includes(tag?.slug)) {
            return topSlider;
          }
        }
      }

      // Check for specific posts match.
      if (condition.displayPosts) {
        const postIds = condition.displayPosts.map((post) => post?.databaseId);
        if (postIds?.includes(postContext?.databaseId)) {
          return topSlider;
        }
      }
    }
  }

  return null;
};

const TopSlider: FC<ITopSlider> = ({ context }) => {
  // Using context to get card data.
  const { cardDataJson } = useContext(CardDataContext);
  const { creditCardReviewTopSlider } = getAllTopSliders();

  // Finding the matching top slider for the current context.
  const topSliderReview = findMatchingTopSlider(
    creditCardReviewTopSlider as TopSliderObject,
    context,
  );
  if (!topSliderReview) return null;

  // Parsing and validating the card ID.
  const sliderCardId =
    typeof topSliderReview?.dealCardName === 'string'
      ? parseInt(topSliderReview.dealCardName) || 0
      : topSliderReview?.dealCardName || 0;
  const cardData = context.isPreview
    ? context.cardDataJsonPreview && JSON.parse(context.cardDataJsonPreview)
    : cardDataJson?.data?.find((card) => card.cardId === sliderCardId) || null;

  // Defaults.
  const defaultApiComplianceObject = {
    value: '',
    description: '',
    intro: '',
    introDescription: '',
  };

  // Prepare compliance data from cardData.
  const upgpCardName = cardData?.upgpCardName || defaultApiComplianceObject;
  const upgpImage = cardData?.upgpImage || defaultApiComplianceObject;
  const goLink = getGoLink(cardData);

  // Helper functions.
  const processApiComplianceValues = ({ value }: ICardApiComplianceData) =>
    value && typeof value === 'object' ? value?.toString() : value;

  // Process the image data for API compliance, and if unavailable, get a default card art based on the card title.
  const cardTitle = processApiComplianceValues(upgpCardName) || '';
  const cardArt = processImageApiComplianceValue(upgpImage);
  const image: IImgProps = cardArt
    ? { alt: cardTitle, src: cardArt }
    : getDefaultCardArt(cardTitle);

  // Determine if the source tracking type needs to be added to the card data.
  const needToAddSource = cardData?.upgpCardReviewAddTrackingSourceType || false;

  // State to control the visibility of the sticky card element.
  const [isStickyActive, setIsStickyActive] = useState(true);

  const templateName = context?.templateName || '';
  // Effect hook to manage sticky card behavior on scroll events, applicable only on non-mobile devices.
  useEffect(() => {
    const handleScroll = () => {
      const cardStickyElm = document.querySelector('.cardSticky');
      const ccButtonElm = document.querySelector('.contentAdvertiserDisclosure')?.firstElementChild;
      if (cardStickyElm && ccButtonElm) {
        const ccButton = ccButtonElm.getBoundingClientRect();
        if (ccButton.bottom <= 0 && !cardStickyElm.classList.contains('stick-to-bottom')) {
          cardStickyElm.classList.add('stick-to-bottom');
        }
        if (ccButton.bottom >= 0 && cardStickyElm.classList.contains('stick-to-bottom')) {
          cardStickyElm.classList.remove('stick-to-bottom');
        }
      }
      if (templateName === TEMPLATE_NAMES.FOOTER_AND_HEADER_ONLY) {
        cardStickyElm?.classList[window.scrollY > SCROLL_SPACE_TOP_SLIDER ? 'add' : 'remove'](
          'stick-to-bottom',
        );
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [templateName]);

  // Function to toggle the visibility of the sticky card.
  const handleIsStickyActive = () => {
    setIsStickyActive(!isStickyActive);
  };

  const cardBadges = {
    redBadgeText: cardData?.redBadgeCardTables,
    orangeBadgeText: cardData?.orangeBadgeCardTables,
  };

  const terms = getTerms(cardData);

  return (
    <div className="credit-card-top-slider">
      {goLink && isStickyActive && (
        <CardSticky
          title={cardTitle}
          tooltipText={getWelcomeOfferExplanationForCardTable(cardData) || ''}
          cardImage={image}
          ctaButton={{
            text: cardData?.ctaLanguage || DEFAULT_CTA_LANGUAGE,
            link: goLink,
          }}
          feature={{
            subTitle: getWelcomeOfferAmountForCardTable(cardData) || '',
            title: 'Welcome Offer',
          }}
          neeedToAddSource={needToAddSource}
          handleIsStickyActive={handleIsStickyActive}
          disclaimer={cardData?.disclosureTextNextToButton || ''}
          badges={cardBadges}
          terms={terms}
        />
      )}
    </div>
  );
};

export default TopSlider;
