import { getPlaceholderGatsbyImg } from '..';
import { IFaq } from '../../components/Faqs/components/Faq/Faq.def';
import { IRelatedPost } from '../../components/RelatedPosts/components/RelatedPost/RelatedPost.def';

export const getFaqs = (
  faqs?: Queries.Maybe<
    readonly Queries.Maybe<Queries.WpPost_Fieldsforposts_UpgpPostFaqs_UpgpPostFaq>[]
  >
): IFaq[] => {
  if (faqs) {
    return faqs.map((faq) => ({
      answer: faq?.upgpPostAnswer || '',
      question: faq?.upgpPostQuestion || '',
    }));
  }
  return [];
};

export const getRelatedPosts = (
  relatedPosts?: Queries.Maybe<
    readonly Queries.Maybe<Queries.WpPost_Fieldsforposts_upgpPostRelatedPosts>[]
  >
): IRelatedPost[] => {
  if (relatedPosts) {
    return relatedPosts
      .filter(
        (relatedPost) =>
          relatedPost?.upgpGbPostRelatedPostsPostPage &&
          relatedPost?.upgpGbPostRelatedPostsPostPage?.uri &&
          relatedPost?.upgpGbPostRelatedPostsPostPage?.title &&
          relatedPost?.upgpGbPostRelatedPostsPostPage?.featuredImage?.node?.sourceUrl
      )
      .map((relatedPost) => ({
        link: relatedPost?.upgpGbPostRelatedPostsPostPage?.uri || '#',
        thumbnail: {
          alt: relatedPost?.upgpGbPostRelatedPostsPostPage?.title || '',
          src:
            relatedPost?.upgpGbPostRelatedPostsPostPage?.featuredImage?.node?.sourceUrl ||
            getPlaceholderGatsbyImg(),
        },
        title: relatedPost?.upgpGbPostRelatedPostsPostPage?.title || '',
      }));
  }
  return [];
};
