import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { isElement } from '../utils';

import OverviewTestimonials from '../components/OverviewTestimonials/OverviewTestimonials';
import { ITestimony } from '../components/Testimony/Testimony.def';
import { IWpTestimonialsContent, IWpTestimony } from '../models/IWpTestimony';

const TestimonialsOverviewContainer = (props: any) => {
  const {
    upgp_gb_testimonials_overview_title: title,
    upgp_gb_testimonials_overview_more: moreLinkData,
  } = props;
  let testimonies: ITestimony[] = [];
  const testimonialsPageContent = useStaticQuery(graphql`
    query {
      wpPage(uri: { eq: "/testimonials/" }) {
        uri
        content
      }
    }
  `);
  let testimonialsParsedContent: IWpTestimonialsContent = { upgp_gb_testimonials: [] };
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (isElement(domNode)) {
        if (
          domNode.attribs['data-react-component'] &&
          domNode.attribs['data-react-component'] === 'Testimonials'
        ) {
          testimonialsParsedContent = JSON.parse(domNode?.attribs['data-react-props'] || '');
        }
      }
      return domNode;
    },
  };
  if (testimonialsPageContent?.wpPage?.content) {
    parse(testimonialsPageContent.wpPage.content, options);
  }
  const moreLink = moreLinkData && {
    text: moreLinkData.upgp_gb_testimonials_overview_more_text,
    link: moreLinkData.upgp_gb_testimonials_overview_more_link,
  };
  const blockHeader = { title, moreLink };
  const wpTestimonials = testimonialsParsedContent.upgp_gb_testimonials;
  if (wpTestimonials && wpTestimonials.length) {
    wpTestimonials.forEach((testimony: IWpTestimony) => {
      const {
        upgp_gb_testimonials_name: name,
        upgp_gb_testimonials_rating: rating,
        upgp_gb_testimonials_comment: reviewText,
        upgp_gb_testimonials_profile_picture: thumbnailUrl,
        upgp_gb_testimonials_show_other_pages: showOtherPages,
      } = testimony;
      //Skip any testimonials that have a rating of 0 or is not picked to be shown on other pages (Home, About )
      if (showOtherPages !== true) {
        return;
      }

      if (rating > 0 && typeof thumbnailUrl === 'string' && thumbnailUrl.length > 0) {
        //Use the 60x60 version of the thumbnail
        const urlSuffixIndex = thumbnailUrl.lastIndexOf('.');
        const thumbnail =
          thumbnailUrl.slice(0, urlSuffixIndex) + '-60x60' + thumbnailUrl.slice(urlSuffixIndex);
        testimonies.push({
          name,
          rating,
          reviewText,
          thumbnail,
        });
      }
    });
  }
  return <OverviewTestimonials blockHeader={blockHeader} testimonies={testimonies} />;
};

export default TestimonialsOverviewContainer;
