import { ICardData } from '@throttleup/esi-components';

/**
 * Generate a list of AB Test allocations for component-based tests based on a given list of test bucket names.
 * For example, if there is a cookie found for the test bucket, return the value from the cookie.
 * @param abTests - A list of test names to process for this component.
 * @param cardData - the card data JSON for the card whose component we are processing.
 * @returns [array] - an array of test allocations, or an empty array if no tests were processed
 *   or their cookies were not found.
 */
export const getABTestBucketAllocations = (abTests: string[], cardData: ICardData): string[] => {
  const abTestBucketAllocations: string[] = [];
  // No tests are currently active

  return abTestBucketAllocations;
};
