import React, { FC } from 'react';

interface ISeeMoreProps {
  childNodes: string | JSX.Element | JSX.Element[];
}

const SeeMore: FC<ISeeMoreProps> = ({ childNodes }) => {
  return (
    <details className="upgp-see-more">
      <summary className="upgp-see-more__summary-toggle">
        <span className="upgp-see-more__hide-summary">Hide Summary</span>
        <span className="upgp-see-more__show-summary">Show Summary</span>
      </summary>
      {childNodes}
    </details>
  );
};

export default SeeMore;
