import React, { FC } from 'react';
import { Link } from 'gatsby';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { IArticleExcerpt } from './Article.def';
import parser from 'html-react-parser';
import { processCopyright } from '../../../utils/helpers';
import { createSourceElements, createSrc, createSrcSet } from '../../../utils';
import {
  LARGE_TAB_MIN_WIDTH_MQ,
  MEDIUM_TAB_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_DESKTOP_MIN_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
} from '../../../constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const ArticleExcerpt: FC<IArticleExcerpt> = ({
  thumbnail,
  date,
  title,
  link,
  excerpt,
  moreLink = {
    text: 'Learn More',
  },
  category,
  linkClick = () => {},
  isFeaturedArticle = false,
  author,
}) => {
  let sourceElements = createSourceElements([
    {
      media: MEDIUM_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 500,
            height: 236,
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 450,
            height: 338,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail?.src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);

  if (isFeaturedArticle) {
    sourceElements = createSourceElements([
      {
        media: SMALL_DESKTOP_MIN_WIDTH_MQ,
        srcSet: createSrcSet(thumbnail?.src || '', [
          {
            options: {
              width: 1200,
            },
          },
        ]),
      },
      {
        media: LARGE_TAB_MIN_WIDTH_MQ,
        srcSet: createSrcSet(thumbnail?.src || '', [
          {
            options: {
              width: 1000,
            },
          },
        ]),
      },
      {
        media: MEDIUM_TAB_MIN_WIDTH_MQ,
        srcSet: createSrcSet(thumbnail?.src || '', [
          {
            options: {
              width: 740,
            },
          },
        ]),
      },
      {
        media: SMALL_TAB_MIN_WIDTH_MQ,
        srcSet: createSrcSet(thumbnail?.src || '', [
          {
            options: {
              width: 630,
            },
          },
        ]),
      },
      {
        media: MOBILE_MAX_WIDTH_MQ,
        srcSet: createSrcSet(thumbnail?.src || '', [
          {
            options: {
              width: 700,
            },
          },
        ]),
      },
    ]);
  }

  if (thumbnail) {
    thumbnail = {
      ...thumbnail,
      src: createSrc(thumbnail.src || ''),
    };
  }

  return (
    <article className="newsBlock">
      <figure>
        <Link to={link} onClick={() => linkClick(link)}>
          {thumbnail && (
            <picture>
              {sourceElements}
              <img {...thumbnail} loading="lazy" />
            </picture>
          )}
        </Link>
        {category && (
          <a href={category.link} className="category desktop">
            {category.text}
          </a>
        )}
      </figure>
      <div className="newsBlockContent">
        <header>
        <div className="blockPostMeta">
          {author?.link && (
            <div className="newsBlockContent-author">
              <span>By</span>
              <Link to={author.link}>{author.text}</Link>
              <span className="author-time-separator">-</span>
            </div>
          )}
          <div className="date-category-wrapper">
            {date && (
              <time>{dayjs.utc(date).tz('America/Chicago').format('MMMM D, YYYY, h:mma z')}</time>
            )}
            {category && (
              <a href={category.link} className="category mobile">
                {category.text}
              </a>
            )}
          </div>
        </div>
        <h3>
          <Link to={link} onClick={() => linkClick(link)}>
            {parser(processCopyright(title))}
          </Link>
        </h3>
        </header>
        {excerpt && <div className="excerpt-container">{parser(processCopyright(excerpt))}</div>}
        <footer>
        <Link to={link} className="btn-standard btn-small" onClick={() => linkClick(link)}>
          {moreLink.text}
        </Link>
        </footer>
      </div>
    </article>
  );
};

export default ArticleExcerpt;
