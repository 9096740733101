import React, { FC } from 'react';
import { Link } from 'gatsby';
import { IHubHeader } from './FeaturedHub.def';

const HubHeader: FC<IHubHeader> = ({
  title,
  subtitle,
  moreLink,
  links,
  linkClick = () => {},
  activeTab,
}) => (
  <div className="hubHeader">
    <div className="hubHeaderContent">
      <h2>{title}</h2>
      <p>{subtitle}</p>
      {links && (
        <>
          <nav className="hubNav">
            <ul>
              {links.map((link, index) => {
                const { text } = link;
                return (
                  <li key={index}>
                    <button
                      className={`${text === activeTab ? 'active' : ''}`}
                      onClick={() => {
                        linkClick(text);
                      }}
                    >
                      {text}
                    </button>
                  </li>
                );
              })}
            </ul>
          </nav>
          <div className="hubMobileWrapper">
            <i className="select-arrow"></i>
            <select
              className="hubMobileNav"
              onChange={(e) => {
                linkClick(e.target.value);
              }}
            >
              {links.map((link) => {
                const { text } = link;
                return (
                  <option value={text} key={text}>
                    {text}
                  </option>
                );
              })}
            </select>
          </div>
        </>
      )}
    </div>
    {moreLink && moreLink.link && (
      <Link to={moreLink.link} className="btn-standard">
        {moreLink.text}
      </Link>
    )}
  </div>
);

export default HubHeader;
