import React, { FC } from 'react';
import { IQuickFacts } from './FullFeatured.def';

const QuickFacts: FC<IQuickFacts> = ({ facts }) => {
  return (
    <>
      <div className="container quick-facts">
        {facts.slice(0, 5).map(({ label, children }, index) => (
          <div
            key={index}
            className={typeof label === 'string' ? label?.toLowerCase()?.replaceAll(' ', '-') : ''}
          >
            <label>{label}</label>
            <div>{children}</div>
          </div>
        ))}
      </div>
      {facts.length > 5 && (
        <details className="quick-facts__disclosure">
          <summary className="show-more-button">Show more</summary>
          <div className="container quick-facts">
            {facts.slice(5).map(({ label, children }, index) => (
              <div
                key={index}
                className={
                  typeof label === 'string' ? label?.toLowerCase()?.replaceAll(' ', '-') : ''
                }
              >
                <label>{label}</label>
                <div>{children}</div>
              </div>
            ))}
          </div>
        </details>
      )}
    </>
  );
};

export default QuickFacts;
