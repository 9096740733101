import React, { FC, useContext, MouseEvent as ReactMouseEvent } from 'react';
import { Link } from 'gatsby';
import { ISubNav } from './SubNav.def';
import parser from 'html-react-parser';
import MenuCheckBox from '../base/MenuCheckBox/MenuCheckBox';
import { handleNavLinkOnClick } from '../../utils/helpers/navLinkOnClickHandlers';
import HTEventsContext from '../../context/HTEventsContext';

const SubNav: FC<ISubNav> = ({ className, category }) => {
  const htevents = useContext(HTEventsContext);

  const { links, allLink, layout, title } = category;
  let ulProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  > = {};

  if (layout === 'sideBySide') {
    ulProps = {
      ...ulProps,
      className: 'sideBySide',
    };
  }

  const lowercaseVs = (str: string): string => {
    const replacement = '<span class="versus">vs.</span>';
    return str.replace('vs.', replacement);
  };

  return (
    <>
      <div className={`dropdown-subnav ${className ? className : ''}`}>
        <p className="submenu-title">{title.text}</p>
        <MenuCheckBox className="sub-menu-item" />
        <ul className="mobile-sub-menu-item__menu" {...ulProps}>
          {links.map(({ text, link }, index) => (
            <li key={index}>
              <Link
                onClick={(event: ReactMouseEvent<Element, MouseEvent>) =>
                  handleNavLinkOnClick(event, htevents, link, text)
                }
                to={link}
              >
                {parser(lowercaseVs(text))}
              </Link>
            </li>
          ))}
        </ul>
        {allLink && (
          <Link
            onClick={(event: ReactMouseEvent<Element, MouseEvent>) =>
              handleNavLinkOnClick(event, htevents, allLink.link, allLink.text)
            }
            className="allLink"
            to={allLink.link}
          >
            {allLink.text}
          </Link>
        )}
      </div>
    </>
  );
};

export default SubNav;
