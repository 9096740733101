import React from 'react';
import PageHeader from '../components/PageHeader';

const PageHeaderContainer = (props: any) => {
  const { upgp_gb_page_header_title: title, upgp_gb_page_header_subtitle: subTitle } = props;
  return (
    <PageHeader
      title={title}
      breadCrumbs={[
        {
          text: 'Home',
          link: '/',
        },
      ]}
      subTitle={subTitle}
    />
  );
};

export default PageHeaderContainer;
