import React, { FC } from 'react';
import { renderItems } from '../../utils';
import Scroller from '../Scroller';
import { ISquareBrowser } from './SquareBrowser.def';

const SquareBrowser: FC<ISquareBrowser> = ({
  title,
  moreLink,
  articles,
  isScrollable,
  subTitle,
  scrollerSettings,
}) => (
  <section className="squareBrowser">
    <div className="container">
      <div className="blockHeader">
        <div className="titles">
          {title && <h2>{title}</h2>}
          {subTitle && <p>{subTitle}</p>}
        </div>
        {moreLink && (
          <a href={moreLink.link} className={`btn-standard ${subTitle && 'hasSubtitle'}`}>
            {moreLink.text ? moreLink.text : ''}
          </a>
        )}
      </div>
      {!isScrollable && (
        <div className="newsContainer">{renderItems(articles, 'ArticleOverlay')}</div>
      )}
    </div>
    {isScrollable && (
      <Scroller className="squareBrowserScroll" scrollerSettings={scrollerSettings}>
        {renderItems(articles, 'ArticleOverlay')}
      </Scroller>
    )}
    {moreLink && (
      <div className="btn-wrapper">
        <a href={moreLink.link} className="btn-standard btn-standard-mobile">
          {moreLink.text ? moreLink.text : ''}
        </a>
      </div>
    )}
  </section>
);
export default SquareBrowser;
