import { ILink } from '../../models/ILink';
import { graphql, useStaticQuery } from 'gatsby';

const mapAcfMegaMenuItems = (
  menuItems: readonly Queries.Maybe<Queries.WpUpMegamenu_Megamenu_mainMenuItems>[] = []
) => {
  return menuItems.map((mainMenuItem) => {
    let item: ILink = {
      id: mainMenuItem?.url || '#',
      link: mainMenuItem?.url || '#',
      text: mainMenuItem?.linkText || '',
    };

    if (mainMenuItem?.menuColumns && mainMenuItem?.menuColumns.length > 0) {
      const subMenu = {
        categories:
          mainMenuItem?.menuColumns.map((menuColumn) => {
            return {
              title: {
                id: menuColumn?.columnTitle || '',
                text: menuColumn?.columnTitle || '',
              },
              links:
                menuColumn?.columnLinks?.map((columnLink) => {
                  return {
                    id: columnLink?.linkText || '',
                    link: columnLink?.url || '',
                    text: columnLink?.linkText || '',
                  };
                }) || [],
            };
          }) || [],
      };
      item = {
        ...item,
        subMenu,
      };
    }
    return item;
  });
};

const mapFooterMenuItems = (
  links: readonly Queries.Maybe<Queries.WpUpMegamenu_Footermenus_footerLinks>[]
): ILink[] =>
  links.map((link) => ({
    id: link?.url || '#',
    link: link?.url || '#',
    text: link?.linkText || '',
  }));

const getMenuItems = () => {
  const socials = ['facebook', 'instagram', 'twitter', 'pinterest', 'youtube', 'linkedin'];
  const data = useStaticQuery(graphql`
    query GetMenuItems {
      wpUpOption(slug: { eq: "menus" }) {
        megamenu {
          mainMenuItems {
            linkText
            fieldGroupName
            url
            menuColumns {
              columnTitle
              fieldGroupName
              columnLinks {
                linkText
                url
                fieldGroupName
              }
            }
          }
        }
        footerMenus {
          footerLinks {
            linkText
            url
          }
          footerLinks2 {
            linkText
            url
          }
          footerLinks4 {
            linkText
            url
          }
          footerLinks3 {
            linkText
            url
          }
          footerSmallLinks {
            linkText
            url
          }
          footerSocials {
            linkText
            url
          }
        }
      }
    }
  `);
  let mainMenuLinks: ILink[] = [];
  let legalLinks: ILink[] = [];
  let socialLinks: ILink[] = [];
  let footerLinks: ILink[][] = [[], [], [], []];
  const advertiserDisclosure: ILink = {
    link: '/advertiser-disclosure/',
    text: 'Advertiser Disclosure',
  };
  let siteLine = '';

  const { megamenu, footerMenus } = data.wpUpOption as Queries.WpUpOption;

  if (megamenu && megamenu.mainMenuItems?.length) {
    mainMenuLinks = mapAcfMegaMenuItems(megamenu.mainMenuItems);
  }

  if (footerMenus) {
    for (const footerMenu in footerMenus) {
      switch (footerMenu) {
        case 'footerLinks':
          footerLinks[0] = footerMenus?.footerLinks
            ? mapFooterMenuItems(footerMenus?.footerLinks)
            : [];
          break;
        case 'footerLinks2':
          footerLinks[1] = footerMenus?.footerLinks2
            ? mapFooterMenuItems(footerMenus?.footerLinks2)
            : [];
          break;
        case 'footerLinks3':
          footerLinks[2] = footerMenus?.footerLinks3
            ? mapFooterMenuItems(footerMenus?.footerLinks3)
            : [];
          break;
        case 'footerLinks4':
          footerLinks[3] = footerMenus?.footerLinks4
            ? mapFooterMenuItems(footerMenus?.footerLinks4)
            : [];
          break;
        case 'footerSmallLinks':
          legalLinks = footerMenus.footerSmallLinks
            ? mapFooterMenuItems(footerMenus.footerSmallLinks)
            : [];
          break;
        case 'footerSocials':
          footerMenus?.footerSocials
            ? footerMenus?.footerSocials.forEach((social) => {
                const item: ILink = {
                  id: social?.url || '#',
                  link: social?.url || '#',
                  text: social?.linkText || '',
                };
                if (item && item.text) {
                  if (socials.includes(item.text.toLowerCase())) {
                    socialLinks.push(item);
                  } else {
                    siteLine = item.text;
                  }
                }
              })
            : [];
          break;
      }
    }
  }

  return { siteLine, mainMenuLinks, footerLinks, legalLinks, socialLinks, advertiserDisclosure };
};

export default getMenuItems;
