import { Link } from 'gatsby';
import React, { FC, Fragment } from 'react';
import ChevronRightIcon from '../../../svgs/ChevronRightIcon';
import { IBreadCrumbs } from './BreadCrumbs.def';

const BreadCrumbs: FC<IBreadCrumbs> = ({ breadCrumbs }) => {
  return (
    <span className="headerBreadcrumb">
      {breadCrumbs.map((breadCrumb, index) => {
        return (
          <Fragment key={breadCrumb.text}>
            {breadCrumb.link && <Link to={breadCrumb.link}>{breadCrumb.text}</Link>}
            {breadCrumbs.length - 1 > index && <ChevronRightIcon />}
          </Fragment>
        );
      })}
    </span>
  );
};

export default BreadCrumbs;
