import React from 'react';
import { SVGProps } from 'react';

const TimesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21.443} height={19.5} {...props}>
    <g fill="none" stroke={'#003458'} strokeLinecap="round" strokeWidth={2}>
      <path data-name="Path 1029" d="m1.412 1.723 18.619 16.675" />
      <path data-name="Line 3" d="m1.742 18.413 18-17" />
    </g>
  </svg>
);

export default TimesIcon;
