import { CREDIT_CARD_REVIEW_MAX_WIDTH, FULL_WIDTH_HERO_IMAGE_MAX_WIDTH, PAID_LANDING_PAGE_MAX_WIDTH } from "../../constants";
import { ITableCondition } from "../../models/ITableCondition/ITableCondition";
import { TTemplate } from "../../models/TTemplate";

export const isTableFitLg = (templateName: TTemplate, tableWidth: number) => {
  const conditions: ITableCondition[] = [
    {
      names: [
        'Full Width Hero Image (No Sidebar)',
        'No Sidebar',
        'Clean Landing Page With Top Nav',
      ],
      maxWidth: FULL_WIDTH_HERO_IMAGE_MAX_WIDTH,
    },
    {
      names: ['Credit Card Review'],
      maxWidth: CREDIT_CARD_REVIEW_MAX_WIDTH,
    },
    {
      names: ['Paid Landing Page'],
      maxWidth: PAID_LANDING_PAGE_MAX_WIDTH,
    },
    {
      names: ['Full Width (No Hero Image)', 'Paid Landing Page - Full Width'],
      maxWidth: CREDIT_CARD_REVIEW_MAX_WIDTH,
    },
  ];

  return conditions.some(
    (condition) => condition.names.includes(templateName) && tableWidth <= condition.maxWidth,
  );
};