
interface ToggleElementsObject {
  toggleElements?: string[] | undefined;
}

export const searchInToggleElements = (obj: ToggleElementsObject, searchString: string): boolean => {
  if (!obj?.toggleElements || !Array.isArray(obj?.toggleElements)) {
    return false;
  }

  return obj?.toggleElements.includes(searchString);
}


/**
 * Checks if the '_upgp_advertiser_disclosure_inline_disabled' toggle element is present in the given data.
 *
 * @param {IDataTemplate} data - The data object containing page and fieldsForPosts information.
 * @returns {boolean} - Returns true if '_upgp_advertiser_disclosure_inline_disabled' is found in toggleElements, otherwise false.
 */
export function isAdvertiserDisclosureDisabled(data: { page: Queries.WpPage }): boolean {
  // Check if data.page.fieldsForPosts.upgpPostToggleElements is an array, if so assign it to toggleElements, otherwise assign undefined
  const toggleElements: string[] | undefined = Array.isArray(data?.page?.fieldsForPosts?.upgpPostToggleElements)
    ? data.page.fieldsForPosts.upgpPostToggleElements
    : undefined;
  
  // Check if '_upgp_advertiser_disclosure_inline_disabled' is present in toggleElements
  return searchInToggleElements(
    { toggleElements },
    '_upgp_advertiser_disclosure_inline_disabled'
  );
}
