import React, { FC, useContext } from 'react';
import CardDataContext from '../../context/CardDataContext';
import { ICardImageShortcode } from './CardImageShortcode.def';
import GoLink from '../GoLink/GoLink';
import { createSrc } from '../../utils';
import { defaultApiComplianceObject, processApiComplianceValues } from '@throttleup/esi-components';
import { renderError } from '../../utils/helpers/card';
import { processImageApiComplianceValue } from '../../utils';

//TODO: Remove this component as it is not longer being used
const CardImageShortcode: FC<ICardImageShortcode> = ({ cardId, placing = '/TT/' }) => {
  if (!cardId) return renderError('No Card ID set.');
  const { cardDataJson } = useContext(CardDataContext);
  if (!cardDataJson?.data?.length) return renderError(`JSON API Error.`);
  const cardData = cardDataJson.data.find((card) => card.cardId === cardId);

  // Get the affiliate link.
  const goLink = cardData?.affiliateLinkReviewPage;
  const upgpCardName = cardData?.upgpCardName || defaultApiComplianceObject;
  const upgpImage = cardData?.upgpImage || defaultApiComplianceObject;
  const cardTitle = processApiComplianceValues(upgpCardName);
  const cardArt = processImageApiComplianceValue(upgpImage);
  const needToAddSource = cardData?.upgpCardReviewAddTrackingSourceType || false;

  if (cardArt) {
    const imgComponent = (
      <img src={createSrc(cardArt)} alt={cardTitle} className="sc-card-image" loading='lazy' />
    );
    if (goLink) {
      return (
        <GoLink
          link={goLink}
          placingFromProps={placing}
          needToAddSource={needToAddSource}
          children={imgComponent}
          classNameFromProps="sc-card-image"
        />
      );
    }

    return imgComponent;
  }

  return renderError(`Art for Card ${cardId} not found.`);
};

export default CardImageShortcode;
