import React, { FC } from 'react';
import IconTrophy from '../../../svgs/IconTrophy';
import { IRedBadge } from './RedBadge.def';

const RedBadge: FC<IRedBadge> = ({ redBadgeText }) => {
  return (
    <span className="redBadge">
      <IconTrophy fill="#fff" />
      <span>{redBadgeText}</span>
    </span>
  );
};

export default RedBadge;
