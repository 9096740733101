import React, { FC } from 'react';
import { renderItemsList } from '../../utils';
import { IAsideArticles } from './FullFeatured.def';

const AsideArticles: FC<IAsideArticles> = ({ articles, title, linkClick = () => {} }) => (
  <div className="aside">
    <h4>{title}</h4>
    <ul>{renderItemsList(articles, 'ArticleSimple', linkClick)}</ul>
  </div>
);

export default AsideArticles;
