import React, { FC } from 'react';
import { Link } from 'gatsby';

import { ICategory } from './Category.def';
import { addLeadingAndTralingSlashes } from '../../../utils';

const CategoryLink: FC<ICategory> = ({ link, text, class: className, categoryImage }) => {
  let anchorProps = {};

  if (categoryImage) {
    anchorProps = {
      className: 'categoryImageWrapper',
    };
  }
  return (
    <li
      className={`catLink${className ? ` ${className} ` : ''} ${categoryImage ? 'catImage' : ''}`}
    >
      <Link to={addLeadingAndTralingSlashes(link)} {...anchorProps}>
        {categoryImage && (
          <figure className="categoryImage">
            <img {...categoryImage} loading="lazy" />
          </figure>
        )}
        {text && <span>{text}</span>}
      </Link>
    </li>
  );
};

export default CategoryLink;
