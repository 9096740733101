import React, { FC } from 'react';
import { renderItems } from '../../utils';
import { IHorizontalBrowser } from './HorizontalBrowser.def';
import Scroller from '../Scroller';
import BlockHeader from '../BlockHeader/BlockHeader';

const HorizontalBrowser: FC<IHorizontalBrowser> = ({
  title = '',
  subtitle = '',
  seeAllLink,
  cards,
  tileClassName = '',
  linkClick = () => {},
  notScrollable,
  headline = '',
  scrollerSettings,
  componentName = 'CardExcerptESI',
}) => {
  const renderCards = (containerClassName: string) =>
    renderItems(cards, componentName, linkClick, { containerClassName });
  return (
    <section className="horizontalBrowser">
      <div className="container">
        <BlockHeader
          headline={headline}
          title={title}
          subTitle={subtitle}
          moreLink={seeAllLink || null}
        />
      </div>
      {cards && (
        <>
          {notScrollable ? (
            <div className="notScrollable"> {renderCards(tileClassName || 'featuredCards')}</div>
          ) : (
            <Scroller scrollerSettings={scrollerSettings} className="topCreditCards">
              {renderCards(tileClassName || 'topCreditCards')}
            </Scroller>
          )}
        </>
      )}

      {seeAllLink && (
        <div className="see-all-wrapper">
          <a href={seeAllLink.link} className="btn-standard btn-standard-mobile">
            {seeAllLink.text}
          </a>
        </div>
      )}
    </section>
  );
};

export default HorizontalBrowser;
