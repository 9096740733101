import React from 'react';
import { Link } from 'gatsby';

import GuideBlocks from '../components/GuideBlocks';
import NewsFeaturedArticle from '../components/NewsFeaturedArticle/NewsFeaturedArticle';
import { getLatestNewsArticles } from '../utils/static-queries';
import { IArticleExcerpt } from '../components/ContentTypes/Article/Article.def';
import { getPlaceholderGatsbyImg } from '../utils';
import { removeDomain } from '../utils/helpers';

const LatestNewsContainer = (props: any) => {
  const {
    upgp_gb_latest_news_title: title,
    upgp_gb_latest_news_more_link_group: moreLinkData,
    upgp_gb_latest_news_featured_article: featuredArticleData,
  } = props;
  const moreLink = {
    text: moreLinkData.upgp_gb_latest_news_more_link_group_text,
    link: moreLinkData.upgp_gb_latest_news_more_link_group_link,
  };
  let featuredPostLink;
  let featuredArticle: IArticleExcerpt | null = null;
  const latestArticles = getLatestNewsArticles();
  let articles = null;
  if (featuredArticleData) {
    featuredPostLink = featuredArticleData.uri || '#';
    featuredArticle = {
      thumbnail: {
        alt: featuredArticleData?.featuredImageAlt || '',
        src: featuredArticleData?.featuredImageSrc || getPlaceholderGatsbyImg(),
      },
      date: featuredArticleData?.date ? featuredArticleData?.date : '',
      title: featuredArticleData?.title || '',
      link: featuredPostLink,
      excerpt: featuredArticleData?.excerpt || '',
      moreLink: {
        link: featuredPostLink,
        text: 'LEARN MORE',
      },
      isFeaturedArticle: true,
      author: {
        link: removeDomain(featuredArticleData?.authorUrl) || '#',
        text: featuredArticleData?.authorName || '',
      },
    };
    articles = latestArticles
      .filter((article: IArticleExcerpt) => article.databaseId !== featuredArticleData?.id)
      .slice(0, 6);
  } else {
    const firstArticle = latestArticles[0];
    featuredPostLink = firstArticle?.link || '#';
    featuredArticle = {
      thumbnail: firstArticle?.thumbnail,
      date: firstArticle?.date ? firstArticle?.date : '',
      title: firstArticle?.title || '',
      link: featuredPostLink,
      excerpt: firstArticle?.excerpt || '',
      moreLink: {
        link: featuredPostLink,
        text: 'LEARN MORE',
      },
      isFeaturedArticle: true,
      author: {
        link: firstArticle?.author?.link,
        text: firstArticle?.author?.text,
      },
      secondaryAuthor: {
        link: firstArticle?.secondaryAuthor?.link,
        text: firstArticle?.secondaryAuthor?.text,
      },
    };
    articles = latestArticles.slice(1, 7);
  }
  return (
    <>
      <section>
        <div className="container">
          <div className="blockHeader">
            <h2>{title}</h2>
          </div>
        </div>
        <NewsFeaturedArticle article={featuredArticle} />
        <GuideBlocks articles={articles} />
        <div className="container">
          <div className="latest-news-more">
            {moreLink && (
              <Link to={moreLink.link} className="btn-standard">
                {moreLink.text ? moreLink.text : 'Learn More'}
              </Link>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestNewsContainer;
