import React, { useState, MouseEventHandler, useEffect, useContext} from 'react';
import Loading from '../base/Loading/Loading';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { setCookie } from '../../utils/helpers/cookie';
import { navigate } from 'gatsby';
import { verifyEmail } from '../../utils';
import { PULSE_SLIDE_UP_SESSION_ITEM, DEFAULT_SUBSCRIPTION_LIST, HT_TRACK_EVENTS } from '../../constants';
import { sendHtTrackEvent } from '../../utils/helpers/htApi';
import HTEventsContext from '../../context/HTEventsContext/HTEventsContext';

interface SignUpFormProps {
  isPopup: boolean | undefined;
  buttonLabel: string;
  hideFirstName?: boolean;
  listId: number;
  newsletterTitle: string;
  redirectUrl?: string | undefined;
}

const SignUpForm: React.FC<SignUpFormProps> = ({
  isPopup = false,
  buttonLabel = 'Join Now',
  hideFirstName = false,
  listId = DEFAULT_SUBSCRIPTION_LIST,
  redirectUrl = '',
  newsletterTitle
}) => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isClient, setIsClient] = useState(false);
  const htevents = useContext(HTEventsContext);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    setLoading(true);
    setError('');
    e.preventDefault();
    // Send click event when a user clicks on signup button
    sendHtTrackEvent(htevents, HT_TRACK_EVENTS.CLICK, {
      element: 'Newsletter Signup',
      placing: isPopup ? 'Popup' : 'In Content',
      variant: newsletterTitle,
      email,
      name: firstName,
    });
    if (executeRecaptcha) {
      if (verifyEmail(email)) {
        try {
          await executeRecaptcha('submit');
        } catch {
          setError('There was an error when processing your request. Please try again!');
        }
        const cleanListId = parseInt(listId.toString());
        const listIds = [cleanListId];
        // Send Signup event when a user subscribes to the newsletter
        sendHtTrackEvent(htevents, 'Signup', {
          element: 'Newsletter Signup',
          placing: isPopup ? 'Popup' : 'In Content',
          variant: newsletterTitle,
          email,
          firstName,
          listIds,
        });
        setEmail('');
        setFirstName('');
        if (isPopup && isClient) {
          // close the pop-up after submit the form.
          document
            .querySelector('.closePopup')
            ?.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
        }
        setCookie(PULSE_SLIDE_UP_SESSION_ITEM, '1', 365);
        const redirectPage = redirectUrl || '/thank-you/?pos=bottomFold';
        navigate(redirectPage);
      } else {
        setLoading(false);
        setError('Invalid email format');
      }
    }
  };

  return (
    <form>
      {!hideFirstName && (
        <input
          type="text"
          placeholder="FIRST NAME"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      )}
      <input
        type="email"
        placeholder="EMAIL"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {error && <p className="error">{error}</p>}
      <button
        disabled={(!hideFirstName && firstName.length < 2) || !email || loading}
        onClick={handleSubmit}
        className="upBtn submit-form"
      >
        {loading ? <Loading /> : buttonLabel}
      </button>
    </form>
  );
};

export default SignUpForm;
