import React, { FC, useContext } from 'react';
import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser';

import CardDataContext from '../../context/CardDataContext';
import { IUpShortcode } from './UpShortcode.def';
import GoLink from '../GoLink/GoLink';
import { isElement } from '../../utils';

//TODO: Remove this component as it is not longer being used
const UpShortcode: FC<IUpShortcode> = ({
  cardId,
  cardName,
  className,
  display,
  placing = '/TBL/',
}) => {
  const renderError = (message: string) => {
    return <span color="red">{message}</span>;
  };

  if (!cardId) return renderError('No ID attribute found.');
  if (!cardName) return renderError('No name attribute found.');

  const { cardDataJson } = useContext(CardDataContext);
  if (!cardDataJson?.data?.length) return renderError('JSON API Error.');

  const cardData = cardDataJson.data.find((card) => card.cardId === cardId);
  const upShortcode = cardData?.shortcodes?.find((shortcodes) => shortcodes.name === cardName);
  if (!upShortcode) return renderError(`[up id="${cardId}" name="${cardName}"]`);

  const upShortcodeContent = upShortcode?.renderedContent || '';
  const shortcodeClassName = `up-shortcode ${className}`;
  const style = display === 'none' ? { display: 'none' } : {};

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (isElement(domNode)) {
        //Process Go Links first
        if (domNode?.name === 'a') {
          let link = domNode?.attribs['href'];
          if (link?.includes('/go/')) {
            const children = domToReact(domNode.children);
            const needToAddSource = cardData?.upgpCardReviewAddTrackingSourceType || false;
            return (
              <GoLink
                link={link}
                placingFromProps={placing}
                needToAddSource={needToAddSource}
                children={children}
              />
            );
          }
        }
      }
    },
  };
  return (
    <span className={shortcodeClassName} style={style}>
      {parse(upShortcodeContent, options)}
    </span>
  );
};

export default UpShortcode;
