import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { TTemplate } from '../../models/TTemplate';

/**
 * This function returns the template type which determines the page layout.
 * We have 3 page layouts: Full Width Hero Image (No Sidebar), Full Width (No Hero Image or Sidebar)
 * and Full Width Hero Image + Sidebar
 * Airline and Loyalty programs use Full Width Hero Image + Sidebar which is the default
 **/
export const getTemplateName = (
  templateName?: Queries.Maybe<string>,
  uri?: Queries.Maybe<string>,
  featuredImage?: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> | null,
): TTemplate => {
  if (templateName === 'Full Width Hero Image (No Sidebar)') {
    return 'Full Width Hero Image (No Sidebar)';
  }
  if (templateName === 'Full Width (No Hero Image)') {
    return 'Full Width (No Hero Image)';
  } else {
    if (uri && uri !== '/offer-not-available/') {
      if (featuredImage) {
        return 'Full Width Hero Image (No Sidebar)';
      } else {
        return 'Full Width (No Hero Image)';
      }
    }
    return 'Full Width Hero Image + Sidebar';
  }
};

interface Data {
  page?: {
    databaseId?: number;
    uri?: string | undefined | null;
    template?: {
      templateName?: string | undefined | null;
    } | undefined | null;
  };
}

export const createTemplateContext = (data: Data) => ({
  databaseId: data?.page?.databaseId || 0,
  tags: null,
  link: data?.page?.uri || '',
  categories: null,
  templateName: data?.page?.template?.templateName || '',
});