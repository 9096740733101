import { MouseEvent as ReactMouseEvent } from 'react';
import { sendHtTrackEvent } from './htApi';
import { HT_TRACK_EVENTS } from '../../constants';
import { HTEventsContextType } from '../../context/HTEventsContext/HTEventsContext.def';

/**
 * This funtion handles sending an Hightouch Click (Track) event for any Mega Menu Link
 * @param htevents - The global htevents handle.
 * @param link - The link to send in the event.
 * @param title - The title to send in the event.
 */
export const megaMenuSendHTClickEvent = (
  htevents: HTEventsContextType,
  link: string,
  title: string,
) => {
  sendHtTrackEvent(htevents, HT_TRACK_EVENTS.CLICK, {
    element: 'Mega Menu',
    title,
    link,
  });
};

/**
 * This function is the on click handler for menu link item
 * When JavaScript is enabled because we are doing client-side rendering, the dropdown menu doesn't
 * close on Desktop after the user clicks on a link and navigate to a new page
 */
export const handleNavLinkOnClick = (
  event: ReactMouseEvent<Element, MouseEvent>,
  htevents: HTEventsContextType,
  link: string,
  text: string,
) => {
  megaMenuSendHTClickEvent(htevents, link, text);

  // Triggers the close button to hide the search on any menu item clicked
  const closeSearchDiv = document.querySelector('.closeSearch') as HTMLElement;
  if (closeSearchDiv) {
    closeSearchDiv.click();
  }

  if (window.innerWidth >= 1024) {
    document.querySelectorAll('.dropdown')?.forEach((el) => {
      if (el.contains(event.currentTarget) || el.parentNode === event.currentTarget.parentNode)
        el.classList.add('hover-off');
    });
  } else {
    const checkboxes = document.querySelectorAll('.mobile-menu-toggle');
    if (checkboxes) {
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          checkbox.checked = false;
        }
      });
    }
    if (document.body.classList.contains('body--overflow-hidden')) {
      document.body.classList.remove('body--overflow-hidden');
    }
  }
};
