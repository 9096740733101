import parse, { HTMLReactParserOptions } from 'html-react-parser';

import { IHeading } from '../components/Article/components/TableOfContents/components/HeadingsContainer/HeadingsContainer.def';
import {
  getTableOfContentsHeadings,
  processCopyright,
  processLinks,
  processDomNode,
} from './helpers';
import { isElement } from '.';
import { TTemplate } from '../models/TTemplate';

export const processSimpleHTMLContent = (content: string, placingForGoLinks = '') => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (isElement(domNode)) {
        const linkNode = processLinks(domNode, placingForGoLinks);
        if (linkNode) return linkNode;
      }
      return domNode;
    },
  };
  return parse(content, options);
};

export const processArticleContent = (
  content: string,
  placingForGoLinks: string = '/TE/',
  templateName?: TTemplate,
) => {
  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if (isElement(domNode)) {
        return processDomNode(domNode, placingForGoLinks, templateName);
      }

      return domNode;
    },
  };
  let headings: IHeading[] = [];
  const articleContent = parse(processCopyright(content), options);
  if (Array.isArray(articleContent)) {
    headings = getTableOfContentsHeadings(articleContent);
  }
  return {
    articleContent,
    headings,
  };
};

export const processHTMLContent = (content: string, placingForGoLinks = '', templateName?: TTemplate) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (isElement(domNode)) {
        return processDomNode(domNode, placingForGoLinks, templateName);
      }
      return domNode;
    },
  };
  return parse(content, options);
};
