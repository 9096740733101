import React, { FC } from 'react';
import { ISocialIcons } from './SocialIcons.def';
import { getSocialIcon } from '../../utils/getSocialIcon';

const SocialIcons: FC<ISocialIcons> = ({ color = "white", socials }) => {
  return (
    <ul className="social">
      {socials.map((social) => {
        const { link, name } = social;

        return (
          <li key={name}>
            <a href={link} target="_blank" rel="noopener">
              <img src={getSocialIcon(name, color)} alt={`${name} icon`} />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default SocialIcons;
