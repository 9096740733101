import React, { SVGProps } from 'react';

const GreenCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    {...props}
  >
    <path
      d="M21.3977 10.5C21.3977 16.299 16.6967 21 10.8977 21C5.0987 21 0.397705 16.299 0.397705 10.5C0.397705 4.70099 5.0987 0 10.8977 0C16.6967 0 21.3977 4.70099 21.3977 10.5ZM9.68318 16.0597L17.4735 8.26934C17.738 8.00481 17.738 7.57588 17.4735 7.31134L16.5155 6.35335C16.251 6.08877 15.822 6.08877 15.5575 6.35335L9.20416 12.7066L6.23795 9.7404C5.97342 9.47587 5.54448 9.47587 5.27991 9.7404L4.32191 10.6984C4.05738 10.9629 4.05738 11.3919 4.32191 11.6564L8.72514 16.0596C8.98971 16.3242 9.4186 16.3242 9.68318 16.0597Z"
      fill="#0DB14B"
    />
  </svg>
);

export default GreenCheck;
