import { UP_STAFF_MEMBER_PARAM_NAME, UP_STAFF_MEMBER_PARAM_VALUE } from './src/constants';
import './src/css/index.css';
import './src/css/table.css';
import { setClickIdsInLS } from './src/utils/helpers/goLinks';

if (process.env.NODE_ENV === 'development') {
  require('./src/css/critical.css');
}

// To run when the Gatsby client initializes
export const onClientEntry = () => {
  const location = window?.location;
  if (location) {
    const searchParams = new URLSearchParams(location.search);
    setClickIdsInLS(searchParams);
    const upStaffMemberParam = searchParams.get(UP_STAFF_MEMBER_PARAM_NAME);
    // If the url param identifying a UP Staff member is present in the url params, set it in
    // localStorage so that it persists across the user's sessions
    if (upStaffMemberParam) {
      localStorage.setItem(UP_STAFF_MEMBER_PARAM_NAME, UP_STAFF_MEMBER_PARAM_VALUE);
    }
  }
};

export const onRouteUpdate = () => {
  //Move the Trust Arc Cookie Preferences Bar from the bottom of the page to inside the
  //footer Legal Links section. Do this using a mutation observer so that we only move it once
  //it gets added to the DOM by the Trust Arc third-party script tag from GTM.
  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.type === 'childList') {
        const teConsentElement = document.getElementById('teconsent');
        if (teConsentElement) {
          //Get the cookie preferences a tag
          const linkElement = teConsentElement.querySelector('a[id^="icon-id"]');
          if (linkElement) {
            //Get the container tag for the legal links.
            const ccmFooterContainer = document.getElementById('ccm-footer-container');
            if (ccmFooterContainer) {
              // Create the span element with divider bar and append it to the footer container first
              const spanElement = document.createElement('span');
              spanElement.className = 'pipe';
              spanElement.textContent = ' | ';
              ccmFooterContainer.appendChild(spanElement);
              ccmFooterContainer.appendChild(linkElement);
              observer.disconnect(); // Stop observing after the target element is found
            }
          }
          break;
        }
      }
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};
