import React, { ReactNode } from 'react';
import TeamMembers from '../components/About/components/TeamMembers/TeamMembers';
import { ITeamMember } from '../components/About/components/TeamMember/TeamMember.def';
import { getUsers } from '../utils/static-queries';
import parse from 'html-react-parser';
import { processCopyright } from '../utils/helpers';
import { getAuthorExtraDetails } from '../utils/helpers';

interface IWpSocialLinks {
  upgpUserSocialLinksName: string;
  upgpUserSocialLinksUrl: string;
}

interface IWpUserBios {
  upgpUserRichBio?: string;
}

interface IWpAvatar {
  url: string;
}

interface IWpUser {
  name: string;
  description: string;
  databaseId: number;
  slug: string;
  userBios: IWpUserBios;
  avatar: IWpAvatar;
  socials?: ISocial[];
}

const TeamMembersContainer = (props: any) => {
  const {
    upgp_gb_meet_the_team: memberIds,
    upgp_gb_meet_the_team_title: title,
    upgp_gb_meet_the_team_variant: variant,
  } = props;

  const getDescriptionFromRichBio = (richBio: string) => {
    return parse(typeof richBio === 'string' ? processCopyright(richBio) : '');
  };
  const userData = getUsers();
  const members: ITeamMember[] = [];
  const isLargeVariant = variant === 'Tiled';
  memberIds.forEach((id: number) => {
    const wpUser = userData.find((user: IWpUser) => user.databaseId === id);
    if (wpUser) {
      let description: ReactNode = null;
      if (isLargeVariant) {
        description = getDescriptionFromRichBio(wpUser.userBios?.upgpUserRichBio);
      } else {
        if (
          wpUser.userBios?.upgpUserShortBio &&
          typeof wpUser.userBios?.upgpUserShortBio == 'string'
        ) {
          description = parse(processCopyright(wpUser.userBios.upgpUserShortBio));
          if (Array.isArray(description)) {
            description = description.slice(0, 2);
          }
        } else {
          description = getDescriptionFromRichBio(wpUser.userBios?.upgpUserRichBio);
        }
      }
      const moreLink = {
        label: 'LEARN MORE',
        link: '/author/' + wpUser.slug + '/',
      };
      const thumbnail = {
        alt: wpUser.name + ' Picture',
        src: wpUser.avatar?.url,
      };
      let socials: IWpSocialLinks[] = [];
      if (wpUser.userSocials.upgpUserSocialLinks) {
        socials = socials.concat(
          wpUser.userSocials?.upgpUserSocialLinks?.map((element: IWpSocialLinks) => {
            return {
              name: element.upgpUserSocialLinksName,
              link: element.upgpUserSocialLinksUrl,
            };
          })
        );
      }
      const topicOfExpertise = wpUser.userSocials?.upgpUserExpertise && {
        link: '/' + wpUser.userSocials?.upgpUserExpertise.slug + '/',
        text: wpUser.userSocials?.upgpUserExpertise.name,
      };
      const extraAuthorDetails = getAuthorExtraDetails(wpUser.upgpAuthorDetails);
      members.push({
        fullName: wpUser.name,
        designation: wpUser.description,
        description: description,
        moreLink,
        thumbnail,
        socials,
        topicOfExpertise,
        extraAuthorDetails,
      });
    }
  });
  return <TeamMembers members={members} title={title} isLargeVariant={isLargeVariant} />;
};

export default TeamMembersContainer;
