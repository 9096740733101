import { graphql, useStaticQuery } from 'gatsby';

const getTopCreditCards = () => {
  const { wpMultiCreditCard } = useStaticQuery(graphql`
    query {
      wpMultiCreditCard(title: { eq: "Best Credit Cards Page" }) {
        fieldsForMultiTables {
          tables {
            cardReview {
              ... on WpPost {
                databaseId
              }
            }
          }
        }
      }
    }
  `);
  return wpMultiCreditCard as Queries.WpMultiCreditCard;
};

export default getTopCreditCards;
