import React, { FC } from 'react';
import { IAboutContent } from './AboutContent.def';

const AboutContent: FC<IAboutContent> = ({ mainContent }) => {
  return (
    <div className="container">
      <div className="main">{mainContent}</div>
    </div>
  );
};

export default AboutContent;
