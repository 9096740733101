import React from 'react';
import { SVGProps } from 'react';

const CloseModalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 123 123"
    {...props}
  >
    <style>
      {'.closePopup .st0{fill:none;stroke:#000;stroke-width:11.138;stroke-linecap:round;stroke-linejoin:round}'}
    </style>
    <path
      d="M83.4 39.9 61.7 61.7m0 0L39.9 83.4m21.8-21.7L39.9 39.9m21.8 21.8 21.8 21.8M61.7 116.1c30 0 54.4-24.3 54.4-54.4 0-30-24.3-54.4-54.4-54.4-30 0-54.4 24.3-54.4 54.4 0 30 24.4 54.4 54.4 54.4z"
      className="st0"
    />
  </svg>
);

export default CloseModalIcon;
