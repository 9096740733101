import React, { useState, useEffect } from 'react';
import CardDataContext from '../context/CardDataContext';

interface ChildProps {
  children: JSX.Element;
}

const CardDataProvider = ({ children }: ChildProps) => {
  const [cardDataJson, setCardDataJson] = useState({});

  useEffect(() => {
    fetch(`${process.env['GATSBY_SITE_URL']}/wp-content/uploads/json/allcards.json`)
      .then((response) => response.json())
      .then((data) => setCardDataJson({ data: data }));
  }, []);

  return <CardDataContext.Provider value={{ cardDataJson }}>{children}</CardDataContext.Provider>;
};

export default CardDataProvider;
