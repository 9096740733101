import React, { FC } from 'react';
import AsideArticles from './AsideArticles';
import AsideText from './AsideText';
import FeaturedArticle from './FeaturedArticle';
import FeaturedMeta from './FeaturedMeta';
import { IFullFeatured } from './FullFeatured.def';
import QuickFacts from './QuickFacts';

const FullFeatured: FC<IFullFeatured> = ({
  variant = 'list',
  featuredArticle,
  articles,
  asideTitle,
  asideInfo,
  quickFacts,
  featuredMeta,
  linkClick = () => {},
}) => {
  let textClass = '';
  switch (variant) {
    case 'text':
      textClass = 'fullFeaturedCopy-variant';
      break;
    case 'meta':
      textClass = 'fullFeaturedCopy-variant fullFeaturedReview';
      break;
    default:
      break;
  }
  return (
    <section className={`fullFeatured ${textClass}`}>
      <div className="fullFeaturedWrapper">
        {featuredMeta && <FeaturedMeta {...featuredMeta} linkClick={linkClick} />}
        {featuredArticle && <FeaturedArticle {...featuredArticle} linkClick={linkClick} />}
        {articles && <AsideArticles title={asideTitle} articles={articles} linkClick={linkClick} />}
        {asideInfo && <AsideText {...asideInfo} linkClick={linkClick} />}
      </div>
      {quickFacts && <QuickFacts facts={quickFacts} />}
    </section>
  );
};

export default FullFeatured;
