import { UAParser } from 'ua-parser-js';

/**
 * Returns whether or not the user's browser is the Facebook or Instagram In-App browser
 * @returns boolean - false if the browser was not detected or is not the FAB or IAB. Otherwise true.
 */
export const isFacebookOrInstagramBrowser = () => {
  const { browser } = UAParser(window.navigator.userAgent);
  return browser?.name && ['Facebook', 'Instagram'].includes(browser.name);
};

/**
 * Determine whether or not the user's device is running a version of iOS.
 * @returns boolean - true only if the device OS detected by the UAParser is iOS.
 */
export const isIOSDevice = () => {
  const { os } = UAParser(window.navigator.userAgent);
  return os.name === 'iOS';
};
