import { graphql, useStaticQuery } from 'gatsby';

const getAllHelloBars = () => {
  const { wpUpOption } = useStaticQuery(graphql`
    query {
      wpUpOption(slug: { eq: "hello-bar" }) {
        helloBar {
          upgpAllHelloBars {
            title
            hellobarStyle
            buttonLabel
            buttonUrl
            displayHelloBar
            displayOn {
              displayCategory
              displayPagesUrl
              displayPosts {
                ... on WpPost {
                  databaseId
                }
              }
              displayPages {
                ... on WpPage {
                  databaseId
                }
              }
              displayCategories {
                databaseId
                slug
              }
              displayTags {
                databaseId
                slug
              }
            }
            hideOn {
              hidePosts {
                ... on WpPost {
                  id
                  databaseId
                  slug
                }
              }
              hideTags {
                slug
                databaseId
              }
              hideCategories {
                slug
                databaseId
              }
            }
          }
        }
      }
    }
  `);

  return wpUpOption as Queries.WpUpOption;
};

export default getAllHelloBars;
