import { IPressBlock } from './components/Press/components/PressBlock/PressBlock.def';

export const MAX_NUMBER_OF_POSTS_PER_PAGE = 21;
export const MAX_NUMBER_OF_CARDS_PER_PAGE = 20;
export const PULSE_SLIDE_UP_SESSION_ITEM = 'up_pulse_slide_up';
export const BAGGAGE_FEES_SLUG = 'baggage-fees';
export const TRANSFER_PARTNER_TOOL_POST_ID = 2873;
export const HOMEPAGE_PAGE_ID = 284206;
export const SM_BREAKPOINT = 767;
export const FULL_WIDTH_TEMPLATE_SIZE = 1200;
export const MAIN_CONTENT_WIDTH_SIDEBAR = 760;
export const LG_BREAKPOINT = 1024;
export const FULL_WIDTH_HERO_IMAGE_MAX_WIDTH = 900;
export const FULL_WIDTH_SIDEBAR_MAX_WIDTH = 750;
export const CREDIT_CARD_REVIEW_MAX_WIDTH = 1000;
export const FULL_WIDTH_NO_HERO_MAX_WIDTH = 1200;
export const PAID_LANDING_PAGE_MAX_WIDTH = 884;
export const FULL_WIDTH_PAID_LANDING_PAGE_MAX_WIDTH = 1260;
export const MOBILE_COL_MAX_WIDTH = 220;
export const MOBILE_FIRST_COL_MAX_WIDTH = 160;
export const MINIMUM_CELL_WIDTH = 50;
export const DEFAULT_SUBSCRIPTION_LIST = 3;
export const SCROLL_SPACE_TOP_SLIDER = 200;
export const TABLE_OF_CONTENTS_ELEMENT_OFFSET_POSITION = 70;
export const TABLE_OF_CONTENTS_TOGGLE_BUTTON_OFFSET = 35;
export const TABLE_OF_CONTENTS_UNSTICK_OFFSET = 50;
export const SHARE_DROPDOWN_OPEN_CLASS = 'share-dropdown-open';
export const HUB_PAGES__FILTERS = ['Personal', 'Use', 'Credit Card Reviews'];
export const TABLE_OF_CONTENTS_STICKY_SCROLL_HEIGHT_THRESHOLD = 8000;
export const STICKY_NAV_SCROLL_UP_OFFSET = 5;
export const INCONTENT_MOBILE_AD = 'upgradedpoints_incontent_3';
export const STICKY_BOX_OFFSET_TOP = 80;
export const HUB_PAGES = [
  'Comparisons',
  'Guides',
  'Airline',
  'Hotel',
  'Credit Card Points',
  'Travel Product Reviews',
];

export const PAGINATION_NEIGHBORS = 3;
export const MIN_PAGES_FOR_SEPARATOR = 5;
export const SEPARATOR_THRESHOLD = 3;

export const MEGA_MENU_SUB_ELEMENT = 'Mega Menu - Sub';
export const MEGA_MENU_MAIN_ELEMENT = 'Mega Menu - Main';

export const CAP_ONE_DISCLAIMER_TEXT =
  'Information collected independently. Not reviewed by Capital One.';

// Temporary Categories
export const HOTEL_CATEGORIES = [
  {
    text: 'Marriott',
    options: ['Marriott'],
  },
  {
    text: 'Hilton',
    options: ['Hilton'],
  },
  {
    text: 'IHG',
    options: ['IHG'],
  },
  {
    text: 'Hyatt',
    options: ['Hyatt'],
  },
];
export const AIRLINES_CATEGORIES = [
  {
    text: 'American Airlines',
    options: ['American Airlines'],
  },
  {
    text: 'United Airlines',
    options: ['United Airlines'],
  },
  {
    text: 'Delta Airlines',
    options: ['Delta Airlines'],
  },
  {
    text: 'Southwest Airlines',
    options: ['Southwest Airlines'],
  },
  {
    text: 'Emirates',
    options: ['Emirates'],
  },
  {
    text: 'Air Canada',
    options: ['Air Canada'],
  },
  {
    text: 'Virgin',
    options: ['Virgin'],
  },
  {
    text: 'Singapore Airlines',
    options: ['Singapore Airlines', 'Singapore'],
  },
  {
    text: 'Turkish Airlines',
    options: ['Turkish Airlines'],
  },
  {
    text: 'Qatar Airways',
    options: ['Qatar Airways'],
  },
];

export const CREDIT_CARDS_CATEGORIES = [
  {
    text: 'Chase',
    options: ['Chase'],
  },
  {
    text: 'Amex',
    options: ['Amex', 'American Express'],
  },
  {
    text: 'Capital One',
    options: ['Capital One'],
  },
  {
    text: 'Citi',
    options: ['Citi'],
  },
  {
    text: 'Mastercard',
    options: ['Mastercard'],
  },
];

export const TRAVEL_PRODUCTS_REVIEWS_CATEGORIES = [
  {
    text: 'Luggage & Bags',
    link: '/travel/luggage-bags/',
    possibleValues: ['bag', 'luggage', 'suitcase', 'backpack', 'carry-on', 'purse'],
  },
  {
    text: 'Travel Tech',
    link: '/travel/travel-tech/',
    possibleValues: ['mobile', 'wi-fi', 'camera', 'video', 'binocular', 'clock', 'alarm'],
  },
  {
    text: 'Travel Clothes',
    link: '/travel/travel-clothes/',
    possibleValues: ['cloth', 'shoe', 'blanket', 'dress', 'shirt', 'pant', 'vest'],
  },
];

export const PRESS_LIST: IPressBlock[] = [
  {
    pressDate: 'July 10, 2023',
    publicationName: 'CNBC',
    pressTitle: `Americans spend an average of $91.75 on Amazon each month`,
    link: 'https://www.cnbc.com/2023/07/10/americans-spend-91-on-amazon-monthly-these-10-states-spend-the-most.html',
  },
  {
    pressDate: 'June 12, 2023',
    publicationName: 'Penn Live',
    pressTitle: `This Pa. airport is one of the most ‘kid-friendly’ in the US, according to a study`,
    link: 'https://www.pennlive.com/life/2023/06/this-pa-airport-is-one-of-the-most-kid-friendly-in-the-us-according-to-a-study.html',
  },
  {
    pressDate: 'June 11, 2023',
    publicationName: 'Forbes',
    pressTitle: `Distraction, Diversion And Discontent: The Truth About Remote Work Today`,
    link: 'https://www.forbes.com/sites/tracybrower/2023/06/11/distraction-diversion-and-discontent-the-truth-about-remote-work-today/?sh=1c5630914166',
  },
  {
    pressDate: 'May 25, 2023',
    publicationName: 'The New York Times',
    pressTitle: `Strikes, Delays and Lost Luggage: How to Survive Air Travel This Summer`,
    link: 'https://www.nytimes.com/explain/2023/05/25/travel/summer-travel-airports-flights',
  },
  {
    pressDate: 'May 16, 2023',
    publicationName: 'CBS News',
    pressTitle: `South Floridians need to be on high alert for identity theft`,
    link: 'https://www.cbsnews.com/miami/news/south-floridians-need-to-be-on-high-alert-for-indentity-theft/',
  },
  {
    pressDate: 'May 8, 2023',
    publicationName: 'KTNV',
    pressTitle: `Study: For every 10k residents in southern Nevada, there are 48.9 identity theft reports`,
    link: 'https://www.ktnv.com/news/study-for-every-10k-residents-in-southern-nevada-there-are-48-9-identity-theft-reports',
  },
  {
    pressDate: 'May 2, 2023',
    publicationName: 'Spectrum News',
    pressTitle: `With inflation rising, Texans increasingly relying on credit cards`,
    link: 'https://spectrumlocalnews.com/tx/south-texas-el-paso/news/2023/04/29/increased-credit-card-reliance-',
  },
  {
    pressDate: 'April 23, 2023',
    publicationName: 'The Manual',
    pressTitle: `Need a last-minute flight? A new study says these airlines offer the most affordable options`,
    link: 'https://www.themanual.com/travel/best-airlines-for-last-minute-flights/',
  },
  {
    pressDate: 'April 14, 2023',
    publicationName: 'TimeOut',
    pressTitle: `These are the best airlines for booking last-minute flights`,
    link: 'https://www.timeout.com/usa/news/these-are-the-best-airlines-for-booking-last-minute-flights-041423',
  },
  {
    pressDate: 'March 6, 2023',
    publicationName: 'WRAL News',
    pressTitle: `Raleigh metro sees 34.6% increase in credit card frauds in 2022 compared to year before, report finds`,
    link: 'https://www.wral.com/story/raleigh-metro-sees-34-6-increase-in-credit-card-frauds-in-2022-compared-to-year-before-report-finds/20750049/',
  },
  {
    pressDate: 'March 6, 2023',
    publicationName: 'Travel Off Path',
    pressTitle: `These Are The U.S. Airports With The Best Lounges, According To A New Report`,
    link: 'https://www.traveloffpath.com/these-are-the-u-s-airports-with-the-best-lounges-according-to-a-new-report/',
  },
  {
    pressDate: 'Feb 22, 2023',
    publicationName: 'Yahoo',
    pressTitle: `This Study Reveals Your State’s Most Ordered Drink… & How Well You Tip`,
    link: 'https://www.yahoo.com/lifestyle/study-reveals-states-most-ordered-182900917.html',
  },
  {
    pressDate: 'Feb 1, 2023',
    publicationName: 'Fortune',
    pressTitle: `‘Ridiculous’ airline fees for checked bags and seating assignments could become a thing of the past…`,
    link: 'https://fortune.com/2023/02/01/ridiculous-airline-fees-checked-bags-seating-assignments-thing-past-new-bill/',
  },
  {
    pressDate: 'Jan 6, 2023',
    publicationName: 'CNBC',
    pressTitle: `Arrive extra-early at these 15 U.S. airports—up to 3 hours—if you don’t want to miss your flight`,
    link: 'https://www.cnbc.com/2023/01/06/us-airports-you-need-to-arrive-two-to-four-hours-early.html',
  },
  {
    pressDate: 'Dec 22, 2022',
    publicationName: 'Business Journals',
    pressTitle: `Household debt has risen steadily for years. What does that look like in Alabama?`,
    link: 'https://www.bizjournals.com/birmingham/news/2022/12/22/what-kinds-of-debt-affect-alabamians-the-most.html',
  },
  {
    pressDate: 'Dec 21, 2022',
    publicationName: 'Consumer Affairs',
    pressTitle: `How early do you need to get to the airport for holiday travel? A new survey breaks it down`,
    link: 'https://www.consumeraffairs.com/news/how-early-do-you-need-to-get-to-the-airport-for-holiday-travel-a-new-survey-breaks-it-down-122122.html',
  },
  {
    pressDate: 'Dec 20, 2022',
    publicationName: 'Dallas News',
    pressTitle: `Here’s how early you should arrive at DFW, other major airports for your holiday flights`,
    link: 'https://www.dallasnews.com/business/airlines/2022/12/20/heres-how-early-you-should-arrive-at-dfw-other-major-airports-for-your-holiday-flights/',
  },
  {
    pressDate: 'Dec 15, 2022',
    publicationName: 'Boston',
    pressTitle: `How early you should arrive at Logan Airport for your flight, according to data`,
    link: 'https://www.boston.com/travel/flights/2022/12/15/how-early-you-should-arrive-at-logan-airport-for-your-flight/',
  },
  {
    pressDate: 'Dec 12, 2022',
    publicationName: 'Fortune',
    pressTitle: `America’s least favorite airport (somehow) isn’t LAX`,
    link: 'https://fortune.com/2022/12/12/travelers-pet-peeves-least-favorite-airports/',
  },
  {
    pressDate: 'Nov 22, 2022',
    publicationName: 'TheStreet',
    pressTitle: `These Are the Worst Days and Times to go Through Airport Customs`,
    link: 'https://www.thestreet.com/travel/these-are-the-worst-days-and-times-to-go-through-airport-customs',
  },
  {
    pressDate: 'Oct 31, 2022',
    publicationName: 'New York Times',
    pressTitle: `What does “first class” even mean anymore?`,
    link: 'https://www.washingtonpost.com/travel/2022/10/31/first-class-american-airlines-delta-emirates/',
  },
  {
    pressDate: 'Oct 25, 2022',
    publicationName: 'Thrillist',
    pressTitle: `Here Are the Best and Worst Cities to Visit for Weed Tourism`,
    link: 'https://www.thrillist.com/news/nation/best-and-worst-us-cities-weed-tourism',
  },
  {
    pressDate: 'Oct 7, 2022',
    publicationName: 'Business Insider',
    pressTitle: `Planning a cannabis vacation? Here are the top 10 cities for a weed enthusiast’s getaway`,
    link: 'https://www.businessinsider.com/top-10-best-cities-visit-weed-marijuana-cannabis-vacation-2022-10?r=US&IR=T',
  },
  {
    pressDate: 'Sept 6, 2022',
    publicationName: 'NBC Dallas',
    pressTitle: `How to Start Improving Your Credit Score`,
    link: 'https://www.nbcdfw.com/news/nbc-5-responds/how-to-start-improving-your-credit-score/3065684/',
  },
  {
    pressDate: 'Aug 10, 2022',
    publicationName: 'TheStreet',
    pressTitle: `What are Best and Worst Cities to Lift Your Credit Rating?`,
    link: 'https://www.thestreet.com/personal-finance/best-cities-boost-credit-rating',
  },
  {
    pressDate: 'Aug 5, 2022',
    publicationName: 'CNBC',
    pressTitle: `These are the 10 best cities for building your credit score — and the 10 worst`,
    link: 'https://www.cnbc.com/select/best-and-worst-cities-for-building-credit-score/',
  },
  {
    pressDate: 'July 28, 2022',
    publicationName: 'Huffington Post',
    pressTitle: `What To Do If An Airline Loses Your Luggage`,
    link: 'https://www.huffpost.com/entry/airlines-lost-luggage-what-to-do_l_62d78328e4b0aad58d14069a',
  },
  {
    pressDate: 'May 24, 2022',
    publicationName: 'GOBankingRates',
    pressTitle: `21 Mistakes That Can Blow Your Budget While Traveling`,
    link: 'https://www.gobankingrates.com/saving-money/travel/21-mistakes-can-blow-your-budget-while-traveling/',
  },
  {
    pressDate: 'May 23, 2022',
    publicationName: 'NBC Chicago',
    pressTitle: `O’Hare International Airport Has the Most Starbucks Locations of Any US Airport`,
    link: 'https://www.nbcchicago.com/news/local/ohare-has-the-most-starbucks-locations-of-any-us-airport-report-says/2839636/',
  },
  {
    pressDate: 'April 12, 2022',
    publicationName: 'Thrillist',
    pressTitle: `Mass U.S. Flight Cancellations Could Continue Into the Summer`,
    link: 'https://www.thrillist.com/news/nation/flight-cancellations-continue-summer-staffing-shortages',
  },
  {
    pressDate: 'March 26, 2022',
    publicationName: 'SFGate',
    pressTitle: `Routes: Bay Area airports gain two routes, lose one; Alaska Airlines plans big move at SFO`,
    link: 'https://www.sfgate.com/travel/article/Routes-Alaska-plans-big-move-at-SFO-17026414.php',
  },
  {
    pressDate: 'March 21, 2022',
    publicationName: 'Time Out',
    pressTitle: `This is New York’s favorite road trip snack`,
    link: 'https://www.timeout.com/newyork/news/this-is-new-yorks-favorite-road-trip-snack-032122',
  },
  {
    pressDate: 'Feb 22, 2022',
    publicationName: 'Travel Pulse',
    pressTitle: `The Most Searched for Spring Break Destinations`,
    link: 'https://www.travelpulse.com/news/features/the-most-searched-for-spring-break-destinations.html',
  },
  {
    pressDate: 'Feb 16, 2022',
    publicationName: 'Smarter Travel',
    pressTitle: `The U.S. Airlines With the Most Customer Complaints`,
    link: 'https://www.smartertravel.com/us-airlines-with-the-most-customer-complaints/',
  },
  {
    pressDate: 'Feb 5, 2022',
    publicationName: 'CNBC',
    pressTitle: `Your favorite Winter Olympic sport may depend on what state you’re from, according to a new analysis`,
    link: 'https://www.cnbc.com/2022/02/05/analysis-most-searched-winter-olympics-sports-in-each-us-state.html',
  },
  {
    pressDate: 'Feb 2, 2022',
    publicationName: 'Insider',
    pressTitle: `Map shows which Winter Olympics sport is most popular in each state`,
    link: 'https://www.insider.com/map-of-us-states-most-popular-winter-olympics-sports-2022-2',
  },
  {
    pressDate: 'Dec 29, 2021',
    publicationName: 'Forbes',
    pressTitle: `New Rankings Rate The Best (and Worst) U.S. Airlines`,
    link: 'https://www.forbes.com/sites/elizabethbrownfield/2021/12/29/new-rankings-rate-the-best-and-worst-us-airlines/?sh=148859007e2a',
  },
  {
    pressDate: 'Dec 22, 2021',
    publicationName: 'New York Times',
    pressTitle: `It’s Not How Much You Fly, It’s How Much You Spend`,
    link: 'https://www.nytimes.com/2021/12/22/travel/frequent-flier-miles-airline-status.html',
  },
  {
    pressDate: 'Dec 20, 2021',
    publicationName: 'Consumer Affairs	',
    pressTitle: `Experts warn holiday travelers about crowded airports and roadways`,
    link: 'https://www.consumeraffairs.com/news/experts-warn-holiday-travelers-about-crowded-airports-and-roadways-122021.html',
  },
  {
    pressDate: 'Dec 7, 2021',
    publicationName: 'Boston.com',
    pressTitle: `The U.S. airlines most likely to bump you, according to Upgraded Points`,
    link: 'https://www.boston.com/travel/flights/2021/12/07/the-u-s-airlines-most-likely-to-bump-you-according-to-upgraded-points/',
  },
  {
    pressDate: 'Sept 28, 2021',
    publicationName: 'Washington Post',
    pressTitle: `Flying with kids: 6 questions about mask rules, answered`,
    link: 'https://www.washingtonpost.com/travel/2021/09/28/kids-planes-mask-rules-flying/',
  },
  {
    pressDate: 'Sept 17, 2021',
    publicationName: 'The Points Guy',
    pressTitle: `Here’s why a vaccine mandate for international travel could be on the way`,
    link: 'https://thepointsguy.com/news/us-travel-vaccine-mandate/',
  },
  {
    pressDate: 'Sept 13, 2021',
    publicationName: 'Finder',
    pressTitle: `It’s a good time to open a travel credit card`,
    link: 'https://www.finder.com/credit-cards/travel-credit-cards',
  },
  {
    pressDate: 'Aug 22, 2021',
    publicationName: 'CNBC',
    pressTitle: `Here are the country’s cheapest airports to park at`,
    link: 'https://www.cnbc.com/2021/08/22/here-are-the-countrys-cheapest-and-priciest-airports-for-parking.html',
  },
  {
    pressDate: 'Aug 17, 2021',
    publicationName: 'Forbes',
    pressTitle: `Majority Of Americans Want A Vaccine Mandate For Domestic Flights`,
    link: 'https://www.forbes.com/sites/suzannerowankelleher/2021/08/17/significant-majority-americans-vaccine-mandate-domestic-flights/?sh=3ae359792256',
  },
  {
    pressDate: 'Aug 12, 2021',
    publicationName: 'Trip Savvy',
    pressTitle: `Why Did Spirit Airlines Have so Many Cancelations Last Week?`,
    link: 'https://www.tripsavvy.com/spirit-airlines-cancelations-5197222',
  },
  {
    pressDate: 'June 24, 2021',
    publicationName: 'Skift',
    pressTitle: `The New York Times Pulls Plug on Travel Show After 17 Years`,
    link: 'https://skift.com/2021/06/24/the-new-york-times-pulls-plug-on-travel-show-after-17-years/',
  },
  {
    pressDate: 'April 10, 2021',
    publicationName: 'Business Insider',
    pressTitle: `Freezing student-loan payments saved just $2,000 per borrower, report finds`,
    link: 'https://www.insider.com/most-popular-disney-ride-in-every-state-2021-4',
  },
  {
    pressDate: 'April 10, 2021',
    publicationName: 'Insider',
    pressTitle: `Here are the country’s cheapest airports to park at`,
    link: 'https://www.cnbc.com/2021/08/22/here-are-the-countrys-cheapest-and-priciest-airports-for-parking.html',
  },
  {
    pressDate: 'Aug 22, 2021',
    publicationName: 'CNBC',
    pressTitle: `The most popular Disney World ride in every state`,
    link: 'https://www.insider.com/most-popular-disney-ride-in-every-state-2021-4',
  },
  {
    pressDate: 'April 8, 2021',
    publicationName: 'AJC',
    pressTitle: `This is the cocktail Georgians enjoy sipping the most`,
    link: 'https://www.ajc.com/life/this-is-the-cocktail-georgians-enjoy-sipping-the-most/GLFL7XSWZNAKJB4VNCHGRD7DHU/',
  },
  {
    pressDate: 'April 7, 2021',
    publicationName: 'Yahoo! Life',
    pressTitle: `This is the Most Popular Cocktail in Your State, According to Data`,
    link: 'https://www.yahoo.com/lifestyle/most-popular-cocktail-state-according-005758551.html',
  },
  {
    pressDate: 'March 18, 2021',
    publicationName: 'CNBC',
    pressTitle: `This Map Show Which U.S. States Procrastinate the Most on Their Taxes`,
    link: 'https://www.cnbc.com/2021/03/18/map-shows-which-us-states-procrastinate-most-on-taxes.html',
  },
  {
    pressDate: 'March 3, 2021',
    publicationName: 'Washington Post	',
    pressTitle: `This is how traveler loyalty has changed during the pandemic`,
    link: 'https://www.washingtonpost.com/lifestyle/travel/customer-loyalty-pandemic-safety-service/2021/03/03/eb9f4ddc-76d7-11eb-9537-496158cc5fd9_story.html',
  },
  {
    pressDate: 'Feb 16, 2021',
    publicationName: 'Bankrate',
    pressTitle: `Click to Pay: What it is, how it works`,
    link: 'https://www.bankrate.com/finance/credit-cards/what-is-click-to-pay-how-it-works/',
  },
  {
    pressDate: 'Feb 3, 2021',
    publicationName: 'AJC',
    pressTitle: `Analysis: Georgians should save this much to send kids to college`,
    link: 'https://www.ajc.com/life/analysis-georgians-should-save-this-much-to-send-kids-to-college/BRVH7LCVXVCMBNYEQI5LCVKJFA/',
  },
  {
    pressDate: 'Dec 25, 2020',
    publicationName: 'Business Insider',
    pressTitle: `7 credit card predictions for 2021 to help you plan your rewards strategy for the new year`,
    link: 'https://www.businessinsider.com/personal-finance/credit-card-predictions-2021-2020-12?IR=T',
  },
  {
    pressDate: 'Dec 22, 2020',
    publicationName: 'New York Times',
    pressTitle: `9 Ways the Pandemic Will Change Travel in 2021`,
    link: 'https://www.nytimes.com/2020/12/22/travel/flight-cancellations-cruise-ships-covid-vaccine.html?partner=naver',
  },
  {
    pressDate: 'Dec 17, 2020',
    publicationName: 'New York Times',
    pressTitle: `Booking Travel in 2021? Here’s How to Protect Your Investment`,
    link: 'https://www.nytimes.com/2020/12/17/travel/cancellations-refunds.html?partner=naver',
  },
  {
    pressDate: 'Dec 3, 2020',
    publicationName: 'Inquisitr',
    pressTitle: `DOT Ruling On Emotional Support Animals Called A “Disservice” By Experts, But Should Curb Abuse of System`,
    link: 'https://www.inquisitr.com/6405172/transportation-department-emotional-support-animal-experts/',
  },
  {
    pressDate: 'Nov 30, 2020',
    publicationName: 'Fox Business',
    pressTitle: `Should you renew your travel credit card for 2021?`,
    link: 'https://www.foxbusiness.com/money/should-you-renew-travel-credit-card',
  },
  {
    pressDate: 'Nov 23, 2020',
    publicationName: 'Apartment Therapy',
    pressTitle: `Stuck On Your Couch with Wanderlust? You Can Make $1,000 by Watching “Eat, Pray, Love” and Other Travel Films`,
    link: 'https://www.apartmenttherapy.com/get-paid-to-watch-travel-movies-36847365',
  },
  {
    pressDate: 'Nov 17, 2020',
    publicationName: 'Travel & Leisure',
    pressTitle: `Get Paid $1,000 to Binge-watch 9 Wanderlust-inducing Travel Movies`,
    link: 'https://www.travelandleisure.com/culture-design/tv-movies/upgraded-points-pay-1000-dollars-to-watch-wanderlust-movies',
  },
  {
    pressDate: 'Nov 11, 2020',
    publicationName: 'Fox Business',
    pressTitle: `Applying for a store credit card? Watch out for this big red flag`,
    link: 'https://www.foxbusiness.com/money/applying-for-store-credit-card-red-flag',
  },
  {
    pressDate: 'Nov 6, 2020',
    publicationName: 'Time Out',
    pressTitle: `This company is willing to pay you $1,000 to binge-watch 10 pretty good movies`,
    link: 'https://www.timeout.com/usa/news/this-company-is-willing-to-pay-you-1-000-to-binge-watch-10-pretty-good-movies-110620',
  },
  {
    pressDate: 'Oct 6, 2020',
    publicationName: 'Business Insider',
    pressTitle: `Travel may not be back to normal, but credit card companies are starting to offer new flight, hotel, and points perks`,
    link: 'https://www.businessinsider.com/personal-finance/despite-quarantine-travel-credit-cards-are-ramping-back-up-2020-10?IR=T',
  },
  {
    pressDate: 'Sept 3, 2020',
    publicationName: 'CNTraveler',
    pressTitle: `Labor Day Will Be a Busy Travel Weekend Despite the Pandemic`,
    link: 'https://www.cntraveler.com/story/labor-day-will-be-a-busy-travel-weekend-despite-the-pandemic',
  },
  {
    pressDate: 'Aug 31, 2020',
    publicationName: 'Forbes',
    pressTitle: `The Dirtiest Places In Hotels: It’s Worse Than You Think`,
    link: 'https://www.forbes.com/sites/laurabegleybloom/2020/08/31/dirtiest-places-hotels/',
  },
  {
    pressDate: 'Aug 6, 2020',
    publicationName: 'Washington Post',
    pressTitle: `Why more families are teaming up and hitting the road in vacation pods`,
    link: 'https://www.washingtonpost.com/',
  },
  {
    pressDate: 'Aug 4, 2020',
    publicationName: 'Fox Business',
    pressTitle: `What to do with airline and travel credit cards amid coronavirus`,
    link: 'https://www.foxbusiness.com/money/what-to-do-with-airline-travel-credit-cards-coronavirus',
  },
  {
    pressDate: 'Aug 3, 2020',
    publicationName: 'Travel Pulse',
    pressTitle: `The US Airlines With the Highest Number of Complaints Amid COVID-19`,
    link: 'https://www.travelpulse.com/news/airlines/the-us-airlines-with-the-highest-number-of-complaints-amid-covid-19.html',
  },
  {
    pressDate: 'Aug 3, 2020',
    publicationName: 'Fox Business',
    pressTitle: `These US airlines received most complaints in coronavirus pandemic, study says`,
    link: 'https://www.foxbusiness.com/lifestyle/us-airlines-most-complaints-pandemic-study-claims',
  },
  {
    pressDate: 'July 7, 2020',
    publicationName: 'Reader’s Digest',
    pressTitle: `How to Avoid Germs on Fully Booked Flights`,
    link: 'https://www.rd.com/list/avoid-germs-on-flights/',
  },
  {
    pressDate: 'June 22, 2020',
    publicationName: 'WTOP',
    pressTitle: `Can you Cancel Rewards Travel?`,
    link: 'https://wtop.com/news/2020/06/can-you-cancel-rewards-travel/',
  },
  {
    pressDate: 'June 12, 2020',
    publicationName: 'New York Times',
    pressTitle: `In Fine Print, Airlines Make It Harder to Fight for Passenger Rights`,
    link: 'https://www.nytimes.com/2020/06/12/travel/virus-airlines-private-arbitration.html',
  },
  {
    pressDate: 'June 11, 2020',
    publicationName: 'Lifehacker',
    pressTitle: `How to Land a Job That Pays You $1,000 to Take Virtual Vacations`,
    link: 'https://lifehacker.com/how-to-land-a-job-that-pays-you-1-000-to-take-virtual-1843997232',
  },
  {
    pressDate: 'June 9, 2020',
    publicationName: 'Travel & Leisure',
    pressTitle: `This Company Will Pay You $1,000 to Take a Virtual Trip Around the World (Video)`,
    link: 'https://www.travelandleisure.com/jobs/get-paid-to-take-virtual-trip-dream-job',
  },
  {
    pressDate: 'June 8, 2020',
    publicationName: 'Apartment Therapy',
    pressTitle: `This Travel Company Will Pay You To Take a Virtual Vacation Anywhere in the World`,
    link: 'https://www.apartmenttherapy.com/virtual-tour-travel-dream-job-36759489',
  },
  {
    pressDate: 'May 29, 2020',
    publicationName: 'Business Insider',
    pressTitle: `Is flying safe right now? Experts break down the risks associated with boarding a flight during COVID-19`,
    link: 'https://www.businessinsider.com/is-flying-safe-right-now-coronavirus?IR=T',
  },
  {
    pressDate: 'May 26, 2020',
    publicationName: 'Fodor’s Travel',
    pressTitle: `What Should I Do With My Airline Points and Miles Right Now?`,
    link: 'https://www.fodors.com/news/rewards-programs/what-should-i-do-with-my-airline-points-and-miles-right-now',
  },
  {
    pressDate: 'May 12, 2020',
    publicationName: 'MSN',
    pressTitle: `7 Danger Zones in Hotels You Need to Avoid, According to Experts`,
    link: 'https://www.msn.com/en-us/travel/news/7-danger-zones-in-hotels-you-need-to-avoid-according-to-experts/ss-BB13ZCys',
  },
  {
    pressDate: 'May 8, 2020',
    publicationName: 'CNBC',
    pressTitle: `This luxury resort is open for swanky social distancing retreats—take a look inside`,
    link: 'https://www.cnbc.com/2020/05/08/photos-nemacolin-resort-open-for-luxury-social-distancing-retreats.html',
  },
  {
    pressDate: 'April 27, 2020',
    publicationName: 'New York Times',
    pressTitle: `Airlines and Hotels Reach Out to Their Top Spenders`,
    link: 'https://www.nytimes.com/2020/04/27/business/coronavirus-airlines-hotels-customers.html',
  },
  {
    pressDate: 'April 24, 2020',
    publicationName: 'Smarter Travel',
    pressTitle: `5 Charts That Show COVID-19’s Impact on Air Travel`,
    link: 'https://www.smartertravel.com/how-hard-covid-hit-air-travel/',
  },
  {
    pressDate: 'April 20, 2020',
    publicationName: 'Oyster',
    pressTitle: `The 6 Biggest Myths About Hotel Room Upgrades (And How to Score One)`,
    link: 'https://www.oyster.com/articles/biggest-myths-about-hotel-upgrades-and-how-to-score-one/',
  },
  {
    pressDate: 'April 13, 2020',
    publicationName: 'Travel & Leisure',
    pressTitle: `Coronavirus Air Travel: These Numbers Show the Massive Impact of the Pandemic`,
    link: 'https://www.cntraveler.com/story/coronavirus-air-travel-these-numbers-show-the-massive-impact-of-the-pandemic',
  },
  {
    pressDate: 'April 12, 2020',
    publicationName: 'Thrillist',
    pressTitle: `You Can Get Paid $1,000 to Compare Disney’s Originals and Remakes`,
    link: 'https://www.thrillist.com/news/nation/paid-to-watch-disneye-movies-remakes',
  },
  {
    pressDate: 'April 6, 2020',
    publicationName: 'Travel & Leisure',
    pressTitle: `What to Do With Your Frequent Flier Miles and Hotel Points When You Can’t Travel`,
    link: 'https://www.travelandleisure.com/travel-tips/points-miles/what-to-do-with-frequent-flier-miles-hotel-points-while-grounded',
  },
  {
    pressDate: 'April 3, 2020',
    publicationName: 'Dallas News',
    pressTitle: `Airlines are giving passengers more time to rebook tickets since many are scared to fly again until 2021`,
    link: 'https://www.businessinsider.com/is-flying-safe-right-now-coronavirus?IR=T',
  },
  {
    pressDate: 'March 26, 2020',
    publicationName: 'Today.com',
    pressTitle: `Real ID deadline is extended by a year. Here’s what you need to know`,
    link: 'https://www.today.com/money/real-id-deadline-extended-year-amid-coronavirus-pandemic-t174888',
  },
  {
    pressDate: 'March 23, 2020',
    publicationName: 'The Ladders',
    pressTitle: `Despite the Coronavirus outbreak, this amount of Americans would travel within the next two weeks`,
    link: 'https://www.theladders.com/career-advice/travel-during-coronavirus',
  },
  {
    pressDate: 'March 9, 2020',
    publicationName: 'House Beautiful',
    pressTitle: `This Company Wants to Pay You to Binge-Watch 12 Disney Originals…`,
    link: 'https://www.housebeautiful.com/lifestyle/a31289659/disney-original-remake-reviewer-job-contest/',
  },
  {
    pressDate: 'March 6, 2020',
    publicationName: 'UPI',
    pressTitle: `Disney fan sought to make $1,000 comparing animated, live-action films`,
    link: 'https://www.upi.com/Odd_News/2020/03/06/Disney-fan-sought-to-make-1000-comparing-animated-live-action-films/5651583521113/',
  },
  {
    pressDate: 'March 6, 2020',
    publicationName: 'Click Orlando',
    pressTitle: `Get paid $1,000 to watch classic Disney movies — again`,
    link: 'https://www.clickorlando.com/news/2020/03/06/get-paid-1000-to-watch-classic-disney-movies-again/',
  },
  {
    pressDate: 'February 25, 2020',
    publicationName: 'Travel Pulse',
    pressTitle: `The 15 Most Pet-Friendly Vacation Spots in the US`,
    link: 'https://www.travelpulse.com/gallery/destinations/the-15-most-pet-friendly-vacation-spots-in-the-us.html',
  },
  {
    pressDate: 'February 12, 2020',
    publicationName: 'NJ.com',
    pressTitle: `Is it legal for an airline to bump you from a flight?`,
    link: 'https://www.nj.com/advice/2020/02/is-it-legal-for-an-airline-to-bump-you-from-a-flight-yes-but-you-can-cash-in.html',
  },
  {
    pressDate: 'February 12, 2020',
    publicationName: 'CNBC',
    pressTitle: `How much a fancy Valentine’s Day date costs in 10 major U.S. cities`,
    link: 'https://www.cnbc.com/2020/02/12/how-much-a-fancy-date-costs-in-10-major-us-cities.html',
  },
  {
    pressDate: 'February 5, 2020',
    publicationName: 'Smarter Travel',
    pressTitle: `The airline most likely to lose your bag`,
    link: 'https://www.smartertravel.com/the-airline-most-likely-to-lose-your-bag/',
  },
  {
    pressDate: 'January 29, 2020',
    publicationName: 'Fodor’s Travel',
    pressTitle: `This is the only state where you can’t fly commercial`,
    link: 'https://www.fodors.com/news/news/this-is-the-only-state-where-you-cant-fly-commercial',
  },
  {
    pressDate: 'January 29, 2020',
    publicationName: 'CBS Austin',
    pressTitle: `Victoria, Texas has the worst airport access among larger US cities`,
    link: 'https://cbsaustin.com/news/local/victoria-texas-has-the-worst-airport-access-among-larger-us-cities-report-says',
  },
  {
    pressDate: 'December 16, 2019',
    publicationName: 'FlyerTalk',
    pressTitle: `The best and worst airports for holiday travel`,
    link: 'https://www.flyertalk.com/articles/the-best-and-worst-airports-for-holiday-travel.html',
  },
  {
    pressDate: 'November 29, 2019',
    publicationName: 'Dallas News',
    pressTitle: `4 ways American Airlines is trying to fix its industry-worst tech experience`,
    link: 'https://www.dallasnews.com/business/airlines/2019/11/29/4-ways-american-airlines-is-trying-to-fix-its-industry-worst-tech-experience/',
  },
  {
    pressDate: 'November 27, 2019',
    publicationName: 'Business Insider',
    pressTitle: `These are the airlines most likely to bump you`,
    link: 'https://www.businessinsider.com/airlines-most-likely-to-bump-ranked-2019-11',
  },
  {
    pressDate: 'November 26, 2019',
    publicationName: 'Mental Floss',
    pressTitle: `Planning a Trip? These Are the 10 U.S. Airlines Most Likely to Bump You`,
    link: 'https://www.mentalfloss.com/article/609347/airlines-most-likely-to-bump-you',
  },
  {
    pressDate: 'November 25, 2019',
    publicationName: 'Boston.com',
    pressTitle: `Upgraded Points revealed the U.S. airline most likely to bump passengers`,
    link: 'https://www.boston.com/travel/travel/2019/11/25/airlines-most-likely-to-bump-passengers',
  },
  {
    pressDate: 'November 21, 2019',
    publicationName: 'Thrillist',
    pressTitle: `These Are the Airlines Most Likely to Bump You From a Flight`,
    link: 'https://www.cnbc.com/2019/11/24/if-flying-this-thanksgiving-heres-how-long-youll-wait-at-airports.html',
  },
  {
    pressDate: 'November 21, 2019',
    publicationName: 'Forbes',
    pressTitle: `The U.S. Airlines Most Likely To Bump Passengers [Infographic]`,
    link: 'https://www.forbes.com/sites/niallmccarthy/2019/11/21/the-us-airlines-most-likely-to-bump-passengers-infographic/#7751302f3095',
  },
  {
    pressDate: 'November 20, 2019',
    publicationName: 'Travel & Leisure',
    pressTitle: `These are the airlines most likely to bump you, according to a new study`,
    link: 'https://www.travelandleisure.com/airlines-airports/airlines-flights-holiday-travel-southwest-frontier',
  },
  {
    pressDate: 'November 20, 2019',
    publicationName: 'Fodor’s Travel',
    pressTitle: `This survey reveals how people really feel about animals on planes`,
    link: 'https://www.fodors.com/news/news/this-survey-reveals-how-people-really-feel-about-animals-on-planes',
  },
  {
    pressDate: 'November 19, 2019',
    publicationName: 'Fox News',
    pressTitle: `These airlines are most likely to bump passengers, according to a travel company`,
    link: 'https://www.foxnews.com/travel/airlines-bump-passengers-study/',
  },
  {
    pressDate: 'November 18, 2019',
    publicationName: 'Lonely Planet',
    pressTitle: `How do your fellow passengers really feel about animals on airplanes?`,
    link: 'https://www.lonelyplanet.com/articles/service-animals-upgraded-points-study',
  },
  {
    pressDate: 'November 8, 2019',
    publicationName: 'USA Today',
    pressTitle: `These airlines are the most and least likely to bump you`,
    link: 'https://www.usatoday.com/picture-gallery/travel/airline-news/2019/11/08/airlines-most-and-least-likely-bump-you-per-upgraded-points/2530335001/',
  },
  {
    pressDate: 'November 7, 2019',
    publicationName: 'Lifehacker',
    pressTitle: `These U.S. Airlines Bumped the Most Passengers in 2019`,
    link: 'https://lifehacker.com/these-u-s-airlines-bumped-the-most-passengers-in-2019-1839694572',
  },
  {
    pressDate: 'October 24, 2019',
    publicationName: 'Orlando Weekly',
    pressTitle: `Orlando International has the longest U.S. customs wait time of any airport in the U.S.`,
    link: 'https://www.orlandoweekly.com/Blogs/archives/2019/10/24/orlando-international-has-the-longest-us-customs-wait-time-of-any-airport-in-the-us',
  },
  {
    pressDate: 'October 9, 2019',
    publicationName: 'Apartment Therapy',
    pressTitle: `Here’s How Much Airlines Make from Baggage Fee`,
    link: 'https://www.apartmenttherapy.com/baggage-fees-cost-2019-36656652',
  },
  {
    pressDate: 'October 8, 2019',
    publicationName: 'Fast Company',
    pressTitle: `Ridiculous baggage fees are keeping these budget airlines afloat`,
    link: 'https://www.fastcompany.com/90414848/ridiculous-baggage-fees-are-keeping-these-budget-airlines-afloat',
  },
  {
    pressDate: 'September 18, 2019',
    publicationName: 'Consumer Affairs',
    pressTitle: `New study reveals which destinations travelers are showing the most interest in`,
    link: 'https://www.consumeraffairs.com/news/new-study-reveals-which-destinations-travelers-are-showing-the-most-interest-in-091819.html',
  },
  {
    pressDate: 'September 17, 2019',
    publicationName: 'Dallas News',
    pressTitle: `American Airlines charges a record $350 million for bags in the second quarter`,
    link: 'https://www.dallasnews.com/business/airlines/2019/09/17/after-hiking-fees-american-airlines-charges-a-record-350-million-for-bags-in-the-second-quarter/',
  },
  {
    pressDate: 'September 15, 2019',
    publicationName: 'CNBC',
    pressTitle: `How to pick a travel rewards credit card – if you should at all`,
    link: 'https://www.cnbc.com/2019/09/15/how-to-pick-a-travel-rewards-credit-card-if-you-should-at-all.html',
  },
  {
    pressDate: 'September 6, 2019',
    publicationName: 'Quartz',
    pressTitle: `Today’s bachelorette parties have everything — except budgets`,
    link: 'https://qz.com/quartzy/1703242/how-to-plan-a-bachelorette-party-your-friends-can-actually-afford/',
  },
  {
    pressDate: 'August 31, 2019',
    publicationName: 'Business Traveler',
    pressTitle: `Salt Lake City has quickest security lines, Newark the longest waits`,
    link: 'https://www.businessinsider.com/is-flying-safe-right-now-coronavirus?IR=T',
  },
  {
    pressDate: 'August 22, 2019',
    publicationName: 'BSmarter Travel',
    pressTitle: `The U.S. Airports with the Worst TSA Wait Times`,
    link: 'https://www.smartertravel.com/airports-with-the-worst-tsa-wait-times/',
  },
  {
    pressDate: 'August 22, 2019',
    publicationName: 'Daily Mail',
    pressTitle: `Revealed: The worst TSA wait times at America’s busiest 25 airports`,
    link: 'https://www.dailymail.co.uk/news/article-7383877/The-best-worst-airports-TSA-wait-times-revealed-New-Jersey-bottom.html',
  },
  {
    pressDate: 'August 21, 2019',
    publicationName: 'Washington Post',
    pressTitle: `These are the airports with the best and worst TSA wait times`,
    link: 'https://www.washingtonpost.com/travel/tips/these-are-airports-with-best-worst-tsa-wait-times/',
  },
  {
    pressDate: 'August 19, 2019',
    publicationName: 'Statista',
    pressTitle: `TSA Security: The Longest Waits At U.S. Airports`,
    link: 'https://www.statista.com/chart/19056/average-and-peak-waiting-time-at-tsa-security-screening/',
  },
  {
    pressDate: 'August 16, 2019',
    publicationName: 'Forbes',
    pressTitle: `The U.S. Airports With The Longest Security Screening Wait Times [Infographic]`,
    link: 'https://www.forbes.com/sites/niallmccarthy/2019/08/16/tsa-security-screening-the-us-airports-with-the-longest-waiting-time-infographic/#4e6906e4176c',
  },
  {
    pressDate: 'August 15, 2019',
    publicationName: 'iHeartRadio',
    pressTitle: `Here Are The Five Airports With The Fastest TSA Security Wait Times`,
    link: 'https://www.iheart.com/content/2019-08-15-here-are-the-five-airports-with-the-fastest-tsa-security-wait-times/',
  },
  {
    pressDate: 'August 14, 2019',
    publicationName: 'Forbes',
    pressTitle: `Expert Tips to Maximize Points and Miles With 3 Top Frequent Flier Program`,
    link: 'https://www.forbes.com/sites/robinraven/2019/08/14/expert-tips-to-maximize-points-and-miles-with-3-top-frequent-flyer-programs/#2069cc401689',
  },
  {
    pressDate: 'August 14, 2019',
    publicationName: 'Consumer Affairs',
    pressTitle: `Newest study reveals the fastest and slowest security checkpoint times for U.S. airports`,
    link: 'https://www.consumeraffairs.com/news/new-study-reveals-the-fastest-and-slowest-security-checkpoint-times-for-us-airports-081419.html',
  },
  {
    pressDate: 'August 12, 2019',
    publicationName: 'The Salt Lake Tribune',
    pressTitle: `Salt Lake City International Airport has quickest security lines`,
    link: 'https://www.sltrib.com/news/politics/2019/08/12/salt-lake-city/',
  },
  {
    pressDate: 'August 12, 2019',
    publicationName: 'NJ.com',
    pressTitle: `Lines at Newark Airport broke two records`,
    link: 'https://www.nj.com/traffic/2019/08/lines-at-newark-airport-broke-two-records-one-is-impressive-and-the-other-is-annoying.html',
  },
  {
    pressDate: 'July 5, 2019',
    publicationName: 'US News',
    pressTitle: `How to Take Advantage of Credit Card Referral Bonuses`,
    link: 'https://creditcards.usnews.com/articles/how-to-take-advantage-of-credit-card-referral-bonuses',
  },
  {
    pressDate: 'June 26, 2019',
    publicationName: 'MSN',
    pressTitle: `Alaska Airlines named best airline for flying with your pet`,
    link: 'https://www.msn.com/en-ph/autos/other/alaska-airlines-named-best-airline-for-flying-with-your-pet/',
  },
  {
    pressDate: 'June 10, 2019',
    publicationName: 'San Francisco Chronicle',
    pressTitle: `California is #1 when it comes to air travel`,
    link: 'https://www.sfchronicle.com/travel/article/California-air-travel-13960234.php?psid=hMr3g',
  },
  {
    pressDate: 'May 30, 2019',
    publicationName: 'New York Times',
    pressTitle: `Airlines’ Gift to New Parents: Loyalty Status`,
    link: 'https://www.nytimes.com/2019/05/30/travel/airlines-frequent-flier-life-events.html',
  },
  {
    pressDate: 'May 7, 2019',
    publicationName: 'Biz Journals',
    pressTitle: `Which airlines are the largest on a state-by-state basis?`,
    link: 'https://www.bizjournals.com/dallas/news/2019/05/07/american-southwest-market-share-state.html',
  },
  {
    pressDate: 'May 2, 2019',
    publicationName: 'NBC News',
    pressTitle: `Millennials are going into debt over bachelor and bachelorette parties. Here’s how to cut costs.`,
    link: 'https://www.nbcnews.com/better/lifestyle/millennials-are-racking-debt-bachelor-bachelorette-parties-here-s-how-ncna1001341',
  },
  {
    pressDate: 'May 2, 2019',
    publicationName: 'WTOP',
    pressTitle: `Dulles listed among the ‘most kid-friendly’ airports`,
    link: 'https://wtop.com/business-finance/2019/05/dulles-makes-a-list-of-most-kid-friendly-airports/',
  },
  {
    pressDate: 'May 1, 2019',
    publicationName: 'CNBC News',
    pressTitle: `Happily indebted after: Millennials go broke over the bachelor party`,
    link: 'https://www.cnbc.com/2019/05/01/happily-indebted-after-millennials-go-broke-over-the-bachelor-party.html',
  },
  {
    pressDate: 'April 17, 2019',
    publicationName: 'Consumer Affairs',
    pressTitle: `Airports increasingly look to become kid-friendly`,
    link: 'https://www.consumeraffairs.com/news/airports-increasingly-look-to-become-kid-friendly-041719.html',
  },
  {
    pressDate: 'April 9, 2019',
    publicationName: 'Boston 25 News',
    pressTitle: `Study: Logan most kid-friendly airport in the country`,
    link: 'https://www.boston25news.com/news/study-logan-most-kid-friendly-airport-in-the-country/938538534',
  },
  {
    pressDate: 'April 9, 2019',
    publicationName: 'Lifehacker',
    pressTitle: `Will You Really Need a Real ID to Fly Within the U.S.?`,
    link: 'https://lifehacker.com/will-you-really-need-a-real-id-to-fly-within-the-u-s-1833914044',
  },
  {
    pressDate: 'March 23, 2019',
    publicationName: 'Mental Floss',
    pressTitle: `Disney’s Most Magical Destinations Have Been Reimagined as Vintage Travel Posters`,
    link: 'http://mentalfloss.com/article/577773/disney-location-vintage-travel-posters',
  },
  {
    pressDate: 'March 21, 2019',
    publicationName: 'US News & World Report',
    pressTitle: `Cash back vs Travel Rewards: Which is Better For You?`,
    link: 'https://creditcards.usnews.com/articles/cash-back-vs-travel-rewards-which-is-better-for-you',
  },
  {
    pressDate: 'March 20, 2019',
    publicationName: 'COED.com',
    pressTitle: `Your Next Summer Vacation, Based on Your Harry Potter House`,
    link: 'https://coed.com/2019/03/21/your-next-summer-vacation-based-on-your-harry-potter-house/',
  },
  {
    pressDate: 'March 9, 2019',
    publicationName: 'New York Times',
    pressTitle: `Costlier Flights, Cheaper Hotels and Meeting Adam Levine: the New Loyalty Programs`,
    link: 'https://www.nytimes.com/2019/03/09/travel/points-loyalty-program-airlines-hotel.html',
  },
  {
    pressDate: 'March 8, 2019',
    publicationName: 'Pop Sugar',
    pressTitle: `These Disney Vintage Travel Posters Have Me Ready to Discover a Whole New World`,
    link: 'https://www.popsugar.com/smart-living/Disney-Vintage-Travel-Posters-45892998',
  },
  {
    pressDate: 'February 13, 2019',
    publicationName: 'EliteDaily.com',
    pressTitle: `Where To Travel Based On Your ‘Harry Potter’ House Has Been Revealed`,
    link: 'https://www.elitedaily.com/p/where-to-travel-based-on-your-harry-potter-house-has-been-revealed-the-results-are-magical-15949556',
  },
  {
    pressDate: 'February 12, 2019',
    publicationName: 'Entrepreneur.com',
    pressTitle: `My Biggest Lesson in Success? How to Thrive by Proving Others Wrongs`,
    link: 'https://www.entrepreneur.com/article/327442',
  },
  {
    pressDate: 'January 29, 2019',
    publicationName: 'Pure Wow',
    pressTitle: `Accio, Vacation! Here Are the Top TravelDestinations for You Based on Your Hogwarts House`,
    link: 'https://www.purewow.com/news/harry-potter-house-travel-destinations-survey',
  },
  {
    pressDate: 'January 28, 2019',
    publicationName: 'ApartmentTherapy.com',
    pressTitle: `This Is the Trip You Should Take Next, Based On Your Hogwarts House`,
    link: 'https://www.apartmenttherapy.com/harry-potter-hogwarts-house-travel-preferences-266495',
  },
  {
    pressDate: 'January 23, 2019',
    publicationName: 'US News & World Report',
    pressTitle: `The Best Credit Cards with an Annual Bonus`,
    link: 'https://creditcards.usnews.com/annual-bonus',
  },
  {
    pressDate: 'January 9, 2019',
    publicationName: 'Austin360.com',
    pressTitle: `Fido in flight: Three Texas cities among most pet-friendly airports`,
    link: 'https://www.austin360.com/entertainmentlife/20190109/fido-in-flight-three-texas-cities-among-most-pet-friendly-airports',
  },
  {
    pressDate: 'December 25, 2018',
    publicationName: 'FlyerTalk',
    pressTitle: `The most pet-friendly airports in America`,
    link: 'https://www.flyertalk.com/articles/the-most-pet-friendly-airports-in-america-have-been-ranked.html',
  },
  {
    pressDate: 'December 21, 2018',
    publicationName: 'TheStar.com',
    pressTitle: `Devise a safe getaway plan to please your pet and yourself`,
    link: 'https://www.thestar.com/life/travel/2018/12/21/devise-a-safe-getaway-plan-to-please-your-pet-and-yourself.html',
  },
  {
    pressDate: 'December 14, 2018',
    publicationName: 'Credit Karma',
    pressTitle: `TSA PreCheck: What it does, what it costs and how to get it`,
    link: 'https://www.creditkarma.com/credit-cards/i/tsa-precheck-costs/',
  },
  {
    pressDate: 'December 10, 2018',
    publicationName: 'U.S. News & World Repor',
    pressTitle: `How to Get Global Entry or TSA Precheck With Your Credit Card`,
    link: 'https://creditcards.usnews.com/articles/how-to-get-global-entry-or-tsa-precheck-with-your-credit-card',
  },
  {
    pressDate: 'December 6, 2018',
    publicationName: 'Matador Network',
    pressTitle: `The 10 most pet-friendly airports in the US`,
    link: 'https://matadornetwork.com/read/pet-friendly-airports-us/',
  },
  {
    pressDate: 'December 4, 2018',
    publicationName: 'New York Times',
    pressTitle: `Want to Take Your Pet on Your Next Vacation? Here Is What You Need to Know`,
    link: 'https://www.nytimes.com/2018/12/04/travel/want-to-take-your-pet-on-your-next-vacation-here-is-what-you-need-to-know.html',
  },
  {
    pressDate: 'December 1, 2018',
    publicationName: 'Chowhound',
    pressTitle: `Tips for Maximizing Credit Card Rewards at Restaurants`,
    link: 'https://www.chowhound.com/food-news/213303/tips-for-maximizing-credit-card-rewards-at-restaurants/',
  },
  {
    pressDate: 'November 30, 2018',
    publicationName: 'Bizjournals.com',
    pressTitle: `Southwest Airlines hikes prices in U.S.`,
    link: 'https://www.bizjournals.com/houston/news/2018/11/30/southwest-airlines-hikes-prices-in-u-s.html?ana=RSS&amp;s=article_search',
  },
  {
    pressDate: 'November 29, 2018',
    publicationName: 'Mental Floss',
    pressTitle: `The 10 Most Pet-Friendly Airports in America`,
    link: 'http://mentalfloss.com/article/565426/most-pet-friendly-airports-in-america',
  },
  {
    pressDate: 'November 29, 2018',
    publicationName: 'MSN.com',
    pressTitle: `New York’s JFK Airport tops list of most pet-friendly airports in the US`,
    link: 'https://www.msn.com/en-ph/autos/other/new-yorks-jfk-airport-tops-list-of-most-pet-friendly-airports-in-the-us/ar-BBQfuLv?li=AAxCjZq&amp;srcref=rss',
  },
  {
    pressDate: 'November 29, 2018',
    publicationName: 'USA Today (VIDEO feature)',
    pressTitle: `Most pet-friendly airports in the US`,
    link: 'https://www.usatoday.com/videos/travel/2018/11/29/most-pet-friendly-airports-us/38638971/',
  },
  {
    pressDate: 'November 28, 2018',
    publicationName: 'Consumer Affairs',
    pressTitle: `New study details the 10 best U.S. airports for pets`,
    link: 'https://www.consumeraffairs.com/news/new-study-details-the-10-best-us-airports-for-pets-112818.html',
  },
  {
    pressDate: 'November 27, 2018',
    publicationName: 'Travel Pulse',
    pressTitle: `New Study Shows Americans Have 240 Stressful Travel Experiences in a Lifetime`,
    link: 'https://www.travelpulse.com/news/features/new-study-shows-americans-have-240-stressful-travel-experiences-in-a-lifetime.html',
  },
  {
    pressDate: 'November 27, 2018',
    publicationName: 'Travel Pulse',
    pressTitle: `These Are the Most Pet-Friendly Airports in the US`,
    link: 'https://www.travelpulse.com/gallery/airlines/these-are-the-most-pet-friendly-airports-in-the-us.html',
  },
  {
    pressDate: 'November 20, 2018',
    publicationName: 'Credit Karma',
    pressTitle: `Starwood Preferred Guest® Credit Card from American Express: A hotel rewards card worth out`,
    link: 'https://www.creditkarma.com/credit-cards/i/starwood-preferred-guest-credit-card-review/',
  },
  {
    pressDate: 'November 20, 2018',
    publicationName: 'Parade',
    pressTitle: `Traveling With Fido? Check Out These 10 Pet-Friendly U.S. Airports`,
    link: 'https://parade.com/718584/leahingram/traveling-with-fido-check-out-these-10-pet-friendly-u-s-airports/',
  },
  {
    pressDate: 'November 15, 2018',
    publicationName: 'Bizjournals.com',
    pressTitle: `Tour Ohio’s most-expensive Airbnb – a New Albany mansion that rents for $5,000 a night (slideshow)`,
    link: 'https://www.bizjournals.com/columbus/news/2018/11/15/tour-ohios-most-expensive-airbnb-a-new-albany.html?ana=RSS&amp;s=article_search',
  },
  {
    pressDate: 'November 15, 2018',
    publicationName: 'The Points Guy',
    pressTitle: `These Are the Top 10 US Airports for Pets`,
    link: 'https://thepointsguy.com/news/these-are-the-top-10-us-airports-for-pets/',
  },
  {
    pressDate: 'November 15, 2018',
    publicationName: 'People',
    pressTitle: `Presenting the Top 10 Pet-Friendly Airports in the U.S., Just in Time for the Holidays`,
    link: 'https://people.com/pets/pet-friendly-airports-united-states/',
  },
  {
    pressDate: 'November 9, 2018',
    publicationName: 'One News Page (VIDEO feature)',
    pressTitle: `1 in 7 Americans Think They’re “Travel Cursed”`,
    link: 'https://www.onenewspage.co.uk/video/20181109/11028141/in-Americans-Think-They-re-Travel.htm',
  },
  {
    pressDate: 'November 8, 2018',
    publicationName: 'New York Post',
    pressTitle: `Traveling is so horrible that many fear they’re ‘cursed’`,
    link: 'https://nypost.com/2018/11/08/traveling-is-so-horrible-that-many-fear-theyre-cursed/',
  },
  {
    pressDate: 'November 2, 2018',
    publicationName: 'Forbes',
    pressTitle: `Where You’ll Find America’s Most Expensive Airbnbs`,
    link: 'https://www.forbes.com/sites/alyyale/2018/11/02/meet-americas-most-expensive-airbnbs/',
  },
  {
    pressDate: 'October 25, 2018',
    publicationName: 'Due',
    pressTitle: `3 Things To Know About Maximizing Business Credit Cards`,
    link: 'https://due.com/blog/3-things-to-know-about-maximizing-business-credit-cards/',
  },
  {
    pressDate: 'October 17, 2018',
    publicationName: 'Inc.',
    pressTitle: `4 Ways Millennial Travelers Can Make the Most of Business Trips`,
    link: 'https://www.inc.com/andrew-thomas/4-ways-millennial-travelers-can-make-most-of-business-trips.html',
  },
  {
    pressDate: 'October 8, 2018',
    publicationName: 'The Knot',
    pressTitle: `The Most and Least Expensive Cities for a 3-Day Bachelorette Party`,
    link: 'https://www.theknot.com/content/bachelorette-party-weekend-cost',
  },
  {
    pressDate: 'October 8, 2018',
    publicationName: 'Quick And Dirty Tips',
    pressTitle: `Travel Stress-Free With These 6 Apps`,
    link: 'https://www.quickanddirtytips.com/tech/software-apps/travel-stress-free-with-these-6-apps',
  },
  {
    pressDate: 'October 6, 2018',
    publicationName: 'Brides',
    pressTitle: `This Is How Much Future Brides and Grooms Are Spending on Their Bachelorette and Bachelor Parties`,
    link: 'https://www.brides.com/story/bachelorette-bachelor-party-costs',
  },
  {
    pressDate: 'October 4, 2018',
    publicationName: 'CreditCards.com',
    pressTitle: `Headed to a hurricane-prone area this fall? Here’s how to prepare`,
    link: 'https://www.creditcards.com/credit-card-news/headed-to-a-hurricane-prone-destination-how-to-prepare.php',
  },
  {
    pressDate: 'September 25, 2018',
    publicationName: 'AOL.com',
    pressTitle: `The 5 most and least expensive cities for a bachelorette weekend`,
    link: 'https://www.aol.com/article/lifestyle/2018/09/25/the-5-most-and-least-expensive-cities-for-a-bachelorette-weekend/23541454/',
  },
  {
    pressDate: 'September 20, 2018',
    publicationName: 'Thrive Global',
    pressTitle: `5 Ways To Help Yourself Enjoy Air Travel Again`,
    link: 'https://www.thriveglobal.com/stories/43976-5-ways-to-help-yourself-enjoy-air-travel-again',
  },
  {
    pressDate: 'August 20, 2018',
    publicationName: 'U.S. News & World Report',
    pressTitle: `The Secrets to Booking a Family Vacation With Rewards`,
    link: 'https://creditcards.usnews.com/articles/the-secrets-to-booking-a-family-vacation-with-rewards',
  },
  {
    pressDate: 'August 9, 2018',
    publicationName: 'Austin360',
    pressTitle: `This Texas airport is one of the worst in the country, according to a study`,
    link: 'https://www.austin360.com/travel/this-texas-airport-one-the-worst-the-country-according-study/BK8KLkgOW4qxYkZT5bEDnI/',
  },
  {
    pressDate: 'August 9, 2018',
    publicationName: 'Kens5 – San Antonio (VIDEO feature)',
    pressTitle: `San Antonio has one of the worst airports in the U.S., according to Upgraded Points`,
    link: 'https://www.kens5.com/article/news/nation-world/san-antonio-has-one-of-the-worst-airports-in-the-us-according-to-upgraded-points/273-582124638',
  },
  {
    pressDate: 'August 2, 2018',
    publicationName: 'CultureMap – San Antonio',
    pressTitle: `San Antonio airport lands among worst in U.S. in new study`,
    link: 'http://sanantonio.culturemap.com/news/travel/08-02-18-san-antonio-airport-fails-to-get-first-class/',
  },
  {
    pressDate: 'June 6, 2018',
    publicationName: 'Thrive Global',
    pressTitle: `How to Plan a Restorative Getaway Without Stressing About Money`,
    link: 'https://www.thriveglobal.com/stories/32999-how-to-plan-a-restorative-getaway-without-stressing-about-money',
  },
  {
    pressDate: 'April 9, 2018',
    publicationName: 'Business Insider',
    pressTitle: `The 6 best credit cards for travel rewards, according to a couple who flew around the world for just $1,000 each`,
    link: 'https://www.businessinsider.com/cheap-flights-credit-card-rewards-program-2018-4',
  },
  {
    pressDate: 'April 5, 2018',
    publicationName: 'Forbes',
    pressTitle: `Four Ways To Become More Versatile And More Valuable At Work`,
    link: 'https://www.forbes.com/sites/williamarruda/2018/04/05/four-ways-to-become-more-versatile-and-more-valuable-at-work/#64492cdf3f77',
  },
  {
    pressDate: 'March 28, 2018',
    publicationName: 'Born2Invest',
    pressTitle: `3 ways to balance the risks and rewards of your next credit card`,
    link: 'https://born2invest.com/articles/credit-card-rewards/',
  },
  {
    pressDate: 'March 23, 2018',
    publicationName: 'News.com.au',
    pressTitle: `How we made trip of a lifetime reality with just $3k`,
    link: 'http://www.news.com.au/travel/travel-advice/flights/how-we-made-trip-of-a-lifetime-reality-with-just-3k/news-story/6fbffc13024e9af43287afe16dd88007',
  },
  {
    pressDate: 'March 20, 2018',
    publicationName: 'Entrepreneur',
    pressTitle: `3 Ways To Use Credit Card Rewards To Your Advantage`,
    link: 'https://www.entrepreneur.com/article/310377',
  },
  {
    pressDate: 'March 14, 2018',
    publicationName: 'Business Insider',
    pressTitle: `This couple flew around the world in first class using airline points — and paid less than $2,000 for $55,000 of airfare`,
    link: 'https://www.businessinsider.com/cheap-flights-singapore-airlines-first-class-upgrade-points-2018-3',
  },
  {
    pressDate: 'March 5, 2018',
    publicationName: 'Lonely Planet',
    pressTitle: `This couple traveled the world by paying less than $2200 for flights worth 25 times that`,
    link: 'https://www.lonelyplanet.com/news/2018/03/05/travel-hack-reward-points/',
  },
  {
    pressDate: 'February 12, 2018',
    publicationName: 'Forbes',
    pressTitle: `How To Use Your Credit Card Points For The Trip of Your Dreams`,
    link: 'https://www.forbes.com/sites/richardgano/2018/02/12/how-to-use-your-credit-card-points-for-the-trip-of-your-dreams',
  },
  {
    pressDate: 'February 5, 2018',
    publicationName: 'IdeaMensch',
    pressTitle: `Interview — Alex Miller, Founder and CEO, Upgraded Points`,
    link: 'https://ideamensch.com/alex-miller/',
  },
  {
    pressDate: 'September 29, 2017',
    publicationName: 'Consumer Affairs',
    pressTitle: `How to Improve Your Chance of Getting an Airline Upgrade`,
    link: 'https://www.consumeraffairs.com/news/how-to-improve-your-chance-of-getting-an-airline-upgrade-092917.html',
  },
  {
    pressDate: 'August 30, 2017',
    publicationName: 'Huffington Post',
    pressTitle: `30 Quick-Fire Travel Tips To Improve Your Next Adventure`,
    link: 'http://www.huffingtonpost.com/amar-hussain/30-quickfire-travel-tips-_b_11563252.html',
  },
  {
    pressDate: 'July 28, 2017',
    publicationName: 'Entrepreneur',
    pressTitle: `5 Reasons Why Travel Should Be an Essential Part of Building Your Business`,
    link: 'https://www.entrepreneur.com/article/297338',
  },
  {
    pressDate: 'July 26, 2017',
    publicationName: 'PopSugar',
    pressTitle: `10 Free Packing Checklists —&nbsp;Print Them Out Before Your Next Trip!`,
    link: 'https://www.popsugar.com/smart-living/photo-gallery/43554533/image/43554657/longer-extended-trips-detailed-list-from-Upgraded-Points',
  },
  {
    pressDate: 'February 12, 2017',
    publicationName: 'Elite Daily',
    pressTitle: `10 Unique Ways To Travel The World For Free`,
    link: 'https://www.elitedaily.com/travel/ways-travel-world-cheaply/1784753',
  },
  {
    pressDate: 'October 20, 2016',
    publicationName: 'Million Mile Secrets',
    pressTitle: `Upgraded Points Interview`,
    link: 'https://millionmilesecrets.com/inspiration/upgraded-points/',
  },
  {
    pressDate: 'April 4, 2016',
    publicationName: 'Huffington Post',
    pressTitle: `5 Travel Hacks For Your Next Vacation`,
    link: 'http://www.huffingtonpost.com/amar-hussain/5-travel-hacks-for-your-n_b_9605544.html',
  },
];

// Tailwind Breakpoints
export const MEDIUM_MOBILE_MIN_WIDTH_MQ = '(min-width: 350px)';
export const LARGE_MOBILE_MIN_WIDTH_MQ = '(min-width: 400px)';
export const MOBILE_MAX_WIDTH_MQ = '(max-width: 640px)';
export const SMALL_TAB_MIN_WIDTH_MQ = '(min-width: 640px)';
export const MEDIUM_TAB_MIN_WIDTH_MQ = '(min-width: 768px)';
export const LARGE_TAB_MIN_WIDTH_MQ = '(min-width: 1024px)';
export const SMALL_DESKTOP_MIN_WIDTH_MQ = '(min-width: 1280px)';
export const MED_DESKTOP_MIN_WIDTH_MQ = '(min-width: 1536px)';
export const LARGE_DESKTOP_MIN_WIDTH_MQ = '(min-width: 1920px)';

//Tracking constants and LS constants
export const DUMMY_GO_LINK_PLACING = '/DPL/';
export const INVALID_VALUE = '456456456';
export const NO_LOCAL_STORAGE = '890890890';
export const RECORD_EXPIRED = '123123123';
export const DEFAULT_EXPIRY_PERIOD = 2 * 365 * 24 * 60 * 60 * 1000; // 2 year expiry in milliseconds
export const NEED_NEW_SESSION_KEY = 'need_new_bi_session';
export const NEED_NEW_SESSION_VALUE = 'true';

//A/B Testing constants
export const WO_AB_TEST_NAME = 'welcomeoffer';
export const AB_TEST_COOKIE_NAME_PREFIX = 'ab-test-';

//Copy constants
export const UP_RATING_TOOLTIP_TEXT_PLURALIZED = `The ratings for these cards have been determined 
by our own industry experts who know the in's and out's of credit card products. Bonuses, rewards 
as well as rates and fees are all taken into account. Compensation from the issuer does not affect 
our rating. We only recommend products we either use ourselves or endorse.`;

//CCM constants
export const CCM_PREFERENCES_COOKIE_NAME = 'cmapi_cookie_privacy';
export const CCM_SESSION_ID = 'TAsessionID';
export const FUNCTIONAL_BLOCKING_LEVEL = 2;
export const ADVERSITING_BLOCKING_LEVEL = 3;
export const CCM_DECISION_COOKIE_NAME = 'cmapi_cookie_privacy';

//Partners Names to be checked in TPT for Marriott Bonus
export enum PartnersNames {
  AMERICAN_AIRLINES = 'American Airlines',
  AVIANCA_AIRLINES = 'Avianca Airlines',
  DELTA = 'Delta',
  KOREAN_AIR = 'Korean Air',
  UNITED_AIRLINES = 'United Airlines',
  AIR_NEW_ZEALAND = 'Air New Zealand',
}

//Bonus Points in TPT. Standard and Marriott special cases
export enum BonusPoints {
  DEFAULT = 5000,
  MARRIOTT_UNITED_AIRLINES = 10000,
  MARRIOTT_AIR_NEW_ZEALAND = 25,
}

// Template names
export enum TEMPLATE_NAMES {
  FOOTER_AND_HEADER_ONLY = 'Footer And Header Only',
}

//Constants for Hichtouch
export const HT_SESSION_TIMEOUT = 4 * 60 * 60 * 1000; //4 hours (in ms)
export const HT_DEFAULT_PAGE_NAME = 'Unknown';
export const HT_DEFAULT_PAGE_CATEGORY = 'Page';
export const HT_PAGE_CATEGORY_HUB_PAGE = 'Hub Page';
export const HT_PAGE_CATEGORY_ARCHIVE_PAGE = 'Archive';
export const HT_DOMAIN_PROXY_PATH = 'sr'; //The path for the subspace relay backend
export enum HT_TRACK_EVENTS {
  CLICK = 'Click',
  IMPRESSION = 'Impression',
}

//Constants for UP staff feature blocking
export const UP_STAFF_MEMBER_PARAM_NAME = 'upgp_staff_member';
export const UP_STAFF_MEMBER_PARAM_VALUE = 'y';

//Constants for the sitemap
export const CONTACT_PAGE_PATH = '/about-us/contact/';
export const PRESS_MENTIONS_PATH = '/press-mentions/';
export const HOTEL_HUB_PAGE_PATH = '/earn-use-points/hotels/';
export const AIRLINE_HUB_PAGE_PATH = '/earn-use-points/airlines/';
export const CREDIT_CARDS_HUB_PAGE_PATH = '/earn-use-points/credit-cards/';
export const EARN_USE_POINTS_HUB_PAGE_PATH = '/earn-use-points/';
export const COMPARE_CREDIT_CARDS_HUB_PAGE = '/credit-cards/compare-credit-cards/';
export const TRAVEL_PRODUCT_REVIEWS_HUB_PAGE = '/travel/product-reviews/';
export const ALL_CC_GUIDES_HUB_PAGE = '/credit-cards/guides/all/';
export const TRAVEL_HUB_PAGE = '/travel/';
export const CC_REVIEW_PAGE = '/credit-cards/reviews/'
export const CC_GUIDES_PAGE = '/credit-cards/guides/'
export const CC_REVIEWS_BUSINESS_PAGE = '/credit-cards/business/'
export const CREDIT_CARDS_PAGE = '/credit-cards/'
export const HUB_PAGE_TAG_OR_CATEGORY_KEYS = new Map([
  [HOTEL_HUB_PAGE_PATH, 'Hotel'],
  [AIRLINE_HUB_PAGE_PATH, 'Airline'],
  [CREDIT_CARDS_HUB_PAGE_PATH,'CCPoints'],
  [COMPARE_CREDIT_CARDS_HUB_PAGE,'CompareCC'],
  [TRAVEL_PRODUCT_REVIEWS_HUB_PAGE,'TravelProductReview'],
  [ALL_CC_GUIDES_HUB_PAGE,'Guides'],
  [EARN_USE_POINTS_HUB_PAGE_PATH,'EUP'],
  [CC_REVIEW_PAGE,'CCReview'],
  [CC_REVIEWS_BUSINESS_PAGE,'BusinessCCReview'],
  [CREDIT_CARDS_PAGE,'CCPage']
]);

// IMPORTANT: Please change this after modifying the about-us/contact/index.tsx file. Time is in UTC.
export const LAST_MODIFIED_TIMESTAMP_CONTACT_PAGE = '2024-12-05T19:00:00+00:00';
