import React, { FC } from 'react';
import { Link } from 'gatsby';
import { IArticleExcerpt } from './Article.def';
import { processCopyright } from '../../../utils/helpers';
import parser from 'html-react-parser';
import { createSourceElements, createSrc, createSrcSet } from '../../../utils';
import { MOBILE_MAX_WIDTH_MQ, SMALL_TAB_MIN_WIDTH_MQ } from '../../../constants';

const ArticleSimple: FC<IArticleExcerpt> = ({
  thumbnail,
  category,
  title,
  link,
  linkClick = () => {},
}) => {
  const sourceElements = createSourceElements([
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail.src || '', [
        {
          options: {
            width: 200,
            height: 140,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(thumbnail.src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);

  thumbnail = {
    ...thumbnail,
    src: createSrc(thumbnail.src || ''),
  };

  return (
    <>
      <div className="postThumbnail">
        <Link to={link}>
          <picture>
            {sourceElements}
            <img {...thumbnail} loading="lazy" />
          </picture>
        </Link>
      </div>
      <div className="postDetails">
        {category && category.link && (
          <Link
            to={category.link}
            className="postCategory"
            onClick={() => linkClick(category.link)}
          >
            {category.text}
          </Link>
        )}
        <Link to={link} onClick={() => linkClick(link)} className="postTitle">
          {parser(processCopyright(title))}
        </Link>
      </div>
    </>
  );
};

export default ArticleSimple;
