import { ICardData } from '@throttleup/esi-components';
import { AB_TEST_COOKIE_NAME_PREFIX, WO_AB_TEST_NAME } from '../../constants';
import { getCookie } from './cookie';

/**
 * Generate a list of AB Test allocations based on a given list of test bucket names.
 * Currently the only AB Test is the Welcome Offer Test. Return the value from the cookie for this test.
 * @param abTests - A list of test names to process for this component.
 * @param cardData - the card data JSON for the card whose component we are processing.
 * @returns [array] - an array of test allocations, or an empty array if no tests were processed
 *   or their cookies were not found.
 */
export const getABTestBucketAllocations = (abTests: string[], cardData: ICardData) => {
  const abTestBucketAllocations = [];
  // Handle Welcome Offer Test
  if (abTests.includes(WO_AB_TEST_NAME)) {
    if (isCardForWOAbTest(cardData)) {
      const woTestBucket = getCookie(AB_TEST_COOKIE_NAME_PREFIX + WO_AB_TEST_NAME);
      if (woTestBucket) {
        abTestBucketAllocations.push(woTestBucket);
      }
    }
  }

  return abTestBucketAllocations;
};

/**
 * Return whether or not a card is being tested as part of the Welcome Offer AB Test
 * @param cardData - the card data JSON for the card
 * @returns [boolean]
 */
export const isCardForWOAbTest = (cardData: ICardData) => cardData?.welcomeOfferAmountAbTest;
