import React, { FC } from 'react';
import FooterPlaneGraphic from '../../svgs/FooterPlaneGraphic';
import BreadCrumbs from '../base/BreadCrumbs';
import { IPageHeader } from './PageHeader.def';

const PageHeader: FC<IPageHeader> = ({
  title,
  subTitle,
  author,
  lastUpdated,
  breadCrumbs = [],
  showGraphic,
  pageNumber = 1,
}) => (
  <section className="pageHeader">
    {showGraphic && <FooterPlaneGraphic className="desktopGraphic" />}
    <div className="container">
      <BreadCrumbs breadCrumbs={breadCrumbs} />
      <h1>{title}{pageNumber && pageNumber > 1 && ` - Page ` + pageNumber }</h1>
      {(author || lastUpdated) && (
        <div className="blogContainer">
          {author?.thumbnail && (
            <figure>
              <img src={author.thumbnail.src} alt={author.thumbnail.alt} />
            </figure>
          )}
          {author?.name && <span>{author.name}</span>}
          {lastUpdated && <span>{lastUpdated}</span>}
        </div>
      )}
      {subTitle && pageNumber === 1 && <p>{subTitle}</p>}
    </div>
  </section>
);

export default PageHeader;
