import React from 'react';
import GlobalPostContext from '../context/GlobalPostContext';

interface ChildProps {
  children: JSX.Element;
  postDatabaseId: number;
}

const GlobalPostProvider = ({ children, postDatabaseId }: ChildProps) => {
  return (
    <GlobalPostContext.Provider value={{ postDatabaseId }}>{children}</GlobalPostContext.Provider>
  );
};

export default GlobalPostProvider;
